import React, { useEffect } from "react";
import { useState, useRef } from "react";
import VideoItem from "../../components/VideoItem/VideoItem.jsx";
import SearchBarComponent from "../../components/SearchBarComponent/SearchBarComponent.jsx";
import CrossSign from "../../assets/cross-sign.svg";
import useOutsideClick from "../../hooks/useOutsideClick.js";
import "./styles.scss";
import VideoPreview from "../../components/VideoPreview/VideoPreview.jsx";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent.jsx";
import AlertModal from "../AlertModal/AlertModal.jsx";
import {
  hideModal,
  notifySuccess,
  showModal,
} from "../../actions/global.action.js";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createPlaylist, fetchPlaylists } from "../../actions/playlist.js";
import Popup from "../../containers/Popup/Popup.jsx";

const AIRecommendationModal = ({ playlist, handleClose }) => {
  /*
    The modal showing playlist preview and the option to save it.
  */

  const [queryResults, setQueryResults] = useState(playlist.videos);
  const [videosToSave, setVideosToSave] = useState(
    playlist.videos && playlist.videos.length > 0
      ? playlist.videos.map((video) => video.id)
      : [],
  );
  const [videoPreview, setVideoPreview] = useState(null);
  const [closePopup, setClosePopup] = useState(false);
  const addRef = useRef(null);
  const [showWarning, setShowWarning] = useState(false);
  const dispatch = useDispatch();
  useOutsideClick(addRef, () => {
    if (noVideosToShow) {
      setClosePopup(true);
    } else {
      setShowWarning(true);
    }
  });

  const navigate = useNavigate();
  const noVideosToShow = playlist.videos.length === 0;

  useEffect(() => {
    dispatch(showModal());
    return () => {
      dispatch(hideModal());
    };
  }, [dispatch]);

  const handleQuery = (query_term) => {
    if (query_term === "") {
      setQueryResults(playlist.videos);
    } else {
      const filteredResults = playlist.videos.filter((child) =>
        child.title.toLowerCase().includes(query_term.toLowerCase()),
      );
      setQueryResults(filteredResults);
    }
  };

  const handleSave = () => {
    dispatch(
      createPlaylist(
        playlist.title.trim(),
        "private",
        playlist.description,
        videosToSave,
        true,
        null,
      ),
    );
    dispatch(fetchPlaylists());
    dispatch(notifySuccess("Playlist saved succesfully!"));
    navigate("/dashboard?tab=Playlists");
    setClosePopup(true);
  };

  const removeVideo = (id) => {
    setVideosToSave(videosToSave.filter((videoId) => videoId !== id));
  };

  const addVideo = (id) => {
    setVideosToSave([...videosToSave, id]);
  };

  return (
    <Popup
      className="video-add-wrapper-ai"
      closePopup={closePopup}
      closeFunc={handleClose}
      withContainer={true}
    >
      {showWarning && (
        <AlertModal
          type="warning"
          onContinue={() => {
            setShowWarning(false);
            setClosePopup(true);
          }}
          onCancel={() => {
            setShowWarning(false);
          }}
          title="Playist not saved"
          message="Are you sure you don't want to save the playlist? This action is irreversible."
        />
      )}
      <div className="ai-playlist-preview popup" ref={addRef}>
        {videoPreview !== null ? (
          <VideoPreview
            videoPreview={videoPreview}
            setVideoPreview={setVideoPreview}
            popout={true}
            handleClose={() => {
              if (noVideosToShow) {
                setClosePopup(true);
              } else {
                setShowWarning(true);
              }
            }}
          />
        ) : (
          <>
            <div className="video-add-title">
              Modify AI-generated playlist
              <div className="playlists-search">
                <SearchBarComponent
                  placeholder={"Search the item name"}
                  handleChange={(e) => {
                    handleQuery(e.target.value?.toLowerCase());
                  }}
                  setIsFocused={() => {}}
                  handleClose={() => {
                    handleQuery("");
                  }}
                />
              </div>
              <button
                className="video-add-cross-sign"
                onClick={() => {
                  if (noVideosToShow) {
                    setClosePopup(true);
                  } else {
                    setShowWarning(true);
                  }
                }}
              >
                <img src={CrossSign} alt="X" />
              </button>
            </div>

            <div className="add-content">
              <div className="playlist-title">{playlist.title}</div>
              {queryResults && (
                <>
                  <div className="video-list">
                    <div>
                      <div>
                        {noVideosToShow && (
                          <div>
                            Unfortunately, we couldn&apos;t generate a playlist
                            based on your query. Please try again with different
                            keywords.
                          </div>
                        )}
                        {queryResults?.map((video) => (
                          <VideoItem
                            key={video.id}
                            video={video}
                            playlist={playlist}
                            preview={setVideoPreview}
                            isAiRecommendation={true}
                            addVideo={(id) => addVideo(id)}
                            removeVideo={(id) => removeVideo(id)}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="video-bottom">
              <ButtonComponent
                text="Cancel"
                level="secondary"
                size="small"
                icon={false}
                handleClick={() => {
                  if (noVideosToShow) {
                    setClosePopup(true);
                  } else {
                    setShowWarning(true);
                  }
                }}
              />
              <ButtonComponent
                text="Save"
                size="small"
                level={noVideosToShow ? "disable" : "primary"}
                icon={false}
                handleClick={handleSave}
              />
            </div>
          </>
        )}
      </div>
    </Popup>
  );
};

export default AIRecommendationModal;
