import React from "react";
import "./styles.scss";

const StatsHeader = () => {
  return (
    <div className="stats-header">
      <div className="stat-card">
        <div className="stat-title">Total Users</div>
        <div className="stat-value">96</div>
        <div className="stat-change positive">↑ 0.8%</div>
      </div>
      <div className="stat-card">
        <div className="stat-title">Page Views</div>
        <div className="stat-value">12,440</div>
        <div className="stat-change positive">↑ 6.2%</div>
      </div>
      <div className="stat-card">
        <div className="stat-title">Total Revenue</div>
        <div className="stat-value">$8,746.22</div>
        <div className="stat-change positive">↑ 2.4%</div>
      </div>
    </div>
  );
};

export default StatsHeader;
