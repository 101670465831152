import React from "react";
import confetti from "canvas-confetti";

const PlaylistBottomCard = ({ playlist }) => {
  const handleCelebrate = () => {
    const createConfettiBurst = () => {
      // Left corner burst
      confetti({
        particleCount: 20,
        spread: 150,
        origin: { x: 0.0, y: 1.2 },
        gravity: 0.5,
        scalar: 1.2,
        startVelocity: 40,
        ticks: 600,
        colors: [
          "#26ccff",
          "#a25afd",
          "#ff5e7e",
          "#88ff5a",
          "#fcff42",
          "#ffa62d",
          "#ff36ff",
        ],
      });
      confetti({
        particleCount: 50,
        spread: 150,
        origin: { x: 0.0, y: 0.7 },
        gravity: 0.5,
        scalar: 1.2,
        startVelocity: 40,
        ticks: 600,
        colors: [
          "#26ccff",
          "#a25afd",
          "#ff5e7e",
          "#88ff5a",
          "#fcff42",
          "#ffa62d",
          "#ff36ff",
        ],
      });

      // Bottom burst
      confetti({
        particleCount: 30,
        spread: 150,
        origin: { x: 0.5, y: 1.0 },
        gravity: 0.5,
        scalar: 1.2,
        startVelocity: 40,
        ticks: 600,
      });

      // Right corner burst
      confetti({
        particleCount: 20,
        spread: 150,
        origin: { x: 1.0, y: 1.0 },
        gravity: 0.5,
        scalar: 1.2,
        startVelocity: 40,
        ticks: 600,
        colors: [
          "#26ccff",
          "#a25afd",
          "#ff5e7e",
          "#88ff5a",
          "#fcff42",
          "#ffa62d",
          "#ff36ff",
        ],
      });

      confetti({
        particleCount: 50,
        spread: 150,
        origin: { x: 1, y: 0.7 },
        gravity: 0.5,
        scalar: 1.2,
        startVelocity: 40,
        ticks: 600,
        colors: [
          "#26ccff",
          "#a25afd",
          "#ff5e7e",
          "#88ff5a",
          "#fcff42",
          "#ffa62d",
          "#ff36ff",
        ],
      });
    };

    createConfettiBurst();
    setTimeout(createConfettiBurst, 400);
    setTimeout(createConfettiBurst, 800);
  };

  return (
    <div className={`playlist-bottom-card`}>
      <h2>Congratulations!</h2>
      <p>You've completed this playlist</p>
      <button className="celebrate-button" onClick={handleCelebrate}>
        Celebrate!
      </button>
    </div>
  );
};

export default PlaylistBottomCard;
