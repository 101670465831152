import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createPlaylist,
  fetchPlaylists,
  setWatchingPlaylist,
  updatePlaylistToPreview,
  updatePlaylistName as updatePlaylistNameAction,
  updatePlaylistVisibility,
  setCurrentEditPlaylistToNull,
} from "../../actions/playlist.js";
import { notifyError, notifySuccess } from "../../actions/global.action.js";
import { checkIsDuplicatePlaylist } from "../../hooks/usePlaylist.ts";
import PlaylistPreview from "../PlaylistPreview/PlaylistPreview.jsx";
import Divider from "@mui/material/Divider";
import DropdownMenuPrivacy from "../DropdownMenuPrivacy/DropdownMenuPrivacy.jsx";
import TextInputComponent from "../TextInputComponent/TextInputComponent.jsx";
import AuthorAvatar from "../AuthorAvatar/AuthorAvatar.jsx";
import AlertModal from "../../modals/AlertModal/AlertModal.jsx";
import ButtonComponent from "../ButtonComponent/ButtonComponent.jsx";
import { formatTimeAgo } from "../../utils/video.js";
import useWindowWidth from "../../hooks/useWindowWidth.js";
import "./styles.scss";
import { playlistService } from "../../services/playlist.service.ts";
import RichTextEditorComponent from "../RichTextEditor/RichTextEditorComponent.jsx";
import { countChar } from "../../utils/editor.jsx";
import { updatePlaylistToPreviewEdit } from "../../actions/playlist.js";
import Spinner from "../Spinner/Spinner.jsx";
import ThumbnailChangeComponentPlaylist from "../ThumbnailChangeComponent/ThumbnailChangeComponentPlaylist.jsx";

const PlaylistComponent = ({ type }) => {
  /*
  A componet used to display the middle part of the playlist tab.
  type: "create" or "edit" - determines the purpose of the component.
  */
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.playlist.playlistsLeftPanel);
  const currentPlaylist = useSelector(
    (state) => state.playlist.playlistToPreviewEdit,
  );
  const dispatchCurrentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const [loading, setLoading] = useState(true);
  const [img, setImg] = useState(
    currentPlaylist?.customized_thumbnail_url || currentPlaylist?.thumbnail_url,
  );
  const [thumbnail, setThumbnail] = useState(
    currentPlaylist?.customized_thumbnail_url,
  );

  const findItemById = (items, id) => {
    for (const item of items) {
      if (item?.object_id?.slice(0, 8) === id) {
        return item;
      }
      if (item.children) {
        const found = findItemById(item.children, id);
        if (found) return found;
      }
    }
    return null;
  };

  const [playlistTitle, setPlaylistTitle] = useState(
    type === "edit" ? currentPlaylist?.title : "",
  );
  const [visibility, setVisibility] = useState(
    type === "edit" ? currentPlaylist?.visibility : "public",
  );
  const [description, setDescription] = useState("");
  const [numberOfVideos, setNumberOfVideos] = useState(0);
  const [numberOfCards, setNumberOfCards] = useState(0);
  const [views, setViews] = useState(0);
  const [areAnyChangesMade, setAreAnyChangesMade] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (type === "create") {
      dispatch(setCurrentEditPlaylistToNull());
      setLoading(false);
    } else if (!currentPlaylist && dispatchCurrentPlaylist) {
      dispatch(updatePlaylistToPreviewEdit(dispatchCurrentPlaylist.object_id));
    } else {
      setLoading(false);
    }
  }, [currentPlaylist, dispatchCurrentPlaylist, type]);

  useEffect(() => {
    if (type === "edit" && currentPlaylist) {
      setPlaylistTitle(currentPlaylist.title);
      setVisibility(currentPlaylist.visibility);
      setNumberOfVideos(currentPlaylist?.video_count);
      setNumberOfCards(currentPlaylist?.card_count);
      setViews(currentPlaylist?.views);
      setImg(
        currentPlaylist?.customized_thumbnail_url ||
          currentPlaylist?.thumbnail_url,
      );
      setThumbnail(currentPlaylist?.customized_thumbnail_url);

      let oldDesc = currentPlaylist.description;
      try {
        oldDesc = JSON.parse(currentPlaylist.description);
      } catch (err) {
        // do nothing
      }
      setDescription(oldDesc);
    }
  }, [currentPlaylist, type]);

  const handleSubmit = async () => {
    const currDescLength = countChar(description);
    if (currDescLength > 500) {
      dispatch(
        notifyError("Description should be no longer than 500 characters!"),
      );
      return;
    }
    const jsonDesc = description ? JSON.stringify(description) : "";
    if (playlistTitle.length === 0) {
      dispatch(notifyError("Playlist name is too short!"));
      return;
    } else if (playlistTitle.length > 100) {
      dispatch(
        notifyError("Playlist name should be no longer than 100 characters!"),
      );
      return;
    }

    if (type === "create") {
      if (
        playlists.length === 0 ||
        !checkIsDuplicatePlaylist(playlistTitle.trim(), playlists)
      ) {
        setAreAnyChangesMade(false);
        dispatch(
          createPlaylist(
            playlistTitle.trim(),
            visibility,
            jsonDesc,
            [],
            false,
            img?.includes("data:image/") ? thumbnail : null,
          ),
        );
        dispatch(fetchPlaylists(playlistTitle.trim()));
        dispatch(notifySuccess(`Playlist ${playlistTitle} created!`));
        const url = new URL(window.location);
        url.searchParams.set("playlistId", "new-" + playlistTitle.trim());
        window.history.replaceState({}, "", url.toString());
        dispatch(fetchPlaylists());
        dispatch(setWatchingPlaylist("loading"));
      } else {
        dispatch(
          notifyError(
            "Playlist with this name already exists, choose a different name!",
          ),
        );
      }
    } else if (type === "edit") {
      try {
        const formData = new FormData();
        formData.append("title", playlistTitle);
        formData.append("visibility", visibility);
        formData.append("description", jsonDesc);
        if (thumbnail !== currentPlaylist?.customized_thumbnail_url) {
          formData.append("thumbnail", thumbnail);
        }
        setAreAnyChangesMade(false);
        await playlistService.editPlaylist(currentPlaylist?.id, formData);

        dispatch(notifySuccess("Playlist updated successfully"));

        dispatch(fetchPlaylists());
        dispatch(updatePlaylistToPreviewEdit(currentPlaylist?.id));
      } catch (error) {
        dispatch(
          notifyError(
            `Error updating playlist. ${error.response ? error.response.data.message : error}`,
          ),
        );
      }
    }
  };

  const handleVisibilityChange = (newVisibility) => {
    setVisibility(newVisibility);
    dispatch(updatePlaylistVisibility(newVisibility));
  };

  const handleCancelButtonClick = () => {
    if (type === "edit") {
      if (areAnyChangesMade) {
        setShowModal(true);
      } else {
        closePlaylistPreview();
        dispatch(setWatchingPlaylist(false));
      }
    } else {
      if (playlistTitle.length > 0 || description.length > 0) {
        setShowModal(true);
      } else {
        dispatch(setWatchingPlaylist(false));
      }
    }
  };

  const closePlaylistPreview = () => {
    const url = new URL(window.location);
    if (url.searchParams.has("playlistId")) {
      url.searchParams.delete("playlistId");
    }
    window.history.replaceState({}, "", url.toString());
    dispatch(setWatchingPlaylist(false));
  };

  useEffect(() => {
    if (
      (visibility && visibility !== currentPlaylist?.visibility) ||
      img !==
        (currentPlaylist?.customized_thumbnail_url ||
          currentPlaylist?.thumbnail_url) ||
      playlistTitle !== currentPlaylist?.title ||
      (currentPlaylist?.description.length === 0 && description.length !== 0) ||
      (currentPlaylist?.description.length !== 0 &&
        JSON.stringify(description) !== currentPlaylist?.description)
    ) {
      setAreAnyChangesMade(true);
    } else {
      setAreAnyChangesMade(false);
    }
  }, [playlistTitle, description, visibility, img]);

  if (loading) {
    return (
      <div className="spinner-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="playlist-container">
      {showModal && (
        <AlertModal
          type="warning"
          onContinue={() => {
            setAreAnyChangesMade(false);
            setShowModal(false);
            dispatch(fetchPlaylists());
            closePlaylistPreview();
            dispatch(setWatchingPlaylist(false));
          }}
          onCancel={() => {
            setShowModal(false);
          }}
          title={
            type === "create"
              ? "Cancel Creating Playlist"
              : "Cancel Editing Playlist"
          }
          message={`Are you sure you want to stop ${type === "create" ? "creating" : "editing"} a playlist? This action is irreversible.`}
        />
      )}
      <div className="playlist-component">
        <div className="playlist-header">
          <div className="playlist-thumbnail">
            <ThumbnailChangeComponentPlaylist
              playlist={currentPlaylist}
              img={img}
              setImg={setImg}
              setThumbnail={setThumbnail}
              type={type}
            />
          </div>
          <div className="header-side">
            <div
              className={`playlist-title ${playlistTitle === "" ? "playlist-title-empty" : ""}`}
            >
              {playlistTitle || "Untitled"}
            </div>
            <div className="creator-name">
              Created by
              <div className="avatar">
                <AuthorAvatar />
              </div>
            </div>
            <div className="stats-info">
              <div className="video-views">
                {type === "edit"
                  ? `${views} view${views !== 1 ? "s" : ""}`
                  : "0 views"}
              </div>
              <div className="video-views">
                {type === "edit"
                  ? `${numberOfVideos} video${numberOfVideos !== 1 ? "s" : ""}`
                  : "0 videos"}
              </div>
              <div className="video-views">
                {type === "edit"
                  ? `${numberOfCards} card${numberOfCards !== 1 ? "s" : ""}`
                  : "0 cards"}
              </div>
              {type === "edit" &&
                currentPlaylist &&
                formatTimeAgo(currentPlaylist.created_at)}
            </div>
            <Divider />
            <div className="privacy-box">
              <div className="privacy-text"> This playlist is</div>
              <DropdownMenuPrivacy
                category="privacy"
                currentVisibility={visibility}
                handleVisibilityChange={handleVisibilityChange}
                disabled={currentPlaylist?.parent}
                dataCy="edit-playlist-privacy-dropdown"
              />
            </div>
          </div>
        </div>
        <div data-cy="title-input">
          <TextInputComponent
            label="Title"
            state="Default"
            icon="false"
            handleInput={(e) => {
              setPlaylistTitle(e.target.value);
              dispatch(updatePlaylistNameAction(e.target.value));
            }}
            placeholder="Write a title"
            initialValue={playlistTitle}
            restriction={true}
            toolTip={true}
            toolTipText="The playlist title must be less than 100 characters."
          />
        </div>

        <div data-cy="description-input">
          <RichTextEditorComponent
            key={
              type === "create"
                ? "new playlist"
                : currentPlaylist?.id.slice(0, 8)
            }
            title={"Description"}
            setContent={setDescription}
            object={type === "edit" ? currentPlaylist : null}
          />
        </div>

        <div className="playlist-component-buttons">
          <ButtonComponent
            text="Cancel"
            level="secondary"
            icon={false}
            handleClick={handleCancelButtonClick}
          />
          <ButtonComponent
            text={type === "create" ? "Create" : "Save Changes"}
            level={
              !areAnyChangesMade && type === "edit" ? "disable" : "primary"
            }
            icon={false}
            handleClick={handleSubmit}
            dataCy="create-playlist-confirm-button"
          />
        </div>
      </div>
      {windowWidth > 1308 ? (
        <PlaylistPreview
          playlist={currentPlaylist}
          newPlaylist={type === "create"}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PlaylistComponent;
