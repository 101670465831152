import React, { useState, useEffect } from "react";
import playlistIcon from "../../assets/playlist-white.svg";
import { cardMapping } from "../../utils/constants";
import "./styles.scss";

const PlaylistThumbnail = ({
  noPhoto = true,
  thumbnail = null,
  thumbnail_type = null,
  playlist,
  clickHandled = false,
  isCustomized = false,
  itemsCount = null,
}) => {
  const [isPhoto, setIsPhoto] = useState(false);
  const [url, setUrl] = useState(null);
  const [type, setType] = useState(null);

  useEffect(() => {
    if (!noPhoto) {
      if (thumbnail && isCustomized) {
        setIsPhoto(true);
        setUrl(thumbnail);
        setType(thumbnail_type);
      } else if (playlist.video_count + playlist.card_count > 0) {
        setIsPhoto(true);
        if (playlist.thumbnail_url) {
          setUrl(playlist.thumbnail_url);
          setType(playlist.thumbnail_type);
        } else {
          setType(playlist.card_type);
        }
      } else {
        setIsPhoto(false);
      }
    } else {
      setIsPhoto(false);
    }
  }, [noPhoto, thumbnail, thumbnail_type, playlist, isCustomized]);

  const calculateItemsCount = () => {
    if (itemsCount !== null) return itemsCount;
    return playlist?.card_count
      ? playlist?.video_count + playlist?.card_count
      : playlist?.video_count;
  };

  const handleClick = () => {
    if (playlist?.video_count > 0 && !clickHandled) {
      window.open(`/playlist/${playlist?.object_id || playlist?.id}`, "_blank");
    }
  };

  const renderImage = () => {
    if (isPhoto && ["landscape", "portrait"].includes(type)) {
      return (
        <img
          className="playlist-image"
          src={url}
          style={{ objectFit: type === "landscape" ? "cover" : "contain" }}
        />
      );
    }

    if (isPhoto) {
      return (
        <div className="card-thumbnail">
          <img src={cardMapping[type]?.icon} />
          <span>{cardMapping[type]?.text}</span>
        </div>
      );
    }

    return <div className="playlist-image-placeholder"></div>;
  };

  return (
    <div
      className={`playlist-image-container-container ${
        playlist?.video_count > 0 ? "playlist-image-container-exist" : ""
      }`}
      onClick={handleClick}
    >
      <div className="playlist-background"></div>
      <div className="playlist-image-container">
        {renderImage()}
        <div className="playlist-info-bar">
          <img src={playlistIcon} alt="Playlist Icon" />
          {(!noPhoto && calculateItemsCount()) || "0"} Item
          {(!noPhoto && calculateItemsCount()) !== 1 && "s"}
        </div>
      </div>
    </div>
  );
};

export default PlaylistThumbnail;
