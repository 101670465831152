import { ApiService } from "./api.service.ts";
import { AxiosRequestConfig } from "axios";
import { handleAdminDashboardError } from "../helpers/adminDashboardErrors";

class UserService {
  static instance: UserService;

  static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  constructor() {
    if (UserService.instance) {
      throw new Error(
        "Error: Instantiation failed: Use UserService.getInstance() instead of new.",
      );
    }
    UserService.instance = this;
  }

  private userCache: Map<string, any> = new Map();
  private searchCache: Map<string, any> = new Map();

  private getCacheKey(
    timeRange: string,
    page: number,
    pageSize: number,
    search?: string,
  ): string {
    return `${timeRange}-${page}-${pageSize}-${search || ""}`;
  }

  async getUser(username: string): Promise<any> {
    return await ApiService.get(`/user/${username}/`);
  }

  async getUserVideos(): Promise<any> {
    try {
      const response = await ApiService.get("/get-user-videos/");

      // all videos have the required properties to prevent errors
      return response.map((video: any) => ({
        ...video,
        tags: video.tags || [],
        categories: video.categories || [],
        is_liked: video.is_liked || false,
        likes_count: video.likes_count || 0,
        children: video.children || [],
        card_type: video.card_type || "video",
        author: video.author || {},
        explanation: video.explanation || "",
      }));
    } catch (error) {
      console.error("Error in getUserVideos:", error);
      throw error;
    }
  }

  async getUserPlaylists(): Promise<any> {
    return await ApiService.get("/playlists/user/");
  }

  async getWatchHistory(): Promise<any> {
    return await ApiService.get("/user/watch-history/");
  }

  async getUserNotes(): Promise<any> {
    return await ApiService.get("/user/notes/");
  }

  async getUserFavorites(): Promise<any> {
    return await ApiService.get("/user/liked/");
  }

  async subscribe(userId: string): Promise<any> {
    return await ApiService.post(`/user/subscribe`, {
      userId,
    });
  }

  async unsubscribe(userId: string): Promise<any> {
    return await ApiService.post(`/user/unsubscribe`, {
      userId,
    });
  }

  async edit_profile(form_data: {}): Promise<any> {
    const config: Partial<AxiosRequestConfig> = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return await ApiService.patch(`/user/edit`, form_data, config);
  }

  async change_avatar(data): Promise<any> {
    const config: Partial<AxiosRequestConfig> = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    return await ApiService.patch(`/user/edit/avatar`, data, config);
  }

  async edit_interests(data): Promise<any> {
    return await ApiService.post(`/user/edit/interests`, data);
  }

  async getCommunicationPreference(): Promise<any> {
    return await ApiService.get("/user/communication-preference");
  }

  async setCommunicationPreference(preference: string): Promise<any> {
    return await ApiService.post("/user/communication-preference", {
      communication_preference: preference,
    });
  }

  async exportSaleComparisonData(data: any): Promise<any> {
    // return await fetch(`${import.meta.env.VITE_API_BASE_URL}/report/mis/sale-comparison/export`, {
    // 	// return await fetch(`${import.meta.env.VITE_API_BASE_URL}/report/mis/sale/export`, {
    // 	method: 'POST',
    // 	headers: {
    // 		'access-token': Cookie.Get('access_token'),
    // 		'Content-Type': 'application/json'
    // 	},
    // 	body: JSON.stringify(data)
    // })
    // 	.then((res) => {
    // 		if (res.ok) {
    // 			return res.blob()
    // 		}
    // 		return Promise.reject(res)
    // 	})
    // 	.then((response) => {
    // 		return response
    // 	})
  }

  async create_embedded_checkout(): Promise<any> {
    return await ApiService.post(`/stripe/create_embedded_checkout`);
  }

  async check_checkout_status(session_id: string): Promise<any> {
    return await ApiService.get(`/stripe/check_checkout_status/${session_id}`);
  }

  async cancel_subscription(): Promise<any> {
    return await ApiService.post(`/stripe/subscription/cancel`);
  }

  async reactivate_subscription(): Promise<any> {
    return await ApiService.post(`/stripe/subscription/reactivate`);
  }

  async checkSuperuserStatus(): Promise<any> {
    return await ApiService.get("/api/admin/check-superuser/");
  }

  async getAllUsers({
    page = 1,
    pageSize = 10,
    sortBy = "created_at",
    sortOrder = "desc",
    searchTerm = "",
    timeRange = "All Time",
    customRange = null,
  } = {}): Promise<any> {
    try {
      const timeRangeMap: { [key: string]: string } = {
        "All Time": "all",
        "7 days": "7_days",
        "30 days": "30_days",
        "12 months": "12_months",
        Custom: "custom",
      };

      const queryParams = new URLSearchParams({
        page: page.toString(),
        page_size: pageSize.toString(),
        sort_by: sortBy,
        sort_order: sortOrder,
        time_range: timeRangeMap[timeRange] || "all",
        search: searchTerm || "",
      });

      if (
        timeRange === "Custom" &&
        customRange?.startDate &&
        customRange?.endDate
      ) {
        queryParams.append("custom_start", customRange.startDate);
        queryParams.append("custom_end", customRange.endDate);
      }

      const config: AxiosRequestConfig = {
        timeout: 45000,
      };

      try {
        const response = await ApiService.get(
          `/api/admin/users/?${queryParams}`,
          config,
        );
        return response;
      } catch (error: any) {
        return handleAdminDashboardError(error);
      }
    } catch (error) {
      console.error("[UserService] Error in getAllUsers:", error);
      throw error;
    }
  }

  async deleteUser(userId: string): Promise<any> {
    try {
      const response = await ApiService.delete(`/api/admin/users/${userId}/`);
      return response;
    } catch (error) {
      console.error("Error in deleteUser service:", error);
      throw error;
    }
  }

  async massDeleteUsers(userIds: string[]): Promise<any> {
    try {
      // Ensure userIds is always an array of strings
      const idsArray = Array.isArray(userIds) ? userIds : [userIds];

      const response = await ApiService.post("/api/admin/users/mass-delete/", {
        user_ids: idsArray,
      });

      return response;
    } catch (error) {
      console.error("Error in massDeleteUsers service:", error);

      // If there's useful data in the error response, pass it along
      if (error.response?.data) {
        throw {
          ...error,
          message:
            error.response.data.error ||
            error.response.data.message ||
            error.message,
        };
      }

      throw error;
    }
  }

  async subscribeToNewsletter(email: string): Promise<any> {
    return await ApiService.post(`/user/subscribe-to-newsletter`, { email });
  }

  clearCache() {
    this.userCache.clear();
    this.searchCache.clear();
  }
}

export const userService = UserService.getInstance();
