import React from "react";
import { useNavigate } from "react-router-dom";
import UploadPopUpForm from "../../modals/UploadPopUpForm/UploadPopUpForm";
import "./styles.scss";

const UploadPopUpPage = () => {
  /*
    Page that contains the upload form
    Alligned with latest Figma design (May 24)
    */

  const navigate = useNavigate();
  return (
    <div className="upload-popup-form-background">
      <UploadPopUpForm handleClose={() => navigate(-1)} />
    </div>
  );
};

export default UploadPopUpPage;
