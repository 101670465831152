import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { userService } from "../../../services/user.service.ts";
import "./styles.scss";
import PaginationControls from "../PaginationControls/PaginationControls.jsx";
import { FaTrash } from "react-icons/fa";
import AlertModal from "../../../modals/AlertModal/AlertModal.jsx";
import { notifyError, notifySuccess } from "../../../actions/global.action.js";
import Spinner from "../../Spinner/Spinner.jsx";

function useDebounce(callback, delay) {
  const timeoutRef = useRef(null);
  const debounced = useCallback(
    (value) => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        callback(value);
      }, delay);
    },
    [callback, delay],
  );
  return debounced;
}

const UsersTable = ({ timeRange, customRange }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMassDeleteModal, setShowMassDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const responseCache = useRef(new Map());

  const debouncedSearch = useDebounce((value) => {
    setDebouncedSearchTerm(value);
    setPage(1);
  }, 300);

  const getCacheKey = useCallback(() => {
    return `${timeRange}-${page}-${pageSize}-${sortConfig.key}-${sortConfig.direction}-${debouncedSearchTerm}`;
  }, [timeRange, page, pageSize, sortConfig, debouncedSearchTerm]);

  const fetchUsers = useCallback(async () => {
    try {
      setSearchLoading(true);
      const cacheKey = getCacheKey();
      if (responseCache.current.has(cacheKey)) {
        const cachedData = responseCache.current.get(cacheKey);
        setUsers(cachedData.users);
        setTotalUsers(cachedData.total);
        setTotalPages(cachedData.pages);
        setSearchLoading(false);
        setLoading(false);
        return;
      }
      const backendSortMapping = {
        username: "username",
        is_active: "is_active",
        cards_count: "cards_count",
        playlists_count: "playlists_count",
        notes_count: "notes_count",
        unique_content_viewed: "unique_content_viewed",
        total_playlist_views: "total_playlist_views",
        total_likes_given: "total_likes_given",
        total_likes_received: "total_likes_received",
        created_at: "created_at",
        last_login: "last_login",
        videos_count: "videos_count",
      };
      const sortByForBackend =
        backendSortMapping[sortConfig.key] || sortConfig.key;
      const response = await userService.getAllUsers({
        page,
        pageSize,
        sortBy: sortByForBackend,
        sortOrder: sortConfig.direction,
        searchTerm: debouncedSearchTerm,
        timeRange,
        customRange,
      });
      responseCache.current.set(cacheKey, response);
      if (responseCache.current.size > 20) {
        const oldestKey = responseCache.current.keys().next().value;
        responseCache.current.delete(oldestKey);
      }
      setUsers(response.users || []);
      setTotalUsers(response.total || 0);
      setTotalPages(response.pages || 0);
      if (response.current_page && response.current_page !== page) {
        setPage(response.current_page);
      }
    } catch (error) {
      console.error("[UsersTable] Error fetching users:", error);
      dispatch(notifyError("Failed to load users. Please try again."));
    } finally {
      setSearchLoading(false);
      setLoading(false);
    }
  }, [
    page,
    pageSize,
    sortConfig,
    debouncedSearchTerm,
    timeRange,
    customRange,
    dispatch,
    getCacheKey,
  ]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    responseCache.current.clear();
    setPage(1);
  }, [sortConfig, timeRange, customRange, debouncedSearchTerm]);

  const handleSort = (key) => {
    responseCache.current.clear();
    setSortConfig((prev) => {
      if (prev.key !== key) return { key, direction: "desc" };
      return { key, direction: prev.direction === "desc" ? "asc" : "desc" };
    });
  };

  const handlePageSizeChange = (newSize) => {
    setPageSize(newSize);
    setPage(1);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedUsers(users.map((user) => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId],
    );
  };

  const handleDeleteUser = async (userId) => {
    try {
      setDeleteInProgress(true);
      await userService.deleteUser(userId);

      // Remove the deleted user from local state immediately
      setUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
      setTotalUsers((prevCount) => prevCount - 1);

      notifySuccess(dispatch, "User deleted successfully");
    } catch (error) {
      notifyError(
        dispatch,
        `Failed to delete user: ${error.message || "Unknown error"}`,
      );
    } finally {
      setShowDeleteModal(false);
      setUserToDelete(null);
      setDeleteInProgress(false);
    }
  };

  const handleMassDelete = async () => {
    try {
      setDeleteInProgress(true);
      const response = await userService.massDeleteUsers(selectedUsers);

      // Get the list of successfully deleted users
      const deletedUsers = response.deleted_users || [];

      // Remove successfully deleted users from the UI
      setUsers((prevUsers) =>
        prevUsers.filter((u) => !deletedUsers.includes(u.id)),
      );
      setTotalUsers((prevCount) => prevCount - deletedUsers.length);

      // Show appropriate success message
      if (response.message) {
        notifySuccess(dispatch, response.message);
      } else {
        notifySuccess(
          dispatch,
          `${deletedUsers.length} users deleted successfully`,
        );
      }

      // Clear selection
      setSelectedUsers([]);
    } catch (error) {
      // Extract error message from response if available
      const errorMessage =
        error.response?.data?.error ||
        error.response?.data?.message ||
        error.message ||
        "Unknown error occurred";

      notifyError(dispatch, `Failed to delete users: ${errorMessage}`);

      // If there's partial success info in the error response, update the UI accordingly
      if (error.response?.data?.deleted_users?.length > 0) {
        const deletedUsers = error.response.data.deleted_users;
        setUsers((prevUsers) =>
          prevUsers.filter((u) => !deletedUsers.includes(u.id)),
        );
        setTotalUsers((prevCount) => prevCount - deletedUsers.length);
      }
    } finally {
      setShowMassDeleteModal(false);
      setDeleteInProgress(false);
    }
  };

  const isAllCurrentPageSelected =
    users.length > 0 && users.every((user) => selectedUsers.includes(user.id));

  if (loading && !searchLoading) {
    return (
      <div style={{ textAlign: "center", marginTop: "40px" }}>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="users-table-wrapper">
      <div className="table-controls">
        <div className="search-field">
          <input
            type="text"
            placeholder="Search by name or username..."
            value={searchTerm}
            onChange={handleSearch}
          />
          {searchLoading && (
            <span className="search-indicator">
              <Spinner size="small" />
            </span>
          )}
        </div>
        {selectedUsers.length > 0 && (
          <button
            className="delete-selected-button"
            onClick={() => setShowMassDeleteModal(true)}
            disabled={deleteInProgress}
          >
            {deleteInProgress ? (
              <>
                <span className="spinner-icon">
                  <Spinner size="small" />
                </span>
                Deleting...
              </>
            ) : (
              <>
                <FaTrash className="icon" />
                Delete Selected ({selectedUsers.length})
              </>
            )}
          </button>
        )}
      </div>
      <div className="table-scroll-container">
        <table className="users-table">
          <thead>
            <tr>
              <th className="checkbox-column">
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={isAllCurrentPageSelected}
                />
              </th>
              <th
                onClick={() => handleSort("username")}
                className={`sortable ${sortConfig.key === "username" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Username
              </th>
              <th
                onClick={() => handleSort("is_active")}
                className={`sortable ${sortConfig.key === "is_active" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Status
              </th>
              <th
                onClick={() => handleSort("videos_count")}
                className={`numeric-header sortable ${sortConfig.key === "videos_count" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Videos
              </th>
              <th
                onClick={() => handleSort("cards_count")}
                className={`numeric-header sortable ${sortConfig.key === "cards_count" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Cards
              </th>
              <th
                onClick={() => handleSort("playlists_count")}
                className={`numeric-header sortable ${sortConfig.key === "playlists_count" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Playlists
              </th>
              <th
                onClick={() => handleSort("notes_count")}
                className={`numeric-header sortable ${sortConfig.key === "notes_count" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Notes
              </th>
              <th
                onClick={() => handleSort("unique_content_viewed")}
                className={`numeric-header sortable ${sortConfig.key === "unique_content_viewed" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Content Viewed
              </th>
              <th
                onClick={() => handleSort("total_playlist_views")}
                className={`numeric-header sortable ${sortConfig.key === "total_playlist_views" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Playlist Views
              </th>
              <th
                onClick={() => handleSort("total_likes_given")}
                className={`numeric-header sortable ${sortConfig.key === "total_likes_given" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Likes Given
              </th>
              <th
                onClick={() => handleSort("total_likes_received")}
                className={`numeric-header sortable ${sortConfig.key === "total_likes_received" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Likes Received
              </th>
              <th
                onClick={() => handleSort("created_at")}
                className={`sortable ${sortConfig.key === "created_at" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Joined
              </th>
              <th
                onClick={() => handleSort("last_login")}
                className={`sortable ${sortConfig.key === "last_login" ? `sorted ${sortConfig.direction}` : ""}`}
              >
                Last Sign-in
              </th>
            </tr>
          </thead>
          <tbody>
            {searchLoading ? (
              <tr>
                <td
                  colSpan="13"
                  style={{ textAlign: "center", padding: "40px" }}
                >
                  <Spinner />
                </td>
              </tr>
            ) : users.length === 0 ? (
              <tr>
                <td colSpan="13" className="empty-message">
                  No users found. {searchTerm && `Try a different search term.`}
                </td>
              </tr>
            ) : (
              users.map((user) => (
                <tr
                  key={user.id}
                  className={selectedUsers.includes(user.id) ? "selected" : ""}
                >
                  <td className="checkbox-column">
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleSelectUser(user.id)}
                    />
                  </td>
                  <td>
                    <a
                      href={`/channel/${user.username}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="username-link"
                    >
                      {user.username}
                    </a>
                  </td>
                  <td>
                    <span
                      className={`status-badge ${user.is_active ? "active" : "inactive"}`}
                    >
                      {user.is_active ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="numeric-cell">{user.videos_count}</td>
                  <td className="numeric-cell">{user.cards_count}</td>
                  <td className="numeric-cell">{user.playlists_count}</td>
                  <td className="numeric-cell">{user.notes_count}</td>
                  <td className="numeric-cell">
                    {user.unique_content_viewed || 0}
                  </td>
                  <td className="numeric-cell">{user.total_playlist_views}</td>
                  <td className="numeric-cell">{user.total_likes_given}</td>
                  <td className="numeric-cell">{user.total_likes_received}</td>
                  <td className="date-cell">
                    {new Date(user.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </td>
                  <td className="date-cell">
                    {new Date(user.last_login).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <PaginationControls
          currentPage={page}
          totalPages={totalPages}
          pageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={handlePageSizeChange}
          totalRows={totalUsers}
        />
      </div>
      {showDeleteModal && (
        <AlertModal
          type="danger"
          title="Delete User"
          message="Are you sure you want to delete this user? This action cannot be undone."
          onContinue={() => handleDeleteUser(userToDelete)}
          onCancel={() => {
            setShowDeleteModal(false);
            setUserToDelete(null);
          }}
          dataCy="delete-user-alert-modal"
        />
      )}
      {showMassDeleteModal && (
        <AlertModal
          type="danger"
          title="Delete Users"
          message={`Are you sure you want to delete ${selectedUsers.length} selected users? This action cannot be undone.`}
          onContinue={handleMassDelete}
          onCancel={() => setShowMassDeleteModal(false)}
          dataCy="mass-delete-users-alert-modal"
        />
      )}
    </div>
  );
};

export default UsersTable;
