import React, { useState, useEffect } from "react";
import "./styles.scss";
import ButtonComponent from "../ButtonComponent/ButtonComponent.jsx";
import authService from "../../services/auth.service.ts";
import { useDispatch } from "react-redux";
import { notifySuccess } from "../../actions/global.action.js";
import AlertModal from "../../modals/AlertModal/AlertModal.jsx";
import DropdownMenuPrivacy from "../DropdownMenuPrivacy/DropdownMenuPrivacy.jsx";
import { userService } from "../../services/user.service.ts";

const PasswordTab = () => {
  /*
        One of the settings components.
        Enable users to change their password and delete their account.
       
        Sends a request to Auth0 for password change.
    */
  const [userEmail, setUserEmail] = useState("");
  const [sent, setSent] = useState(false); // Tracks if first reset email was sent (from button)
  const [countdown, setCountdown] = useState(30);
  const [resent, setResent] = useState(false); // Tracks if second reset email was sent (from <a> element)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [communicationPreference, setCommunicationPreference] = useState("");

  const dispatch = useDispatch();
  const handlePasswordReset = async (resend_request = false) => {
    const user = await authService.loadUser();
    const userEmail = user.email;
    setUserEmail(userEmail);
    await authService.resetPassword(userEmail);
    setSent(true);
    dispatch(notifySuccess("Reset password email sent!"));
    if (resend_request) {
      setResent(true);
    }
  };

  const fetchCommunicationPreference = async () => {
    try {
      const response = await userService.getCommunicationPreference();
      setCommunicationPreference(response.communication_preference);
    } catch (error) {
      console.error("Error fetching communication preference:", error);
    }
  };

  const updateCommunicationPreference = async (newPreference) => {
    try {
      if (
        newPreference.toLowerCase() === communicationPreference.toLowerCase()
      ) {
        return;
      }
      await userService.setCommunicationPreference(newPreference);
      dispatch(
        notifySuccess(
          `Communication frequency was updated to ${newPreference.toLowerCase()}!`,
        ),
      );
    } catch (error) {
      console.error("Error updating communication preference:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchCommunicationPreference();
  }, []);

  const deleteAccount = async () => {
    await authService.deleteAccount();
    dispatch(notifySuccess("Account deleted successfully!"));
    window.location.href = "/";
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resent, countdown]);

  return (
    <div className="settings-tab">
      <div className="settings-row">
        <div className="left-part">
          <div className="password-headline">Email Notifications</div>
          <div>
            Select the frequency of receiving email updates from the dropdown.
            You can adjust your preferences or unsubscribe at any time.
          </div>
        </div>
        <div className="right-part">
          <DropdownMenuPrivacy
            key={communicationPreference}
            category="news"
            currentVisibility={
              communicationPreference.charAt(0).toUpperCase() +
                communicationPreference.slice(1).toLowerCase() || ""
            }
            handleVisibilityChange={(option) => {
              updateCommunicationPreference(option);
              setCommunicationPreference(option);
            }}
            noPhoto={true}
            dataCy="comm-preference-dropdown"
          />
        </div>
      </div>
      <div className="settings-line"></div>
      {!sent ? (
        <>
          <div className="settings-row">
            <div className="left-part">
              <div className="password-headline">Reset Password</div>
              <div>
                To reset your password, click the button below to receive a
                password change email.
              </div>
            </div>
            <div className="right-part">
              <ButtonComponent
                text={"Change password"}
                icon={false}
                handleClick={() => handlePasswordReset(false)}
                coverFullWidth={true}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="settings-row">
            <div className="left-part">
              <div className="password-headline">Reset Password</div>
              <div>
                We have sent password reset instructions to: {userEmail}.
              </div>
            </div>

            <div className="right-part">
              {countdown === 0 ? (
                <>
                  {!resent ? (
                    <a
                      className="reset-text"
                      onClick={() => handlePasswordReset(true)}
                    >
                      {" "}
                      Send the email again{" "}
                    </a>
                  ) : (
                    <div className="right-part">
                      <ButtonComponent
                        text={"Change password"}
                        level="disable"
                        icon={false}
                        handleClick={() => handlePasswordReset(false)}
                        coverFullWidth={true}
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="reset-completed-text">
                  Resend email in {countdown} seconds
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <div className="settings-row">
        <div className="left-part">
          <div className="password-headline">Delete Account</div>
          <div>
            Deleting your account will delete all your account info, your
            videos, and your playlists and is irreversible. This action cannot
            be undone. You can still create a new account with the same email
            address.
          </div>
        </div>
        <div className="right-part">
          <ButtonComponent
            text={"Delete Account"}
            icon={false}
            handleClick={() => {
              setShowDeleteModal(true);
            }}
            isPink={true}
            coverFullWidth={true}
          />
        </div>
      </div>
      {showDeleteModal && (
        <AlertModal
          type="danger"
          title="Delete Account"
          message="Are you sure you want to delete your account? This action is irreversible."
          onContinue={() => {
            deleteAccount();
          }}
          onCancel={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default PasswordTab;
