import React from "react";
import "./styles.scss";
import assessmentIcon from "../../assets/multiple-choice.svg";
import flashcardIcon from "../../assets/informational.svg";
import knowledgeIcon from "../../assets/knowledge-thumb-black.svg";

const cardTypeImage = {
  assessment: assessmentIcon,
  knowledge: knowledgeIcon,
  flashcard: flashcardIcon,
};

const cardTypeDict = {
  assessment: "Multiple Choice Card",
  knowledge: "Informational Card",
  flashcard: "Flashcard",
};

const CardPreview = ({ card, handleClick = () => {} }) => {
  return (
    <div className="card-thumbnail-preview" onClick={handleClick}>
      <div className="card-thumbnail-image">
        <div className="image-container">
          <img src={cardTypeImage[card.card_type]} alt="thumbnail" />
        </div>
      </div>
      <div className="card-thumbnail-title">
        <div className="card-thumbnail-title-text">{card.title}</div>
        <div className="card-thumbnail-title-type">
          {cardTypeDict[card.card_type]}
        </div>
      </div>
    </div>
  );
};

export default CardPreview;
