import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { videoService } from "../../../services/video.service";
import Spinner from "../../Spinner/Spinner";
import PaginationControls from "../PaginationControls/PaginationControls";
import AdminVideoCard from "../AdminVideoCard/AdminVideoCard";
import AlertModal from "../../../modals/AlertModal/AlertModal";
import { notifySuccess, notifyError } from "../../../actions/global.action";
import { useDebounce } from "../../../hooks/useDebounce";
import "./styles.scss";

const VideosTable = ({ timeRange, customRange }) => {
  // State variables
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedVideos, setSelectedVideos] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  // Cache for API responses
  const responseCache = useRef(new Map());

  // Generate a cache key based on current parameters
  const getCacheKey = useCallback(() => {
    return `${timeRange}-${currentPage}-${pageSize}-${sortConfig.key}-${sortConfig.direction}-${debouncedSearchTerm}`;
  }, [timeRange, currentPage, pageSize, sortConfig, debouncedSearchTerm]);

  const debouncedSearch = useDebounce((value) => {
    setDebouncedSearchTerm(value);
    setCurrentPage(1);
  }, 500);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    // Don't trigger debounced search immediately - wait for debounce or Enter key
    debouncedSearch(value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }
  };

  const fetchVideos = useCallback(async () => {
    try {
      setSearchLoading(true);

      // Check if we have a cached response
      const cacheKey = getCacheKey();
      if (responseCache.current.has(cacheKey)) {
        const cachedData = responseCache.current.get(cacheKey);
        setVideos(cachedData.videos);
        setTotalVideos(cachedData.total);
        setTotalPages(cachedData.pages);
        setSearchLoading(false);
        return;
      }

      const params = {
        page: currentPage,
        pageSize: pageSize,
        sortBy: sortConfig.key,
        sortOrder: sortConfig.direction,
        timeRange,
        customRange,
        search: debouncedSearchTerm,
      };

      const response = await videoService.getAllVideosAdmin(params);

      // Cache the response
      responseCache.current.set(cacheKey, {
        videos: response.videos || [],
        total: response.total || 0,
        pages: response.pages || 1,
      });

      // Limit cache size to prevent memory issues
      if (responseCache.current.size > 50) {
        const firstKey = responseCache.current.keys().next().value;
        responseCache.current.delete(firstKey);
      }

      setVideos(response.videos || []);
      setTotalVideos(response.total || 0);
      setTotalPages(response.pages || 1);
    } catch (err) {
      console.error("Error fetching videos:", err);
      setError(err.message || "Failed to load videos");
    } finally {
      setSearchLoading(false);
      setLoading(false);
    }
  }, [
    currentPage,
    pageSize,
    sortConfig,
    timeRange,
    customRange,
    debouncedSearchTerm,
    getCacheKey,
  ]);

  // Client-side sorting for better performance
  const handleSort = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }

    // Special handling for categories sorting
    if (key === "categories") {
      setSortConfig({ key, direction });
      setCurrentPage(1);
      return;
    }

    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  // Clear cache when time range changes
  useEffect(() => {
    responseCache.current.clear();
  }, [timeRange, customRange]);

  useEffect(() => {
    fetchVideos();
  }, [fetchVideos]);

  const handleSelectVideo = (videoId) => {
    setSelectedVideos((prev) => {
      const newSet = new Set(prev);
      newSet.has(videoId) ? newSet.delete(videoId) : newSet.add(videoId);
      return newSet;
    });
  };

  const handleSelectAll = (currentPageVideos) => {
    if (selectedVideos.size === currentPageVideos.length) {
      setSelectedVideos(new Set());
    } else {
      const newSet = new Set([
        ...selectedVideos,
        ...currentPageVideos.map((v) => v.id),
      ]);
      setSelectedVideos(newSet);
    }
  };

  const handleDeleteVideos = async () => {
    try {
      setLoading(true);

      for (const id of selectedVideos) {
        await videoService.deleteAdminVideo(id);
      }

      setVideos((prevVideos) =>
        prevVideos.filter((video) => !selectedVideos.has(video.id)),
      );

      setSelectedVideos(new Set());
      setShowDeleteModal(false);

      responseCache.current.clear();
    } catch (error) {
      setError("Failed to delete videos");
      console.error("Error deleting videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const allCurrentPageSelected =
    videos.length > 0 && videos.every((vid) => selectedVideos.has(vid.id));

  if (loading && !searchLoading) {
    return (
      <div className="loading-container">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="admin-videos-wrapper">
      <div className="table-controls">
        <div className="search-field">
          <input
            type="text"
            placeholder="Search by title or channel..."
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
          />
          {searchLoading && (
            <span className="search-indicator">
              <Spinner size="small" />
            </span>
          )}
        </div>
        {selectedVideos.size > 0 && (
          <button
            className="delete-selected"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Selected ({selectedVideos.size})
          </button>
        )}
      </div>

      <div className="table-container">
        <div className="table-scroll-container">
          <table className="videos-table">
            <thead>
              <tr>
                <th className="checkbox-column">
                  <input
                    type="checkbox"
                    onChange={() => handleSelectAll(videos)}
                    checked={allCurrentPageSelected}
                  />
                </th>
                <th
                  onClick={() => handleSort("title")}
                  className={`video-info-header ${sortConfig.key === "title" ? "sorted" : ""}`}
                >
                  Video Info
                </th>
                <th
                  onClick={() => handleSort("author")}
                  className={`channel-header ${sortConfig.key === "author" ? "sorted" : ""}`}
                >
                  Channel
                </th>
                <th
                  onClick={() => handleSort("categories")}
                  className={`category-header ${sortConfig.key === "categories" ? "sorted" : ""}`}
                >
                  Category
                </th>
                <th
                  onClick={() => handleSort("date")}
                  className={`date-header ${sortConfig.key === "date" ? "sorted" : ""}`}
                >
                  Upload Date
                </th>
                <th
                  onClick={() => handleSort("views")}
                  className={`numeric-header ${sortConfig.key === "views" ? "sorted" : ""}`}
                >
                  Views
                </th>
                <th
                  onClick={() => handleSort("likes_count")}
                  className={`numeric-header ${sortConfig.key === "likes_count" ? "sorted" : ""}`}
                >
                  Likes
                </th>
                <th
                  onClick={() => handleSort("playlists_count")}
                  className={`numeric-header ${sortConfig.key === "playlists_count" ? "sorted" : ""}`}
                >
                  Playlists
                </th>
                <th
                  onClick={() => handleSort("notes")}
                  className={`numeric-header ${sortConfig.key === "notes" ? "sorted" : ""}`}
                >
                  Notes
                </th>
                <th
                  onClick={() => handleSort("visibility")}
                  className={`visibility-header ${sortConfig.key === "visibility" ? "sorted" : ""}`}
                >
                  Visibility
                </th>
                {/* Actions column: no onClick for sorting */}
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {videos.map((video) => (
                <AdminVideoCard
                  key={video.id}
                  video={video}
                  isSelected={selectedVideos.has(video.id)}
                  onSelect={handleSelectVideo}
                  onDelete={() => {
                    setSelectedVideos(new Set([video.id]));
                    setShowDeleteModal(true);
                  }}
                />
              ))}
            </tbody>
          </table>
        </div>
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
          onPageSizeChange={(size) => {
            setPageSize(size);
            setCurrentPage(1);
          }}
          totalRows={totalVideos}
        />
      </div>

      {showDeleteModal && (
        <AlertModal
          type="danger"
          title="Delete Videos"
          message={`Are you sure you want to delete ${selectedVideos.size} selected video(s)?`}
          buttonDeleteText="Delete"
          onContinue={handleDeleteVideos}
          onCancel={() => setShowDeleteModal(false)}
          dataCy="delete-videos-modal"
        />
      )}
    </div>
  );
};

export default VideosTable;
