import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../actions/global.action.js";
import { parseViews } from "../../utils/video.js";
import DropdownMenuPrivacy from "../DropdownMenuPrivacy/DropdownMenuPrivacy.jsx";
import PlaylistDropdown from "../PlaylistDropdown/PlaylistDropdown.jsx";
import AlertModal from "../../modals/AlertModal/AlertModal.jsx";
import trash from "../../assets/trash.svg";
import edit from "../../assets/edit.svg";
import TooltipComponent from "../TooltipComponent/TooltipComponent.jsx";
import "./styles.scss";
import { cardService } from "../../services/card.service.ts";
import { useNavigate } from "react-router-dom";
import ItemCard from "../ItemCard/ItemCard.jsx";

const MyCard = ({ card, handleChange }) => {
  /*
  Stores the information of a single video in My Content tab
  Corresponds to the latest Figma design (May 15)
  video: a single video to be displayed
  handleChange: a function that leads to fetching videos since something was changed 
  in one of the videos (deleting of the visibility or visibility change)
  */

  const navigate = useNavigate();

  const handleVisibilityChange = (selectedOption) => {
    const newVisibility = selectedOption.toLowerCase();

    cardService
      .changeCardVisibility(card.id, newVisibility)
      .then(() => {
        dispatch(notifySuccess("Visibility changed successfully!"));
        handleChange();
      })
      .catch((error) => {
        dispatch(
          notifyError(
            `Error changing visibility! ${
              error.response ? error.response.data.message : error
            }`,
          ),
        );
        console.error(
          "Error:",
          error.response ? error.response.data : error.message,
        );
      });
  };

  const dispatch = useDispatch();
  const [deleting, setDeleting] = useState(false);
  const [cardToDeleteId, setCardToDeleteId] = useState(null);
  const [showDangerModal, setShowDangerModal] = useState(false);

  const handleEditCard = (cardId) => {
    window.location.href = `/edit/card/${cardId}`;
  };

  const handleDeleteCard = async (cardId) => {
    setDeleting(true);
    try {
      await cardService.deleteCard(cardId);
      dispatch(notifySuccess("Card deleted successfully!"));
      handleChange();
    } catch (error) {
      console.error(
        "Error deleting card:",
        error.response ? error.response.data : error,
      );
      dispatch(
        notifyError(
          `Error deleting card! ${
            error.response ? error.response.data.message : error
          }`,
        ),
      );
    } finally {
      setDeleting(false);
      setShowDangerModal(false);
    }
  };

  return (
    <div className="my-content-item-component" key={card.id}>
      {showDangerModal && (
        <AlertModal
          type="danger"
          onContinue={() => {
            handleDeleteCard(cardToDeleteId);
          }}
          onCancel={() => {
            setShowDangerModal(false);
          }}
          title="Delete Card"
          message="Are you sure you want to delete this card? This action is irreversible."
          dataCy="delete-card-alert-modal"
        />
      )}
      {deleting && (
        <AlertModal
          type="loading"
          title="Deleting card..."
          message="Your card is being deleted. This might take a few seconds."
        />
      )}
      {/* <CardPreview card={card} handleClick={() => navigate(`/card/${card.id}`)} />
      <div className="video-date-created">
        {formatSimpleDate(card.date, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })}
      </div> */}

      <ItemCard
        key={card.id}
        object={card}
        type="dashboard-video"
        handleClick={() => navigate(`/card/${card.id}`)}
      />

      <div className="video-stats">
        <div className="views">Views: {parseViews(card.views || 0)}</div>
        <div className="vertical-line"></div>
        <div className="favorites">
          {" "}
          Favorites: {parseViews(card.likes || card.likes_count || 0)}
        </div>
        <div className="vertical-line"></div>
        <div className="playlists">
          {" "}
          Playlists: {parseViews(card.playlists_count || 0)}
        </div>
      </div>

      <div className="video-status">
        <DropdownMenuPrivacy
          category="privacy"
          currentVisibility={card.visibility}
          handleVisibilityChange={handleVisibilityChange}
        />
      </div>

      <div className="video-controls">
        <div
          className="control-icon"
          onClick={() => handleEditCard(card.id)}
          data-cy="edit-card-button"
        >
          <TooltipComponent tipText={"Edit"} placement={"bottom"} icon={edit} />
        </div>
        <div className="control-icon">
          <PlaylistDropdown
            video={card}
            itemId={card.id}
            addFunction="card"
            isDashboard={true}
          />
        </div>
        <div
          className="control-icon"
          onClick={() => {
            setCardToDeleteId(card.id);
            setShowDangerModal(true);
          }}
          data-cy="delete-card-button"
        >
          <TooltipComponent
            tipText={"Delete"}
            placement={"bottom"}
            icon={trash}
          />
        </div>
      </div>
    </div>
  );
};

export default MyCard;
