import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notifyError } from "../../actions/global.action.js";
import Spinner from "../Spinner/Spinner.jsx";
import Note from "./Note.jsx";
import "./styles.scss";
// import VideoPreview from "../VideoPreview/VideoPreview.js";
import { userService } from "../../services/user.service.ts";
import { setNoteList } from "../../actions/data.js";

const MyNotes = () => {
  /*
  Subpage in Dashboard > My Notes tab, where a user can see a list of all notes they made.
  List of notes with reference to the channel, video name, date, and the note.
  Alligned with the latest Figma design (June 11)
  */

  const noteList = useSelector((state) => state.data.noteList);
  const [loading, setLoading] = useState(noteList.length === 0);
  // const [videoPreview, setVideoPreview] = useState(null);
  const dispatch = useDispatch();
  const updateNoteList = useSelector((state) => state.data.updateNoteList);

  const fetchNoteList = async () => {
    try {
      const response = await userService.getUserNotes();
      response.sort((a, b) => {
        if (a.notes.length > 0 && b.notes.length > 0) {
          return (
            new Date(b.notes[0].last_modified) -
            new Date(a.notes[0].last_modified)
          );
        }
        return 0;
      });
      dispatch(setNoteList(response));
    } catch (e) {
      dispatch(notifyError("Error requesting notes list"));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (updateNoteList) fetchNoteList();
    else setLoading(false);
  }, [dispatch, updateNoteList]);

  const handleChange = () => {
    fetchNoteList();
  };

  const createNoteComponents = (singleNote) => {
    // Only create note if we have valid content and ID
    if (
      !singleNote?.notes?.[0]?.content ||
      singleNote.notes[0].content === "0"
    ) {
      return null;
    }

    // Ensure we have the correct type information
    const noteData = {
      ...singleNote,
      type: singleNote.type || singleNote.card_type || "video", // Set default type if none exists
    };

    return (
      <Note
        key={singleNote.id}
        video={noteData}
        content={singleNote.notes[0].content}
        handleChange={handleChange}
      />
    );
  };

  return (
    <div className="my-notes">
      <div className="initial-heading">
        <div>My notes</div>
      </div>
      <div className="table-headings-component">
        <div className="first-child">Video Information</div>
        <div className="second-child">Notes</div>
        <div className="controls-child">Controls</div>
      </div>
      {/* {videoPreview !== null && (
        <VideoPreview
          videoPreview={videoPreview}
          setVideoPreview={setVideoPreview}
        />
      )} */}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 100px)",
          }}
        >
          <Spinner />
        </div>
      ) : (
        noteList.map((singleNote) => createNoteComponents(singleNote))
      )}
    </div>
  );
};

export default MyNotes;
