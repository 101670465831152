export const parseFrontmatter = (rawContent) => {
  const yamlMatch = rawContent.match(/^---([\s\S]*?)---/);
  if (!yamlMatch) return {};

  const yamlContent = yamlMatch[1];
  const metadata = {};

  yamlContent.split("\n").forEach((line) => {
    const [key, value] = line.split(":").map((str) => str.trim());
    if (key && value) {
      metadata[key] = value;
    }
  });

  return metadata;
};

export const removeFrontmatter = (rawContent) => {
  return rawContent.replace(/^---([\s\S]*?)---/, "").trim();
};
