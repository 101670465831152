import React from "react";
import StatsHeader from "../StatsHeader/StatsHeader";

const DashboardHome = ({ selectedTimeRange, customRange }) => (
  <div className="dashboard-home">
    <h1>Dashboard Overview</h1>
    <StatsHeader timeRange={selectedTimeRange} customRange={customRange} />
  </div>
);

export default DashboardHome;
