import React, { useRef, useState } from "react";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import useOutsideClick from "../../hooks/useOutsideClick";
import cancel from "../../assets/cancel.svg";
import success from "../../assets/success-icon.svg";
import warning from "../../assets/warning-circle-orange.svg";
import trash from "../../assets/trash-pink.svg";
import "./styles.scss";
import Popup from "../../containers/Popup/Popup";
import Spinner from "../../components/Spinner/Spinner";

const AlertModal = ({
  type = "warning",
  title = "Title",
  message = "Message",
  buttonDeleteText = "Delete",
  onContinue,
  onCancel,
  dataCy = "",
}) => {
  /*
    Modal for alerting the user of a warning or error.
    type: "danger", "warning", "success"
   */

  const iconMap = {
    danger: trash,
    warning: warning,
    success: success,
  };
  const [closePopup, setClosePopup] = useState(null);
  const modalRef = useRef();
  useOutsideClick(modalRef, () => setClosePopup("cancel"));

  return (
    <Popup
      className="modal-container"
      closePopup={closePopup}
      closeFunc={closePopup === "continue" ? onContinue : onCancel}
      withContainer={true}
      dataCy={dataCy}
    >
      <div className="message popup" ref={modalRef}>
        {type == "loading" || (
          <div className="cancel">
            <img
              src={cancel}
              alt="cancel"
              onClick={() => setClosePopup("cancel")}
              className="cancel-icon"
            />
          </div>
        )}
        <div className="center">
          {type === "loading" ? (
            <Spinner />
          ) : (
            <div className="icon-div">
              <img src={iconMap[type]} alt="icon" className="icon" />
            </div>
          )}
          {title && <div className="title">{title}</div>}
          {message && <div className="body-message">{message}</div>}
        </div>
        {type === "loading" || (
          <div className="alert-modal-buttons">
            <ButtonComponent
              text="Cancel"
              level="secondary"
              icon={false}
              coverFullWidth={true}
              handleClick={() => setClosePopup("cancel")}
            />
            <ButtonComponent
              text={type === "danger" ? buttonDeleteText : "Confirm"}
              icon={false}
              coverFullWidth={true}
              isPink={type === "danger"}
              handleClick={() => {
                setClosePopup("continue");
              }}
              dataCy={`${dataCy}-confirm-button`}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default AlertModal;
