import React from "react";
import { useEffect } from "react";
import {
  // createSnapModifier,
  restrictToParentElement,
} from "@dnd-kit/modifiers";

import { SortableTree } from "./dnd-kit/SortableTree.tsx";
import DraggableItem from "./DraggableItem/DraggableItem.jsx";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchPlaylists,
  setCurrentPlaylist,
  setPlaylists,
  updatePlaylistStructure,
  updatePlaylistVisibility,
} from "../../actions/playlist.js";
import "./styles.scss";

export default function DragAndDropPlaylistArea({ items }) {
  const dispatch = useDispatch();
  // const items = useSelector(state => state.playlist.playlists);
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const showPlaylistWatch = useSelector(
    (state) => state.playlist.watchingPlaylist,
  );

  const currentPlaylistName = useSelector(
    (state) => state.playlist.currentPlaylistName,
  );
  const creatingPlaylistVisibility = useSelector(
    (state) => state.playlist.currentPlaylistVisibility,
  );

  useEffect(() => {
    console.log("fetched playlist")
    dispatch(fetchPlaylists());
  }, []);

  const populateCreatingPlaylist = (items) => {
    if (showPlaylistWatch === "create") {
      const newPlaylistItem = {
        id: "new-playlist",
        title: currentPlaylistName,
        isCreating: true,
        depth: 0,
        children: [],
        collapsed: false,
        canHaveChildren: false,
        disableSorting: true,
        visibility: creatingPlaylistVisibility,
      };

      const playlistExists = items.some((item) => item.id === "new-playlist");

      if (!playlistExists) {
        return [newPlaylistItem, ...items];
      }
    }
    return items;
  };

  // In very rare casees we encounter a ResizeObserver error which is known to be a safe-to-ignore-error
  // This code handles ignoring the message, see more https://github.com/WICG/resize-observer/issues/38

  window.addEventListener("error", function (event) {
    if (event.message.includes("ResizeObserver loop")) {
      console.warn("Ignored ResizeObserver loop error:", event.message);
      event.preventDefault();
    } else if (event.message.includes("ResizeObserver loop limit exceeded")) {
      console.warn("Ignored ResizeObserver loop error:", event.message);
      event.preventDefault();
    }
  });

  const categorizeItems = (items) => {
    const publicItems = items.filter((item) => item.visibility === "public");
    const privateItems = items.filter((item) => item.visibility === "private");
    const unlistedItems = items.filter(
      (item) => item.visibility === "unlisted",
    );

    return { publicItems, privateItems, unlistedItems };
  };

  const { publicItems, privateItems, unlistedItems } = categorizeItems(items);

  const sortedItems = [...publicItems, ...unlistedItems, ...privateItems];

  const handleItemsChanged = (newPlaylists, reason) => {
    dispatch(setPlaylists(newPlaylists));
    dispatch(updatePlaylistStructure(newPlaylists, currentPlaylist));
  };

  return (
    <div className="playlists">
      <SortableTree
        items={populateCreatingPlaylist(sortedItems)}
        onItemsChanged={(newItems, reason) => {
          dispatch(setPlaylists(newItems));
          if (reason.type === "dropped") {
            dispatch(updatePlaylistStructure(newItems, currentPlaylist));
            if (
              currentPlaylist &&
              currentPlaylist.playlist_item_id ===
                reason.draggedItem.playlist_item_id
            ) {
              let newVisibility = currentPlaylist.visibility;
              const parentVisibility = reason.droppedToParent?.visibility;
              if (
                parentVisibility === "public" ||
                (parentVisibility === "unlisted" &&
                  currentPlaylist.visibility === "private")
              ) {
                newVisibility = parentVisibility;
              }
              dispatch(
                setCurrentPlaylist({
                  ...currentPlaylist,
                  parent: reason.droppedToParent,
                }),
              );
              dispatch(updatePlaylistVisibility(newVisibility));
            }
          } else {
            dispatch(updatePlaylistStructure(newItems, currentPlaylist, false));
          }
          // handleItemsChanged(newItems, reason)
        }}
        TreeItemComponent={DraggableItem}
        modifiers={[restrictToParentElement]}
        sortableProps={{
          animateLayoutChanges: () => false,
          resizeObserverConfig: { disabled: false },
        }}
        dropAnimation={null}
      />
      {/* <button onClick={handleSubmit} className="button">Submit</button> */}
    </div>
  );
}
