import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LoginForm from "../AuthForm/LoginForm.jsx";
import SignupForm from "../AuthForm/SignupForm.jsx";
import SubscriptionForm from "../SubscriptionForm/SubscriptionForm.jsx";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu.jsx";
import hamburger from "../../assets/hamburger.svg";
import edgurLogoIcon from "../../assets/edgur-logo-icon.png";
import edgurLogoText from "../../assets/edgur-logo-text.svg";
import ButtonComponent from "../ButtonComponent/ButtonComponent.jsx";
import AuthorAvatar from "../AuthorAvatar/AuthorAvatar.jsx";
import UploadPopUpForm from "../../modals/UploadPopUpForm/UploadPopUpForm.jsx";
import UploadAssCard from "../../modals/UploadAssCard/UploadAssCard.jsx";
import DropdownMyAccount from "../DropdownMyAccount/DropdownMyAccount.jsx";
import DropdownAddOptions from "../DropdownMyAccount/DropdownAddOptions.jsx";
// import Notification from "../../assets/notification.svg";
import SearchBarComponent from "../SearchBarComponent/SearchBarComponent.jsx";
import SearchModal from "../../modals/SearchModal/SearchModal.jsx";
import "./styles.scss";
import { videoService } from "../../services/video.service.ts";
import {
  hideModal,
  showLogin,
  showSignup,
} from "../../actions/global.action.js";
import { playlistService } from "../../services/playlist.service.ts";
import AIRecommendationModal from "../../modals/AIRecommendationModal/AIRecommendationModal.jsx";
import ShortcutsModal from "../../modals/ShortcutsModal/ShortcutsModal.jsx";
import AddVideoToPlaylistPopOut from "../VideoList/AddVideoToPlaylistPopOut.jsx";
import VideoPreview from "../VideoPreview/VideoPreview.jsx";
import Search from "../../assets/search.svg";
import { useDebounce } from "../../hooks/useDebounce.js";

const Navbar = () => {
  /*
  Navigation bar on top of the website with website name, logo, and playlist search bar.
  Alligned with the latest Figma design (May 24) 
  */

  const displayLogin = useSelector((state) => state.global.showLogin);
  const displaySignup = useSelector((state) => state.global.showSignUp);
  const displaySubscription = useSelector(
    (state) => state.global.showSubscription,
  );
  const showModal = useSelector((state) => state.global.showModal);
  const modalType = useSelector((state) => state.global.modalType);
  const videoPreview = useSelector((state) => state.global.videoPreview);
  const [showHamburgerMenu, setShowHamburgerMenu] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showMyAccount, setShowMyAccount] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [query, setQuery] = useState("");
  const [queryResults, setQueryResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aiSearchStatus, setAiSearchStatus] = useState("not started");
  const [aiGeneratedPlaylist, setAiGeneratedPlaylist] = useState([]);
  const [isAiSearch, setIsAiSearch] = useState(false);
  const [showAiPlaylist, setShowAiPlaylist] = useState(false);
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toggleRef = useRef(null);
  const [state, setState] = useState("magnifier");
  const [showAddOptions, setShowAddOptions] = useState(false);
  const [showUploadAssCard, setShowUploadAssCard] = useState(false);

  const guestLinks = () => (
    <div className="navbar-group">
      <ButtonComponent
        text="Sign up"
        icon={false}
        level="secondary"
        size="medium"
        handleClick={() => dispatch(showSignup())}
        dataCy="sign-up-button"
      />
      {state === "normal" && (
        <ButtonComponent
          text="Sign in"
          icon={false}
          level="primary"
          size="medium"
          handleClick={() => dispatch(showLogin())}
          dataCy="sign-in-button"
        />
      )}
    </div>
  );

  const authLinks = () => (
    <div className="navbar-group">
      {!(state === "magnifier" && showSearchModal) && (
        <div
          className="navbar-group-item"
          onClick={() => setShowAddOptions(true)}
        >
          <ButtonComponent
            text="Create"
            level="primary"
            size="small"
            addCircleIcon={true}
            dataCy="nav-create-button"
          />
          {showAddOptions ? (
            <DropdownAddOptions
              handleClose={() => {
                setTimeout(() => setShowAddOptions(false), 150);
              }}
              setShowUpload={() => setShowUpload(true)}
              setShowUploadAssCard={() => setShowUploadAssCard(true)}
            />
          ) : null}
        </div>
      )}
      {/* <img className="navbar-group-item" src={Notification} onClick={() => navigate('/not-created-yet')}/> */}
      {(state !== "magnifier" || !showSearchModal) && (
        <div className="navbar-group-item">
          <div className="avatar" data-cy="nav-avatar">
            <AuthorAvatar
              type="Photo"
              handleClick={() => {
                setShowMyAccount(true);
              }}
            />
          </div>
          {showMyAccount ? (
            <DropdownMyAccount
              handleClose={() => {
                setTimeout(() => setShowMyAccount(false), 150);
              }}
              setShowKeyboardShortcuts={() => setShowKeyboardShortcuts(true)}
            />
          ) : null}
        </div>
      )}
    </div>
  );

  useEffect(() => {
    const updateState = () => {
      const screenWidth = window.innerWidth;
      let newState;
      if (screenWidth >= 700) {
        newState = "normal";
      } else if (screenWidth >= 400) {
        newState = "no-edgur";
      } else {
        newState = "magnifier";
        setIsAiSearch(false);
      }

      if (newState !== state) {
        setState(newState);
      }
    };

    updateState();

    window.addEventListener("resize", updateState);
    return () => {
      window.removeEventListener("resize", updateState);
    };
  }, [state]);

  const handleQuery = () => {
    setLoading(true);
    videoService
      .search(query)
      .then((response) => {
        setQueryResults(response);
      })
      .catch((error) => {
        console.error("Search error:", error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAiSearch = async () => {
    setAiSearchStatus("generating");
    try {
      const data = await playlistService.generatePlaylist(query);
      setAiGeneratedPlaylist(data);
    } catch (error) {
      console.error("Failed to generate playlist:", error);
    } finally {
      if (showSearchModal) {
        setShowAiPlaylist(true);
        setShowSearchModal(false);
      }
    }
  };

  const debouncedHandleQuery = useDebounce(handleQuery, 500);

  useEffect(() => {
    debouncedHandleQuery();
  }, [query]);

  useEffect(() => {
    if (!showSearchModal) {
      setAiSearchStatus("not started");
    }
  }, [showSearchModal]);

  return (
    <>
      <nav className="navbar-main">
        <div className="hamburger-header">
          {(state !== "magnifier" || !showSearchModal) && (
            <img
              src={hamburger}
              className="hamburger-header-bars"
              onClick={() => {
                setShowHamburgerMenu(!showHamburgerMenu);
              }}
              data-cy="hamburger-menu-button"
            />
          )}
          <div className="hamburger-header-logo" onClick={() => navigate("/")}>
            {!(state === "magnifier" && showSearchModal) && (
              <img src={edgurLogoIcon} className="hamburger-header-logo-icon" />
            )}
            {state === "normal" && (
              <img src={edgurLogoText} className="hamburger-header-logo-text" />
            )}
          </div>
        </div>

        <div
          className={`search-bar-component${state === "magnifier" ? " full-width" : ""}`}
          data-cy="nav-search-bar"
        >
          {(state === "normal" || state === "no-edgur" || showSearchModal) && (
            <SearchBarComponent
              handleChange={(e) => {
                setQuery(e.target.value);
                if (e.target.value.length === 0) {
                  setQueryResults([]);
                }
              }}
              setIsFocused={(newState) => {
                if (state !== "magnifier") {
                  setShowSearchModal(newState);
                }
              }}
              handleClose={() => {
                setShowSearchModal(false);
              }}
              // isAiSearchComponent={state !== "magnifier"} comment it out until we fix this issue
              placeholder={
                isAiSearch && state !== "magnifier"
                  ? "Create an AI-generated playlist based on your prompt. [Beta]"
                  : "What do you want to learn today..."
              }
              toggleRef={toggleRef}
              isAiSearch={isAiSearch}
              setIsAiSearch={setIsAiSearch}
              isMagnifier={state === "magnifier"}
            />
          )}
          {state === "magnifier" && !showSearchModal && (
            <img
              src={Search}
              className="search-bar-component-magnifier"
              onClick={() => setShowSearchModal(true)}
            />
          )}
          {showSearchModal && (
            <SearchModal
              query={queryResults}
              queryTerm={query}
              handleClose={() => setShowSearchModal(false)}
              loading={loading}
              isAiSearch={state !== "magnifier" && isAiSearch}
              closeAiSearch={() => toggleRef.current.click()}
              aiSearchStatus={aiSearchStatus}
              handleAiSearch={handleAiSearch}
              setShowAiPlaylist={setShowAiPlaylist}
            />
          )}
        </div>
        {(state !== "magnifier" || !showSearchModal) && (
          <div className="navbar-right">
            {isAuthenticated !== null
              ? isAuthenticated
                ? authLinks()
                : guestLinks()
              : null}
          </div>
        )}
      </nav>

      {showAiPlaylist && (
        <AIRecommendationModal
          playlist={aiGeneratedPlaylist}
          handleClose={() => setShowAiPlaylist(false)}
        />
      )}

      {showHamburgerMenu && (
        <HamburgerMenu closeMenu={() => setShowHamburgerMenu(false)} />
      )}

      {displayLogin && <LoginForm />}
      {displaySignup && <SignupForm />}
      {displaySubscription && <SubscriptionForm />}
      {showUpload && (
        <UploadPopUpForm handleClose={() => setShowUpload(false)} />
      )}
      {showUploadAssCard && (
        <UploadAssCard handleClose={() => setShowUploadAssCard(false)} />
      )}
      {showKeyboardShortcuts && (
        <ShortcutsModal onClose={() => setShowKeyboardShortcuts(false)} />
      )}
      {showModal && modalType === "add-video-to-playlist" && (
        <AddVideoToPlaylistPopOut />
      )}
      {showModal && modalType === "show-video-preview" && (
        <>
          <VideoPreview
            videoPreview={videoPreview}
            setVideoPreview={() => dispatch(hideModal())}
          />
        </>
      )}
    </>
  );
};

export default Navbar;
