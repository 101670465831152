import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { cardService } from "../../../services/card.service";
import Spinner from "../../Spinner/Spinner";
import PaginationControls from "../PaginationControls/PaginationControls";
import AdminCardsCard from "../AdminCardsCard/AdminCardsCard";
import AlertModal from "../../../modals/AlertModal/AlertModal";
import { notifySuccess, notifyError } from "../../../actions/global.action";
import { useDebounce } from "../../../hooks/useDebounce";
import "./styles.scss";

const CardsTable = ({ timeRange, customRange }) => {
  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCards, setSelectedCards] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCards, setTotalCards] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const responseCache = useRef(new Map());

  // Generate a cache key based on current parameters
  const getCacheKey = useCallback(() => {
    return `${timeRange}-${currentPage}-${pageSize}-${sortConfig.key}-${sortConfig.direction}-${debouncedSearchTerm}`;
  }, [timeRange, currentPage, pageSize, sortConfig, debouncedSearchTerm]);

  // Debounced search
  const debouncedSearch = useDebounce((value) => {
    setDebouncedSearchTerm(value);
    setCurrentPage(1);
  }, 500);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleSearchKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setDebouncedSearchTerm(searchTerm);
      setCurrentPage(1);
    }
  };

  const fetchCards = useCallback(async () => {
    try {
      setSearchLoading(true);

      // Check cache first
      const cacheKey = getCacheKey();
      if (responseCache.current.has(cacheKey)) {
        const cachedData = responseCache.current.get(cacheKey);
        setCards(cachedData.cards);
        setTotalCards(cachedData.total);
        setTotalPages(cachedData.pages);
        setSearchLoading(false);
        setLoading(false);
        return;
      }

      // Build query params
      const params = {
        page: currentPage,
        page_size: pageSize,
        sort_by: sortConfig.key,
        sort_order: sortConfig.direction,
        search: debouncedSearchTerm,
        timeRange: timeRange,
      };

      // Add custom range if selected
      if (
        timeRange === "Custom" &&
        customRange?.startDate &&
        customRange?.endDate
      ) {
        params.customRange = {
          startDate: customRange.startDate,
          endDate: customRange.endDate,
        };
      }

      const response = await cardService.getAllCardsAdmin(params);

      // Cache the response
      responseCache.current.set(cacheKey, {
        cards: response.cards,
        total: response.total,
        pages: response.pages,
      });

      // Limit cache size to prevent memory issues
      if (responseCache.current.size > 50) {
        const firstKey = responseCache.current.keys().next().value;
        responseCache.current.delete(firstKey);
      }

      setCards(response.cards);
      setTotalCards(response.total);
      setTotalPages(response.pages);
      setError(null);
    } catch (err) {
      console.error("Error fetching cards:", err);
      setError("Failed to load cards");
      notifyError("Failed to load cards");
    } finally {
      setLoading(false);
      setSearchLoading(false);
    }
  }, [
    currentPage,
    pageSize,
    sortConfig,
    timeRange,
    customRange,
    debouncedSearchTerm,
    getCacheKey,
  ]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  useEffect(() => {
    responseCache.current.clear();
    setCurrentPage(1);
  }, [timeRange, customRange]);

  const handleSort = (key) => {
    let direction = "desc";
    if (sortConfig.key === key && sortConfig.direction === "desc") {
      direction = "asc";
    }

    // Special handling for card_type sorting
    if (key === "card_type") {
      const typeOrder = {
        flashcard: 1,
        assessment: 2,
        knowledge: 3,
        unknown: 4,
      };

      setCards((prevCards) => {
        const sortedCards = [...prevCards].sort((a, b) => {
          const typeA = a.card_type?.toLowerCase() || "unknown";
          const typeB = b.card_type?.toLowerCase() || "unknown";

          const orderA = typeOrder[typeA] || 4;
          const orderB = typeOrder[typeB] || 4;

          if (direction === "asc") {
            return orderA - orderB;
          }
          return orderB - orderA;
        });
        return sortedCards;
      });
    }

    setSortConfig({ key, direction });
    setCurrentPage(1);
  };

  const handleSelectCard = (cardId) => {
    setSelectedCards((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(cardId)) {
        newSelected.delete(cardId);
      } else {
        newSelected.add(cardId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = () => {
    if (selectedCards.size === cards.length && cards.length > 0) {
      setSelectedCards(new Set());
    } else {
      setSelectedCards(new Set(cards.map((card) => card.id)));
    }
  };

  const handleDeleteCards = async () => {
    try {
      setLoading(true);
      const cardIds = Array.from(selectedCards);

      for (const id of cardIds) {
        await cardService.deleteAdminCard(id);
      }

      setCards((prevCards) =>
        prevCards.filter((card) => !selectedCards.has(card.id)),
      );
      setSelectedCards(new Set());

      setShowDeleteModal(false);

      responseCache.current.clear();
      notifySuccess(`Successfully deleted ${cardIds.length} card(s)`);
    } catch (error) {
      console.error("Error deleting cards:", error);
      notifyError("Failed to delete cards");
    } finally {
      setLoading(false);
    }
  };

  const refreshData = useCallback((deletedCardId) => {
    setCards((prevCards) =>
      prevCards.filter((card) => card.id !== deletedCardId),
    );
    setSelectedCards((prev) => {
      const newSet = new Set(prev);
      newSet.delete(deletedCardId);
      return newSet;
    });
    // Clear cache to ensure fresh data on next fetch
    responseCache.current.clear();
  }, []);

  if (loading && !searchLoading) {
    return (
      <div className="loading-container">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  return (
    <div className="admin-cards-wrapper">
      <div className="table-controls">
        <div className="search-field">
          <input
            type="text"
            placeholder="Search cards..."
            value={searchTerm}
            onChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
          />
          {searchLoading && (
            <span className="search-indicator">
              <Spinner size="small" />
            </span>
          )}
        </div>
        {selectedCards.size > 0 && (
          <button
            className="delete-selected"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete Selected ({selectedCards.size})
          </button>
        )}
      </div>

      <div className="table-container">
        <div className="table-scroll-container">
          <table className="admin-cards-table">
            <thead>
              <tr>
                <th className="checkbox-header">
                  <input
                    type="checkbox"
                    checked={
                      selectedCards.size === cards.length && cards.length > 0
                    }
                    onChange={handleSelectAll}
                  />
                </th>
                <th
                  onClick={() => handleSort("title")}
                  className={`card-info-header sortable ${
                    sortConfig.key === "title" ? "sorted" : ""
                  }`}
                >
                  Card Info
                </th>
                <th
                  onClick={() => handleSort("author")}
                  className={`channel-header sortable ${
                    sortConfig.key === "author" ? "sorted" : ""
                  }`}
                >
                  Channel
                </th>
                <th
                  onClick={() => handleSort("date")}
                  className={`date-header sortable ${
                    sortConfig.key === "date" ? "sorted" : ""
                  }`}
                >
                  Date Created
                </th>
                <th
                  onClick={() => handleSort("card_type")}
                  className={`type-header sortable ${
                    sortConfig.key === "card_type"
                      ? `sorted ${sortConfig.direction}`
                      : ""
                  }`}
                >
                  Type
                </th>
                <th
                  onClick={() => handleSort("views")}
                  className={`numeric-header sortable ${
                    sortConfig.key === "views" ? "sorted" : ""
                  }`}
                >
                  Views
                </th>
                <th
                  onClick={() => handleSort("likes_count")}
                  className={`numeric-header sortable ${
                    sortConfig.key === "likes_count" ? "sorted" : ""
                  }`}
                >
                  Likes
                </th>
                <th
                  onClick={() => handleSort("playlists_count")}
                  className={`numeric-header sortable ${
                    sortConfig.key === "playlists_count" ? "sorted" : ""
                  }`}
                >
                  Playlists
                </th>
                <th
                  onClick={() => handleSort("notes_count")}
                  className={`numeric-header sortable ${
                    sortConfig.key === "notes_count" ? "sorted" : ""
                  }`}
                >
                  Notes
                </th>
                <th
                  onClick={() => handleSort("visibility")}
                  className={`visibility-header sortable ${
                    sortConfig.key === "visibility" ? "sorted" : ""
                  }`}
                >
                  Visibility
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {searchLoading ? (
                <tr>
                  <td colSpan="11" className="loading-cell">
                    <Spinner />
                  </td>
                </tr>
              ) : cards.length === 0 ? (
                <tr>
                  <td colSpan="11" className="no-results">
                    No cards found
                  </td>
                </tr>
              ) : (
                cards.map((card) => (
                  <AdminCardsCard
                    key={card.id}
                    card={card}
                    isSelected={selectedCards.has(card.id)}
                    onSelect={handleSelectCard}
                    refreshData={() => refreshData(card.id)}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>

        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          pageSize={pageSize}
          onPageChange={setCurrentPage}
          onPageSizeChange={(size) => {
            setPageSize(size);
            setCurrentPage(1);
          }}
          totalRows={totalCards}
        />
      </div>

      {showDeleteModal && (
        <AlertModal
          type="danger"
          title="Delete Cards"
          message={`Are you sure you want to delete ${selectedCards.size} selected card(s)?`}
          buttonDeleteText="Delete"
          onContinue={handleDeleteCards}
          onCancel={() => setShowDeleteModal(false)}
          dataCy="delete-cards-modal"
        />
      )}
    </div>
  );
};

export default CardsTable;
