import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { showSignup } from "../../actions/global.action.js";
import { videoService } from "../../services/video.service.ts";
import { cardData } from "./constants.js";
import { cardService } from "../../services/card.service.ts";
import { updateFavorites } from "../../actions/data.js";

import AuthorInfo from "../AuthorInfo/AuthorInfo.jsx";
import Video from "../Video/Video.jsx";
import crossSign from "../../assets/cross-sign.svg";
import RichTextEditorComponent from "../RichTextEditor/RichTextEditorComponent";
import DescriptionTag from "../DescriptionTag/DescriptionTag";
import Playlist from "../Playlist/Playlist";
import Sidebar from "../Sidebar/Sidebar";
import Spinner from "../Spinner/Spinner";
import CardComponent from "../CardComponent/CardComponent";
import PlaylistTopCard from "./PlaylistTopCard";
import PlaylistBottomCard from "./PlaylistBottomCard";

import "./styles.scss";

const FeedCard = ({
  type,
  data,
  video,
  deviceType,
  isMyTab,
  isDetailMode,
  setIsDetailMode,
  selectedOption,
  setSelectedOption,
  navigateToItem,
  playlistTitle,
  currFeedCardIndex,
  index,
  handleChevron,
  navigateToNextItem,
}) => {
  /*
  Feed Card is a component used to display a video card in the feed.
  It handles all the logic and UI. It includes both usual and detailed modes.

  Props: 
  - isDetailMode: boolean that indicates if the card is in detailed mode
  - isMyTab: boolean that indicates if the card is in My Tab
  - setIsDetailMode: callback prop to set the detailed mode
  - video: object that contains all the information about the video
  - selectedOption: string that indicates the selected option in the sidebar
  - setSelectedOption: callback prop to set the selected option

  Possible cases for the bottom of the detail mode:
  - notes: RichTextEditorComponent
  - playlist/upNext/recs: Playlist
  - description: empty block (default case)
   */

  // General hooks

  const location = useLocation();
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(currFeedCardIndex === index);
  const upNextType = location.pathname.split("/")[1];
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [isLoading, setIsLoading] = useState(true);

  // UI variables related to detail mode
  const isLandscape = video?.video_type === "landscape";
  const [sideContent, setSideContent] = useState(<div></div>);
  const [animationClass, setAnimationClass] = useState("");
  const [allowRender, setAllowRender] = useState(true);
  const [allowClosingRender, setAllowClosingRender] = useState(false);
  const [isSlowClosing, setIsSlowClosing] = useState(false);

  // Like-related state variables
  const [isLiked, setIsLiked] = useState(video.is_liked);
  const [likeCount, setLikeCount] = useState(video.likes_count);
  const [cardLikeCount, setCardLikeCount] = useState(video.likes);
  const [showExplanation, setShowExplanation] = useState(false);
  const isPlaylistCard = video?.children?.length > 0;

  // Sets visibility of this FeedCard
  useEffect(() => {
    const newVisibility = currFeedCardIndex === index;
    if (isVisible !== newVisibility) {
      setIsVisible(newVisibility);
    }
  }, [currFeedCardIndex]);

  // Reset explanation state when moving to a different card
  useEffect(() => {
    if (!isVisible) {
      setShowExplanation(false);
    }
  }, [isVisible]);

  useEffect(() => {
    // Only proceed if the card becomes visible
    if (isVisible && type === "card" && video?.id) {
      const playlistId = location.pathname.includes("/playlist/")
        ? location.pathname.split("/")[2]
        : "";

      videoService.addToWatchHistory(video.id, playlistId).catch((error) => {
        console.error("Error adding card to watch history:", error);
      });
    }
  }, [isVisible, type, video?.id]);

  useEffect(() => {
    if (isDetailMode) {
      setAllowClosingRender(true);
      setAnimationClass("opening");
      setAllowRender(true);
      setTimeout(() => {
        setAnimationClass("normal");
        setIsLoading(false);
      }, 300);
    } else {
      setIsSlowClosing(true);
      setAnimationClass("closing");
      setTimeout(() => {
        setAnimationClass("normal");
        setAllowRender(false);
        setIsSlowClosing(false);
        setAllowClosingRender(false);
        setIsLoading(false);
      }, 300);
    }
  }, [isDetailMode]);

  const handleLike = useCallback(() => {
    if (!isAuthenticated) {
      dispatch(showSignup(true));
      return;
    }

    const newLikeCount = isLiked ? likeCount - 1 : likeCount + 1;
    const newCardLikeCount = isLiked ? cardLikeCount - 1 : cardLikeCount + 1;
    setLikeCount(newLikeCount);
    setCardLikeCount(newCardLikeCount);
    setIsLiked(!isLiked);
    dispatch(updateFavorites());

    const service = type === "card" ? cardService : videoService;
    const likeAction = isLiked ? service.removeLike : service.addLike;
    likeAction(video.id).catch(console.error);
  }, [
    isAuthenticated,
    isLiked,
    likeCount,
    cardLikeCount,
    dispatch,
    type,
    video.id,
  ]);

  useEffect(() => {
    if (type === "card" && video?.id) {
      const timer = setTimeout(() => {
        cardService.updateCardViews(video.id);
      }, 10000); // 10 seconds delay

      return () => clearTimeout(timer);
    }
  }, [type, video?.id]);

  useEffect(() => {
    const handleSideContentChange = () => {
      let currentlySelectedOption = selectedOption;
      if (["playlist", "upNext", "recommended"].includes(selectedOption)) {
        const newOption = upNextType === "playlist" ? "playlist" : "upNext";
        setSelectedOption(newOption);
        currentlySelectedOption = newOption;
      }

      const sideContentMap = {
        notes: (
          <RichTextEditorComponent
            key={video.id + index}
            object={video}
            type="notes"
            title="Notes"
            placeholder="Add a note..."
          />
        ),
        playlist: (
          <Playlist
            key={video?.id + index}
            type={currentlySelectedOption}
            playlistItems={data}
            playlistTitle={playlistTitle}
            navigateToItem={navigateToItem}
            index={index}
          />
        ),
        upNext: (
          <Playlist
            key={video?.id + index}
            type={currentlySelectedOption}
            playlistItems={data}
            playlistTitle={playlistTitle}
            navigateToItem={navigateToItem}
            index={index}
          />
        ),
        recommended: (
          <Playlist
            key={video?.id + index}
            type={currentlySelectedOption}
            playlistItems={data}
            playlistTitle={playlistTitle}
            navigateToItem={navigateToItem}
            index={index}
          />
        ),
        description: (
          <div className="description-bottom">
            <></>
          </div>
        ),
      };

      setSideContent(sideContentMap[currentlySelectedOption] || <div></div>);
    };
    if (isVisible) {
      handleSideContentChange();
    }
  }, [
    selectedOption,
    video,
    isVisible,
    upNextType,
    data,
    playlistTitle,
    navigateToItem,
    index,
  ]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (
        ["tiptap", "ProseMirror", "text-input-component-input"].some((cls) =>
          event.target.classList.contains(cls),
        )
      ) {
        return;
      }

      if (event.ctrlKey || event.altKey || event.metaKey || event.shiftKey) {
        return;
      }

      const keyActions = {
        n: () => {
          if (isAuthenticated) {
            setSelectedOption("notes");
            setIsDetailMode(!isDetailMode);
            event.preventDefault();
          }
        },
        ".": () => {
          if (isAuthenticated) {
            setIsDetailMode(!isDetailMode);
            event.preventDefault();
          }
        },
      };

      const action = keyActions[event.key.toLowerCase()];
      if (action) action();
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [
    isVisible,
    isDetailMode,
    isAuthenticated,
    setSelectedOption,
    setIsDetailMode,
  ]);

  if (!video?.video_url && type === "video") {
    console.error("[ERROR] Missing video props in video component:", video);
    return <div className="error-message">Error loading content</div>;
  }

  if (isLoading) {
    return (
      <div className="spinner-container-feed">
        <Spinner />
      </div>
    );
  }

  return (
    !isLoading && (
      <div
        className={`video-card${(isDetailMode || isSlowClosing || allowClosingRender) && !isPlaylistCard ? ` video-card-focused ` : ""}${type === "video-preview" ? " video-card-preview" : ""} ${deviceType}`}
      >
        <div
          className={`left-video-part${isDetailMode && !isPlaylistCard ? ` left-focus` : ""}`}
        >
          <>
            {isDetailMode && (
              <div
                className="cancel"
                onClick={() => {
                  setSelectedOption(null);
                  setIsDetailMode(!isDetailMode);
                }}
              >
                <button>
                  <img src={crossSign} />
                </button>
              </div>
            )}
            {isPlaylistCard > 0 && index === 1 && (
              <PlaylistTopCard
                playlist={null}
                deviceType={deviceType}
                type={type}
              />
            )}
            {isPlaylistCard > 0 && index !== 1 && (
              <PlaylistBottomCard playlist={null} />
            )}
            {isLandscape && type !== "card" && !isPlaylistCard && (
              <div className="landscape-sidebar-container">
                <div
                  className={`landscape-video-container ${(isMyTab || type === "video-preview") && !isDetailMode ? "landscape-my-tab" : ""}${isDetailMode || allowClosingRender || deviceType === "mobile" ? " landscape-focus" : ""}`}
                  id={video.id}
                >
                  <div className="landscape-video-top">
                    <Video
                      id={video?.id}
                      video_type={video?.video_type}
                      video_url={video?.video_url}
                      key={video?.id + index}
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                      isDetailMode={isDetailMode}
                      setIsDetailMode={setIsDetailMode}
                      isVisible={isVisible} // currFeedCardIndex is not passed bc isVisible = (currFeedCardIndex === index)
                      index={index}
                      navigateToNextItem={navigateToNextItem}
                    />
                  </div>
                  {!isDetailMode &&
                    !allowClosingRender &&
                    deviceType !== "mobile" && (
                      <div className="landscape-video-bottom">
                        <div className="title">{video?.title}</div>
                        <div className="description-tags-container">
                          <DescriptionTag
                            key={`landscape-${video?.id}-${index}`}
                            video={video}
                            pressSeeMore={() => {
                              setIsDetailMode(true);
                              setSelectedOption("expanded");
                            }}
                            type={type}
                            rowsDefault={1}
                            isInline={true}
                          />
                        </div>
                        <div className="author-info-container">
                          <AuthorInfo
                            author={video?.author}
                            views={video?.views}
                            date={video?.date}
                            authorFromState={true}
                          />
                        </div>
                      </div>
                    )}
                </div>
                {!(
                  isMyTab ||
                  isDetailMode ||
                  allowClosingRender ||
                  type === "video-preview" ||
                  deviceType === "mobile"
                ) ? (
                  <div className="landscape-sidebar">
                    <Sidebar
                      type={type}
                      video={video}
                      setIsDetailMode={setIsDetailMode}
                      setSelectedOption={setSelectedOption}
                      handleLike={handleLike}
                      isLiked={isLiked}
                      likeCount={likeCount}
                    />
                  </div>
                ) : null}
              </div>
            )}
            {!isLandscape && type !== "card" && !isPlaylistCard && (
              <div className="portrait-sidebar-container">
                <div
                  className={`portrait-video-container${isMyTab && !isDetailMode && !allowClosingRender ? " portrait-my-tab" : ""}`}
                  id={video.id}
                >
                  <div
                    className={`portrait-video-left${isDetailMode || allowClosingRender ? " portrait-focus" : ""}`}
                  >
                    <Video
                      id={video?.id}
                      video_type={video?.video_type}
                      video_url={video?.video_url}
                      play={true}
                      key={video?.id + index}
                      setSelectedOption={setSelectedOption}
                      isDetailMode={isDetailMode}
                      setIsDetailMode={setIsDetailMode}
                      poster={video?.thumbnail_url}
                      index={index}
                      isVisible={isVisible}
                      navigateToNextItem={navigateToNextItem}
                    />
                  </div>

                  {!isDetailMode &&
                    !allowClosingRender &&
                    deviceType !== "mobile" && (
                      <div className="portrait-video-right">
                        <div className="title">{video?.title}</div>
                        <div className="description-tags-container">
                          <DescriptionTag
                            key={`portrait-video-${video?.id}-${index}`}
                            video={video}
                            pressSeeMore={() => {
                              setIsDetailMode(true);
                              setSelectedOption("expanded");
                            }}
                            type={type}
                            rowsDefault={4}
                          />
                        </div>
                        <div className="author-info-container">
                          <AuthorInfo
                            author={video?.author}
                            views={video?.views}
                            date={video?.date}
                          />
                        </div>
                      </div>
                    )}
                </div>

                {!(
                  isMyTab ||
                  isDetailMode ||
                  allowClosingRender ||
                  type === "video-preview" ||
                  deviceType === "mobile"
                ) ? (
                  <div className="portrait-sidebar">
                    <Sidebar
                      type={type}
                      video={video}
                      setIsDetailMode={setIsDetailMode}
                      setSelectedOption={setSelectedOption}
                      handleLike={handleLike}
                      isLiked={isLiked}
                      likeCount={likeCount}
                    />
                  </div>
                ) : null}
              </div>
            )}
            {type === "card" && !isPlaylistCard && (
              <div className="portrait-sidebar-container">
                <div
                  className={`portrait-video-container${isMyTab && !isDetailMode && !allowClosingRender ? " portrait-my-tab" : ""}`}
                  id={video.id}
                >
                  <div
                    className={`portrait-video-left${isDetailMode || allowClosingRender ? " portrait-focus" : ""}`}
                  >
                    <CardComponent
                      cardInfo={video}
                      answer1={
                        video.answers && video.answers.length > 0
                          ? video.answers[0]
                          : null
                      }
                      answer2={
                        video.answers && video.answers.length > 1
                          ? video.answers[1]
                          : null
                      }
                      answer3={
                        video.answers && video.answers.length > 2
                          ? video.answers[2]
                          : null
                      }
                      answer4={
                        video.answers && video.answers.length > 3
                          ? video.answers[3]
                          : null
                      }
                      isFunctional={true}
                      showCodeComponent={false}
                      showImageComponent={video.image_url ? true : false}
                      isDetailMode={isDetailMode}
                      index={index}
                      isVisible={isVisible}
                      handleChevron={handleChevron}
                      setShowExplanation={setShowExplanation}
                    />
                  </div>

                  {!isDetailMode &&
                    !allowClosingRender &&
                    deviceType !== "mobile" && (
                      <div className="portrait-video-right">
                        <div className="title">{video?.title}</div>
                        <div className="description-tags-container">
                          <DescriptionTag
                            key={`portrait-card-${video?.id}-${index}`}
                            explanationToShow={
                              showExplanation ? video?.explanation : null
                            }
                            video={cardData[video?.card_type]}
                            pressSeeMore={() => {}}
                            type={type}
                            rowsDefault={4}
                          />
                        </div>
                        <div className="author-info-container">
                          <AuthorInfo
                            author={video?.author}
                            views={video?.views}
                            date={video?.date}
                          />
                        </div>
                      </div>
                    )}
                </div>

                {!(
                  isMyTab ||
                  isDetailMode ||
                  allowClosingRender ||
                  type === "video-preview" ||
                  deviceType === "mobile"
                ) ? (
                  <div className="portrait-sidebar">
                    <Sidebar
                      type={type}
                      video={video}
                      setIsDetailMode={setIsDetailMode}
                      setSelectedOption={setSelectedOption}
                      handleLike={handleLike}
                      isLiked={isLiked}
                      likeCount={cardLikeCount}
                    />
                  </div>
                ) : null}
              </div>
            )}
          </>
        </div>
        {(isDetailMode || isSlowClosing || allowClosingRender) &&
          isVisible &&
          allowRender &&
          !isPlaylistCard && (
            <div className={`right-part ${animationClass} ${deviceType}`}>
              <div className="right-part-top">
                <div className="title">{video?.title}</div>

                <div className="description-tags-container">
                  <DescriptionTag
                    key={`detail-${video?.id}-${index}-${selectedOption}`}
                    explanationToShow={
                      showExplanation ? video?.explanation : null
                    }
                    video={type === "card" ? cardData[video?.card_type] : video}
                    selectedOption={selectedOption}
                    isDetailMode={true}
                    type={type}
                  />
                </div>
                <div className="author-info-container">
                  <AuthorInfo
                    author={video?.author}
                    views={video?.views}
                    date={video?.date}
                  />
                </div>
                <div className="right-sidebar">
                  <Sidebar
                    type={type}
                    video={video}
                    horizontal={true}
                    selected={selectedOption}
                    setSelectedOption={setSelectedOption}
                    setIsDetailMode={setIsDetailMode}
                    handleLike={handleLike}
                    isLiked={isLiked}
                    likeCount={type !== "card" ? likeCount : cardLikeCount}
                  />
                </div>
                <div className="border-line"></div>
              </div>
              <div
                className="right-part-bottom"
                data-cy="feed-bottom-right-side-content"
              >
                {sideContent}
              </div>
            </div>
          )}
      </div>
    )
  );
};

export default FeedCard;
