import React from "react";
import edgurLogo from "../../assets/edgur-logo-text.svg";
import "./styles.scss";
import { Link } from "react-router-dom";

const NotFoundPage = ({ pageType }) => {
  /*
    Page that handles non-existing routes (videos, playlists, channels, and other).
  */
  return (
    <div className="not-found-container">
      <div className="logo-container">
        <img src={edgurLogo} alt="edgur" width={120} height={120} />
      </div>
      <p className="not-found-text">
        {pageType === "video" || pageType === "playlist"
          ? `The ${pageType} you are looking for either no longer exists or has been set to private by the creator.`
          : pageType === "user"
            ? "The channel you are looking for does not exist."
            : "Sorry, the page you are looking for does not exist. Please check the URL and try again."}
      </p>
      <Link to="/">Return to Home</Link>
    </div>
  );
};

export default NotFoundPage;
