import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import TabComponent from "../../components/TabComponent/TabComponent";
import UsersTable from "../../components/AdminDashboard/AdminUsersTable/AdminUsersTable";
import VideosTable from "../../components/AdminDashboard/AdminVideosTable/AdminVideosTable";
import CardsTable from "../../components/AdminDashboard/AdminCardsTable/AdminCardsTable";
import TimeFilter from "../../components/AdminDashboard/TimeFilter/TimeFilter";
import DashboardHome from "../../components/AdminDashboard/DashboardHome/DashboardHome";
import AdminSettings from "../../components/AdminDashboard/AdminSettings/AdminSettings";
import Spinner from "../../components/Spinner/Spinner";
import PlaylistsTable from "../../components/AdminDashboard/AdminPlaylistsTable/AdminPlaylistsTable";
import "./styles.scss";

const AdminDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTimeRange, setSelectedTimeRange] = useState("All Time");
  const [customRange, setCustomRange] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const tabComponents = {
    "Dashboard Overview": (
      <DashboardHome timeRange={selectedTimeRange} customRange={customRange} />
    ),
    Users: (
      <UsersTable timeRange={selectedTimeRange} customRange={customRange} />
    ),
    Videos: (
      <VideosTable timeRange={selectedTimeRange} customRange={customRange} />
    ),
    Cards: (
      <CardsTable timeRange={selectedTimeRange} customRange={customRange} />
    ),
    Playlists: (
      <PlaylistsTable timeRange={selectedTimeRange} customRange={customRange} />
    ),
    "Admin Settings": <AdminSettings />,
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    if (!tab) {
      navigate("/admin?tab=Dashboard+Overview");
    }
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <div className="loading-container">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="dashboard-title">
          <h1>Admin Dashboard</h1>
        </div>
        <div className="tab-menu">
          <TabComponent
            tabs={Object.keys(tabComponents)}
            defaultTab="Dashboard Overview"
          />
        </div>
      </div>
      <div className="time-filter-container">
        {location.search.includes("Admin+Settings") ? null : (
          <TimeFilter
            selectedRange={selectedTimeRange}
            onRangeChange={setSelectedTimeRange}
            customRange={customRange}
            onCustomRangeChange={setCustomRange}
          />
        )}
      </div>
      <div className="admin-tab-content">
        {
          tabComponents[
            new URLSearchParams(location.search).get("tab") ||
              "Dashboard Overview"
          ]
        }
      </div>
    </div>
  );
};

export default AdminDashboard;
