import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountInfoTab from "../../components/AccountInfoTab/AccountInfoTab.jsx";
import PasswordTab from "../../components/PasswordTab/PasswordTab.jsx";
import PublicProfileTab from "../../components/PublicProfileTab/PublicProfileTab.jsx";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent.jsx";
import TabComponent from "../../components/TabComponent/TabComponent.jsx";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../actions/global.action.js";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";
import { USER_LOADED_SUCCESS } from "../../actions/types.js";
import { countChar } from "../../utils/editor.jsx";
import Spinner from "../../components/Spinner/Spinner.jsx";

const Settings = () => {
  /*
          A page that displays user settings: Account Info, Settings, Public Profile.
          Alligned with the latest Figma design (June 2).

          todo:
          add helping information in the bottom of the input fields indicating the problems with the input
      */

  const [activeTab, setActiveTab] = useState("PublicProfile");
  const [readyToSave, setReadyToSave] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [avatar, setAvatar] = useState(null);
  const [interests, setInterests] = useState(null);
  const [introduction, setIntroduction] = useState("");
  const [saving, setSaving] = useState(false);

  const [userInfo, setUserInfo] = useState({
    firstName: "",
    lastName: "",
    username: "",
  });

  const tabComponents = {
    PublicProfile: (
      <PublicProfileTab
        setReadyToSave={setReadyToSave}
        setAvatar={setAvatar}
        setInterests={setInterests}
        setIntroduction={setIntroduction}
      />
    ),
    AccountInfo: (
      <AccountInfoTab
        setReadyToSave={setReadyToSave}
        setUserInfo={setUserInfo}
      />
    ),
    Settings: <PasswordTab />,
    // // Uncomment this when re-enabling Stripe
    // Billing: <SubscriptionTab/>
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const validTabs = ["PublicProfile", "AccountInfo", "Settings"];
    // // Uncomment this when re-enabling Stripe
    // const validTabs = ["PublicProfile", "AccountInfo", "Settings", "Billing"];

    if (tab && validTabs.includes(tab)) {
      setActiveTab(tab);
    } else {
      setActiveTab("PublicProfile");
    }
  }, [location.search]);

  const handleCancel = () => {
    navigate("/");
  };

  const updateAccountInfo = async (userInfo, updateAvatar = false) => {
    setSaving(true);
    const formData = new FormData();
    formData.append("first_name", userInfo.firstName || "");
    formData.append("last_name", userInfo.lastName || "");
    formData.append("username", userInfo.username || "");

    if (userInfo.channelName || userInfo.channelName?.length === 0) {
      formData.append("channel_name", userInfo.channelName || "");
    }
    if (userInfo.introduction) {
      const jsonIntro = parse_introduction(userInfo.introduction);
      if (jsonIntro === 0) {
        setSaving(false);
        return;
      }
      formData.append("introduction", jsonIntro);
    }
    if (userInfo.interests || userInfo.interests?.length === 0) {
      if (userInfo.interests?.length > 10) {
        setSaving(false);
        dispatch(notifyError("No more than 10 interests allowed!"));
        return;
      }
      formData.append("interests", userInfo.interests);
    }
    if (updateAvatar) formData.append("avatar", userInfo.avatar);
    formData.append("update_avatar", updateAvatar || "");

    try {
      const response = await userService.edit_profile(formData);
      if (!response) {
        dispatch(notifyError("User update failed"));
        throw new Error("User update failed");
      }
      dispatch(notifySuccess("User updated successfully!"));
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      dispatch(
        notifyError(error?.response?.data?.detail || "User update failed"),
      );
      console.error("Error:", error);
    } finally {
      setSaving(false);
      setAvatar(null);
    }
  };

  const parse_introduction = (introduction) => {
    const currIntroLength = countChar(introduction);
    if (currIntroLength > 500) {
      dispatch(
        notifyError("Description should be no longer than 500 characters!"),
      );
      return 0;
    }

    const jsonIntro = JSON.stringify(introduction);
    return jsonIntro;
  };

  const handleSave = () => {
    if (activeTab === "AccountInfo") {
      updateAccountInfo(userInfo);
    } else if (activeTab === "PublicProfile") {
      if (avatar || introduction || interests !== null) {
        updateAccountInfo(
          {
            avatar,
            introduction,
            interests,
          },
          !!avatar,
        );
      }
    }
  };

  return (
    <div className="settings">
      <div className="headline">My Account</div>
      <div className="tab">
        <TabComponent
          tabs={Object.keys(tabComponents)}
          defaultTab={"PublicProfile"}
        />
      </div>
      <div className="body">{tabComponents[activeTab]}</div>
      {activeTab !== "Settings" && activeTab != "Billing" && (
        <div className="footer">
          {saving ? (
            <div className="saving-spinner">
              <Spinner size="small" />
            </div>
          ) : (
            <div className="button cancel-button">
              <ButtonComponent
                text="Cancel"
                level="secondary"
                icon={false}
                handleClick={handleCancel}
                coverFullWidth={true}
              />
            </div>
          )}
          <div className="button save-button">
            <ButtonComponent
              text={saving ? "Saving..." : "Save"}
              level={readyToSave && !saving ? "primary" : "disable"}
              icon={false}
              handleClick={handleSave}
              coverFullWidth={true}
              dataCy="save-button"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Settings;
