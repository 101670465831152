import React, { useEffect, useState, useRef } from "react";
import "./styles.scss";
import eye from "../../assets/eye.svg";
import eyeSlash from "../../assets/eye-slash.svg";
import crossSign from "../../assets/cross-sign.svg";
import googleLogo from "../../assets/google-logo.svg";
import githubLogo from "../../assets/github-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  hideLogin,
  notifyError,
  notifySuccess,
  showSignup,
  showSubscription,
} from "../../actions/global.action.js";
import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  USER_LOADED_FAIL,
  USER_LOADED_SUCCESS,
} from "../../actions/types.js";
import authService from "../../services/auth.service.ts";
import { githubAuthenticate, googleAuthenticate } from "../../actions/auth.js";
import Popup from "../../containers/Popup/Popup.jsx";
import leftArrow from "../../assets/chevron-left.svg";
import useOutsideClick from "../../hooks/useOutsideClick.js";

const LoginForm = () => {
  /*
  Login form component for the auth modal.
   */
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetCount, setResetCount] = useState(0); //To ensure a user doesn't send too many requests
  const [resetEmail, setResetEmail] = useState("");
  const [countdown, setCountdown] = useState(30);
  const [sent, setSent] = useState(false); // Tracks if first reset email was sent (from button)
  const [resent, setResent] = useState(false); // Tracks if second reset email was sent (from <a> element)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const navigate = useNavigate();
  const location = useLocation();
  const loginRef = useRef(null);

  const handleClose = () => {
    dispatch(hideLogin());
    if (location.pathname.includes("/login")) {
      navigate("/");
    }
  };
  useOutsideClick(loginRef, () => {
    setClosePopup(true);
  });
  const requestLogin = async (data) => {
    try {
      const response = await authService.login(data);

      if (response) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response,
        });
        requestLoadUser();
        dispatch(notifySuccess("Login successful"));
      }
    } catch (e) {
      let errorMessage = "Invalid email or password. Please try again.";
      if (e.request.response) {
        const error = JSON.parse(e.request.response);
        errorMessage = error.detail;
      }
      dispatch(notifyError(errorMessage));
      dispatch({ type: LOGIN_FAIL });
    }
  };

  const requestLoadUser = async () => {
    try {
      const response = await authService.loadUser();
      if (response) {
        dispatch({
          type: USER_LOADED_SUCCESS,
          payload: response,
        });
      }
    } catch (e) {
      console.log("Login error: ", e);
      dispatch({ type: USER_LOADED_FAIL });
    }
  };

  const handlePasswordReset = async (resend_request = false) => {
    if (resetCount >= 5) {
      dispatch(
        notifyError("Too many password reset requests. Try again later!"),
      );
      return;
    }
    await authService.resetPassword(resetEmail.toLowerCase());
    dispatch(notifySuccess("Reset password email sent!"));
    setResetCount(resetCount + 1);
    setSent(true);

    if (resend_request) {
      setResent(true);
    } else {
      setCountdown(30);
      setResent(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (forgotPassword) {
      handlePasswordReset();
    } else {
      const email_username = e.currentTarget.email.value;
      const formData = {};
      if (email_username.includes("@")) {
        formData["email"] = email_username.toLowerCase();
      } else {
        formData["username"] = email_username;
      }
      formData["password"] = e.currentTarget.password.value;
      requestLogin(formData);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setClosePopup(true);
      // Check if the URL already has a 'source' query parameter
      const urlParams = new URLSearchParams(window.location.search);
      if (!urlParams.has("source")) {
        window.history.replaceState(
          {},
          document.title,
          `${window.location.pathname}?source=success_login`,
        );
      }
      window.location.reload();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [resetCount, countdown]);

  return (
    <Popup
      className="auth-container"
      closePopup={closePopup}
      closeFunc={() => handleClose()}
      withContainer={true}
      key={forgotPassword ? "reset-password" : "login"}
    >
      <div className="auth-form popup" ref={loginRef}>
        <div className="auth-header">
          <div className="left-side-header">
            {forgotPassword && (
              <button
                onClick={() => {
                  setForgotPassword(false);
                }}
                className="return-button"
              >
                <img src={leftArrow} />
              </button>
            )}
            <span className="auth-title">
              {forgotPassword ? "Reset Password" : "Log in to Edgur"}
            </span>
          </div>
          <button
            className="close-button"
            onClick={() => {
              setClosePopup(true);
            }}
          >
            <img src={crossSign} alt="close-button" />
          </button>
        </div>
        {forgotPassword && (
          <div>We will send an email with password reset instructions.</div>
        )}
        <form className="auth-content" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">
              {forgotPassword ? "Email" : "Email or Username"}
            </label>
            <div className="form-input-group">
              <input
                type={forgotPassword ? "email" : "text"}
                name="email"
                id="email"
                className="form-input"
                placeholder={
                  forgotPassword
                    ? "Enter your email"
                    : "Enter your email or username"
                }
                onChange={(e) => {
                  setResetEmail(e.target.value);
                  setSent(false);
                }}
              />
            </div>
          </div>
          {!forgotPassword && (
            <>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <div className="form-input-group">
                  <input
                    required
                    type={showPassword ? "text" : "password"}
                    name="password"
                    id="password"
                    className="form-input"
                    placeholder="Enter your password"
                  />
                  <button
                    type="button"
                    className="toggle-pass"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <img
                      src={showPassword ? eyeSlash : eye}
                      alt="show-password"
                    />
                  </button>
                </div>
              </div>
              <div className="auth-options">
                <a
                  className="forgot-password"
                  onClick={() => setForgotPassword(true)}
                >
                  Forgot password?
                </a>
              </div>
            </>
          )}
          {sent && forgotPassword && (
            <div className="reset-container">
              <div className="reset-completed-text">
                We have sent password reset instructions to your email.
              </div>
              {countdown === 0 ? (
                !resent && (
                  <a
                    className="reset-text"
                    onClick={() => handlePasswordReset(true)}
                  >
                    {" "}
                    Send the email again{" "}
                  </a>
                )
              ) : (
                <div className="reset-completed-text">
                  Resend email in {countdown} seconds
                </div>
              )}
            </div>
          )}
          {!forgotPassword ? (
            <button type="submit" className="submit-button">
              Sign in
            </button>
          ) : (
            <button type="submit" className="submit-button" disabled={sent}>
              {sent ? "Email sent" : "Send email"}
            </button>
          )}
        </form>
        {!forgotPassword && (
          <>
            <div className="or-divider">
              <span className="divider-line"></span>
              <span className="divider-text">or</span>
              <span className="divider-line"></span>
            </div>
            <div className="other-options">
              <button
                className="other-option"
                onClick={() => dispatch(googleAuthenticate())}
              >
                <img src={googleLogo} alt="google-logo" />
                <p>Sign in with Google</p>
              </button>
              <button
                className="other-option"
                onClick={() => dispatch(githubAuthenticate())}
              >
                <img src={githubLogo} alt="github-logo" />
                <p>Sign in with GitHub</p>
              </button>
            </div>
            <span className="auth-footer">
              {"Don't have an account? "}
              <a
                onClick={() => {
                  setClosePopup(true);
                  dispatch(showSignup());
                  if (location.pathname.includes("/login")) {
                    navigate("/signup");
                    window.location.reload();
                  }
                }}
              >
                Sign up
              </a>
            </span>
          </>
        )}
      </div>
    </Popup>
  );
};

export default LoginForm;
