import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserVideoData } from "../../actions/data.js";
import ButtonComponent from "../ButtonComponent/ButtonComponent.jsx";
import UploadPopUpForm from "../../modals/UploadPopUpForm/UploadPopUpForm.jsx";
import { fetchPlaylists } from "../../actions/playlist.js";
import "./styles.scss";
import MyVideo from "./MyVideo.jsx";
// import VideoPreview from "../VideoPreview/VideoPreview";
import { userService } from "../../services/user.service.ts";
import Spinner from "../Spinner/Spinner.jsx";

const MyVideos = () => {
  /*
    Subpage in Dashboard > Videos tab, where a user can see a list of videos they uploaded.
    List of videos with reference to the channel, video name, date, statistics, and video actions.
  */
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data.userVideoData);
  const [showUpload, setShowUpload] = useState(false);
  // const [videoPreview, setVideoPreview] = useState(null);
  const updateVideoData = useSelector(
    (state) => state.data.updateUserVideoData,
  );
  const [loading, setLoading] = useState(data.length === 0);

  const fetchVideos = async () => {
    try {
      const response = await userService.getUserVideos();
      dispatch(setUserVideoData(response));
    } catch (error) {
      console.error("Error fetching videos:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = () => {
    dispatch(fetchPlaylists());
    fetchVideos();
  };

  const videoItems = data
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .map((video) => (
      <MyVideo
        key={video.id}
        video={video}
        handleChange={handleChange}
        // preview={setVideoPreview}
      />
    ));

  const handleClose = () => {
    setShowUpload(false);
  };

  useEffect(() => {
    if (updateVideoData) fetchVideos();
    else setLoading(false);
  }, [dispatch, updateVideoData]);

  return (
    data && (
      <div className="my-content">
        <div className="initial-heading">
          <div>Videos</div>
          <div className="initial-heading-button">
            <ButtonComponent
              text="Upload Videos"
              icon={true}
              uploadIcon={true}
              handleClick={() => {
                setShowUpload(true);
              }}
              dataCy="upload-video-button"
            />
          </div>
          {showUpload && <UploadPopUpForm handleClose={handleClose} />}
          {/* {videoPreview !== null && (
            <VideoPreview
              videoPreview={videoPreview}
              setVideoPreview={setVideoPreview}
            />
          )} */}
        </div>
        <div className="table-component">
          <div className="table-headings-component">
            <div className="first-child">Video Information</div>
            <div className="second-child">Stats</div>
            <div className="third-child">Visibility Status</div>
            <div className="fourth-child">Controls</div>
          </div>
          <div className="video-items" data-cy="my-dashboard-videos">
            {loading ? (
              <div className="loading-container-styling">
                <Spinner />
              </div>
            ) : (
              <>{videoItems}</>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default MyVideos;
