import React, { useState } from "react";
import ItemCard from "../../components/ItemCard/ItemCard";
import FilterList from "../../components/FilterList/FilterList";
import "./styles.scss";
import ChannelName from "../../components/ChannelName/ChannelName";
import Spinner from "../../components/Spinner/Spinner";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import CrossSign from "../../assets/cross-sign.svg";
import AnimatedDropdown from "../../containers/AnimatedDropdown/AnimatedDropdown";
import CardPreview from "../../components/CardPreview/CardPreview";
import { useNavigate } from "react-router-dom";

const SearchModal = ({
  query,
  queryTerm,
  handleClose,
  loading,
  isAiSearch = false,
  closeAiSearch,
  aiSearchStatus,
  handleAiSearch,
  setShowAiPlaylist,
}) => {
  /*
    Search modal that appears when the user types in the search bar in the navbar.
    query: the search query
    handleClose: function to close the modal

    todo: note, there is still some navigation problem with the search results (when navigation to the second video)
    */

  const [closeDropdown, setCloseDropdown] = useState(false);
  const filterOptions = ["All", "Videos", "Cards", "Playlists", "Creators"];
  const [selectedFilterOptions, setSelectedFilterOptions] = useState(["All"]);
  const navigate = useNavigate();

  const handleClick = (option) => {
    setSelectedFilterOptions([option]);
  };
  return (
    <>
      <AnimatedDropdown
        className="search-modal"
        closeDropdown={closeDropdown}
        closeFunc={handleClose}
      >
        {isAiSearch && (
          <>
            <div className="ai-search-cancel">
              <div className="ai-search-headline">
                <div>AI playlist generation</div>
                <div className="beta">[Beta]</div>
              </div>
              <div>
                <img
                  src={CrossSign}
                  onClick={() => closeAiSearch()}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="ai-section">
              {aiSearchStatus === "not started" && (
                <>
                  <div className="ai-section-message">
                    <div>
                      Create AI-generated playlists based on your prompt
                    </div>
                    <div className="ai-section-message-bottom">
                      Example: Generate a playlist for learning Python
                      fundamentals{" "}
                    </div>
                  </div>
                  <div className="ai-section-right">
                    <ButtonComponent
                      text="Generate"
                      size="small"
                      icon={false}
                      handleClick={() => handleAiSearch()}
                    />
                  </div>
                </>
              )}
              {aiSearchStatus === "generating" && (
                <>
                  <div className="ai-section-message">
                    Generating customized playlist...
                  </div>
                  <div className="ai-section-right">
                    <Spinner size="small" />
                  </div>
                </>
              )}
              {aiSearchStatus === "generated" && (
                <>
                  <div className="ai-section-message">
                    The playlist has been generated successfully!
                  </div>
                  <div className="ai-section-right">
                    <ButtonComponent
                      text="See the playlist"
                      size="small"
                      icon={false}
                      handleClick={() => {
                        setCloseDropdown(true);
                        setShowAiPlaylist(true);
                      }}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
        <div className="search-modal-title">Search Results</div>
        <div className="search-filter-list">
          <FilterList
            categories={filterOptions}
            selectedCategories={selectedFilterOptions}
            handleClick={handleClick}
            dataCy="nav-search-bar-filter"
          />
        </div>
        {loading ? (
          <>{queryTerm !== "" ? <Spinner /> : <></>}</>
        ) : (
          <div className="search-results" data-cy="nav-search-results">
            {query && (
              <>
                {(selectedFilterOptions.includes("Videos") ||
                  selectedFilterOptions.includes("All")) &&
                  query.videos?.map((video) => {
                    return (
                      <ItemCard
                        object={video}
                        handleClose={() => setCloseDropdown(true)}
                        key={video.id}
                      />
                    );
                  })}
                {(selectedFilterOptions.includes("Playlists") ||
                  selectedFilterOptions.includes("All")) &&
                  queryTerm !== "" &&
                  query.playlists?.map((playlist) => {
                    return (
                      <ItemCard
                        object={playlist}
                        handleClose={() => setCloseDropdown(true)}
                        key={playlist.id}
                        type="playlist"
                      />
                    );
                  })}
                {(selectedFilterOptions.includes("Creators") ||
                  selectedFilterOptions.includes("All")) &&
                  query.authors?.map((author) => {
                    return (
                      <div className="creator-line" key={author.id}>
                        <ChannelName
                          author={author}
                          handleClose={() => setCloseDropdown(true)}
                          type="non-item-card"
                        />
                      </div>
                    );
                  })}
                {(selectedFilterOptions.includes("Cards") ||
                  selectedFilterOptions.includes("All")) &&
                  query.cards?.map((card) => {
                    return (
                      <div className="card-line" key={card.id}>
                        <CardPreview
                          card={card}
                          handleClick={() => navigate(`/card/${card.id}`)}
                        />
                      </div>
                    );
                  })}
              </>
            )}
          </div>
        )}
      </AnimatedDropdown>
    </>
  );
};

export default SearchModal;
