import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TabComponent from "../../components/TabComponent/TabComponent.jsx";
import MyPlaylists from "../../components/MyPlaylists/MyPlaylists.jsx";
import MyVideos from "../../components/MyContent/MyVideos.jsx";
import MyCards from "../../components/MyContent/MyCards.jsx";
import Favorites from "../../components/Favorites/Favorites.jsx";
import WatchHistory from "../../components/WatchHistory/WatchHistory.jsx";
import MyNotes from "../../components/MyNotes/MyNotes.jsx";
import { fetchPlaylists } from "../../actions/playlist.js";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";

const Dashboard = () => {
  /*
  Creates a templage for Dashboard page
  Alligned with latest Figma design (May 14)
  */

  const [activeTab, setActiveTab] = useState("Videos");
  const location = useLocation();
  const dispatch = useDispatch();
  const playlists = useSelector((state) => state.playlist.playlists);

  useEffect(() => {
    if (playlists.length === 0) {
      dispatch(fetchPlaylists());
    }
  }, [playlists.length]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get("tab");
    const validTabs = [
      "Videos",
      "Cards",
      "Playlists",
      "WatchHistory",
      "Favorites",
      "Notes",
    ];

    if (tab && validTabs.includes(tab)) {
      setActiveTab(tab);
    } else {
      setActiveTab("Videos");
    }
  }, [location.search]);

  const tabComponents = {
    Videos: <MyVideos />,
    Cards: <MyCards />,
    Playlists: <MyPlaylists />,
    WatchHistory: <WatchHistory />,
    Favorites: <Favorites />,
    Notes: <MyNotes />,
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <div className="dashboard-title">
          <p>Dashboard</p>
        </div>
        <div className="tab-menu">
          <TabComponent tabs={Object.keys(tabComponents)} active={activeTab} />
        </div>
      </div>
      <div className="tab-content">{tabComponents[activeTab]}</div>
    </div>
  );
};

export default Dashboard;
