import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";

const AuthorAvatar = ({
  src = null,
  handleClick,
  author = null,
  isSkeleton,
  redirectToChannelPage = true,
  noAvatar = false,
  className = "",
}) => {
  /*
    Circular avatar display component for the user.
  */

  const userFromState = useSelector((state) => state.auth.user);
  const [user, setUser] = useState(author || userFromState);
  const avatarRef = useRef(null);
  const avatarTextRef = useRef(null);
  const navigate = useNavigate();

  const handleAvatarClick = () => {
    if (!handleClick) {
      if (redirectToChannelPage) {
        navigate(`/channel/${user.username}`);
      }
    } else {
      handleClick();
    }
  };

  useEffect(() => {
    const adjustFontSize = () => {
      if (
        avatarRef &&
        avatarRef?.current &&
        avatarTextRef &&
        avatarTextRef?.current
      ) {
        if (user && user?.avatar) {
          const size = avatarRef.current.offsetWidth;
          avatarTextRef.current.style.width = `${size}px`;
          avatarTextRef.current.style.height = `${size}px`;
          avatarTextRef.current.style.borderRadius = `${size / 2}px`;
        } else {
          const size = avatarRef.current.offsetWidth;
          const fontSize = size / 2.5;
          avatarTextRef.current.style.fontSize = `${fontSize}px`;
        }
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => {
      window.removeEventListener("resize", adjustFontSize);
    };
  }, [user, src]);

  useEffect(() => {
    setUser(author || userFromState);
  }, [author, userFromState]);

  const channelNameDisplay = user?.channel_name
    ?.split(/[ ,]+/, 2)
    .map((word) => word[0]?.toUpperCase());

  if (isSkeleton || !user) {
    return (
      <div
        ref={avatarRef}
        className="avatar-logo"
        style={{ backgroundColor: "transparent" }}
      >
        <Skeleton variant="circular" width="100%" height="100%" />
      </div>
    );
  }

  return (
    <div
      ref={avatarRef}
      className={`avatar-logo ${className}`}
      onClick={handleAvatarClick}
    >
      {user ? (
        (src || user?.avatar) && !noAvatar ? (
          <img src={src || user.avatar} ref={avatarTextRef} />
        ) : (
          <div className="avatar-text" ref={avatarTextRef}>
            {user?.channel_name && user?.channel_name?.length > 0
              ? channelNameDisplay
              : user?.first_name[0] + user?.last_name[0]}
          </div>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default AuthorAvatar;
