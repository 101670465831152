import { useEffect, useRef } from "react";

export function useDebounce(cb, delay) {
  const timerRef = useRef(null); // Use a ref to store the timer ID

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current); // Clear any pending timeout on unmount
      }
    };
  }, []); // Cleanup only on unmount

  // Return a debounced version of the callback
  return (...args) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current); // Clear the previous timer
    }
    timerRef.current = setTimeout(() => {
      cb(...args); // Execute the callback after the delay
    }, delay);
  };
}
