import AuthorAvatar from "../AuthorAvatar/AuthorAvatar";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import React from "react";
import { Skeleton } from "@mui/material";
import {
  formatTimeAgo,
  parseViews,
  formatSimpleDate,
} from "../../utils/video.js";

const ChannelName = ({
  author,
  handleClose = () => {},
  isSkeleton = false,
  redirectToChannelPage = true,
  object,
  type,
  handleClick: customHandleClick,
}) => {
  /*
    Creates author's icon with the name of the author
    Alligned with latest Figma design (May 14) 
    */
  const navigate = useNavigate();

  const handleClick = () => {
    if (customHandleClick) {
      customHandleClick();
    } else if (redirectToChannelPage) {
      navigate(`/channel/${author.username}`);
      handleClose();
    }
  };

  const renderViewsAndDate = (type, object) => {
    if (type === "video" || type === "playlist") {
      return (
        <>
          <p className="video-views">
            {parseViews(object.views)} view{object.views !== 1 ? "s" : ""}
          </p>
          <p>
            {formatTimeAgo(type === "video" ? object.date : object.created_at)}
          </p>
        </>
      );
    } else if (object?.date) {
      return (
        <div className="upload-date">
          Upload Date:{" "}
          {formatSimpleDate(object.date, {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {author && !isSkeleton ? (
        <div className={`channel ${type}`} onClick={handleClick}>
          <div className={`channel-logo ${type}`}>
            <AuthorAvatar
              author={author}
              redirectToChannelPage={redirectToChannelPage}
            />
          </div>
          <div className="channel-right">
            <div className={`channel-first-last-name ${type}`}>
              {author.channel_name?.length > 0
                ? author.channel_name
                : author.first_name + " " + author.last_name}
            </div>
            <div className="views-and-date">
              {renderViewsAndDate(type, object)}
            </div>
          </div>
        </div>
      ) : null}
      {isSkeleton && (
        <div className="channel">
          <div className="channel-logo">
            <AuthorAvatar author={author} isSkeleton={isSkeleton} />
          </div>
          <div className="channel-right">
            <div className="channel-first-last-name">
              <Skeleton variant="text" width="60%" />
            </div>
            <div className="views-and-date">
              <Skeleton variant="text" width="30%" />
              <Skeleton variant="text" width="30%" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChannelName;
