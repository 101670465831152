import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWatchHistory } from "../../actions/data.js";
import { isToday, isThisWeek, isThisMonth } from "date-fns";
import ListVideoComponent from "../ListVideoComponent/ListVideoComponent.jsx";
import Spinner from "../Spinner/Spinner.jsx";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";

const WatchHistory = () => {
  /*
    Subpage in Dashboard > Watch History tab, where a user can see a list of all watched videos.
  */

  const dispatch = useDispatch();
  const watchHistory = useSelector((state) => {
    const history = state.data.watchHistory;
    const processEntries = (entries) => {
      return entries.map((entry) => {
        if (entry.playlist) {
          const videoData = entry.playlist;
          videoData.type = "playlist";
          videoData.current_video = entry.video.id;
          videoData.card_type = entry.video.card_type;
          return videoData;
        }
        const videoData = entry.video;
        videoData.type = "video";
        return videoData;
      });
    };

    return {
      today: processEntries(
        history.filter((item) => isToday(new Date(item.timestamp))),
      ),
      thisWeek: processEntries(
        history.filter(
          (item) =>
            isThisWeek(new Date(item.timestamp), { weekStartsOn: 1 }) &&
            !isToday(new Date(item.timestamp)),
        ),
      ),
      pastMonth: processEntries(
        history.filter(
          (item) =>
            isThisMonth(new Date(item.timestamp)) &&
            !isThisWeek(new Date(item.timestamp), { weekStartsOn: 1 }),
        ),
      ),
    };
  });
  const [loading, setLoading] = useState(
    watchHistory.today.length === 0 &&
      watchHistory.thisWeek.length === 0 &&
      watchHistory.pastMonth.length === 0,
  );
  const updateWatchHistory = useSelector(
    (state) => state.data.updateWatchHistory,
  );

  const fetchWatchHistory = async () => {
    try {
      const response = await userService.getWatchHistory();
      dispatch(setWatchHistory(response));
    } catch (error) {
      console.error("Error fetching user watch history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (updateWatchHistory) {
      fetchWatchHistory();
    } else setLoading(false);
  }, [dispatch, updateWatchHistory, loading]);

  return (
    <div className="watch-history">
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 100px)",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div>
            {watchHistory.today.length ? (
              <ListVideoComponent
                items={watchHistory.today}
                heading="Today"
                type="mixed"
                key_prefix="watch-history-today-"
              />
            ) : null}
            {watchHistory.thisWeek.length ? (
              <ListVideoComponent
                items={watchHistory.thisWeek}
                heading="This week"
                type="mixed"
                key_prefix="watch-history-week-"
              />
            ) : null}
            {watchHistory.pastMonth.length ? (
              <ListVideoComponent
                items={watchHistory.pastMonth}
                heading="Past month"
                type="mixed"
                key_prefix="watch-history-month-"
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default WatchHistory;
