import React, { useState, useEffect, useRef } from "react";
import ReportOverlay from "../ReportOverlay/ReportOverlay";
import { useDispatch, useSelector } from "react-redux";
import { showSignup } from "../../actions/global.action";
import AnimatedDropdown from "../../containers/AnimatedDropdown/AnimatedDropdown";
import threeDotsBlack from "../../assets/three-dots-black.svg";
import threeDotsWhite from "../../assets/three-dots-white.svg";
import "./styles.scss";
import reportIcon from "../../assets/report.svg";

const MoreDropdown = ({ videoId, horizontal = false }) => {
  /*
  one of the components of toolbar comonnet; ideally it should be incorporated into the toolbar component
  that will have all the icons 
  corresponds to Figma design (May 15)
  */
  const [showDropdown, setShowDropdown] = useState(false);
  const [closeDropdown, setCloseDropdown] = useState(false);
  const [showReportOverlay, setShowReportOverlay] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({});
  const buttonRef = useRef(null);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    const handleResize = () => {
      if (showDropdown) {
        adjustDropdownPosition();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [showDropdown]);

  const adjustDropdownPosition = () => {
    if (buttonRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const dropdownWidth = 240;
      const rightSpace =
        window.innerWidth -
        (buttonRect.left + buttonRect.width / 2 + dropdownWidth / 2);
      if (rightSpace < 120) {
        setDropdownPosition({
          right: "10px",
          left: "auto",
        });
      } else {
        setDropdownPosition({
          left: `0`,
          right: "auto",
        });
      }
    }
  };

  const toggleDropdown = () => {
    if (!isAuthenticated) {
      dispatch(showSignup(true));
      return;
    }
    if (showDropdown) {
      setCloseDropdown(true);
    } else {
      setShowDropdown(true);
    }
    adjustDropdownPosition();
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <div className="label-option">
        <button
          ref={buttonRef}
          className={`sidebar-btn ${showDropdown && "btn-selected"}`}
          onClick={toggleDropdown}
        >
          <img
            src={showDropdown ? threeDotsWhite : threeDotsBlack}
            alt="three-dots"
          />
        </button>
        <p className="sidebar-text">More</p>
      </div>
      {showDropdown && (
        <AnimatedDropdown
          className="more-dropdown"
          style={dropdownPosition}
          closeDropdown={closeDropdown}
          closeFunc={() => {
            setShowDropdown(false);
            setCloseDropdown(false);
          }}
        >
          <ul className="more-list">
            <li
              className="more-list-item"
              onClick={() => {
                setCloseDropdown(true);
                setShowReportOverlay(!showReportOverlay);
              }}
            >
              <div className="more-list-left">
                <img src={reportIcon} alt="three-dots" />
                <span>Report</span>
              </div>
            </li>
          </ul>
        </AnimatedDropdown>
      )}
      {showReportOverlay && (
        <ReportOverlay
          setShowReportOverlay={setShowReportOverlay}
          videoId={videoId}
        />
      )}
    </div>
  );
};

export default MoreDropdown;
