interface ErrorResponse {
  users: never[];
  total: number;
  pages: number;
  current_page: number;
  error: string;
}

export const handleAdminDashboardError = (error: any): ErrorResponse => {
  // Network timeout error
  if (error.code === "ECONNABORTED") {
    console.error("[AdminDashboard] Request timeout:", error);
    return {
      users: [],
      total: 0,
      pages: 0,
      current_page: 1,
      error: "Request timed out. The server might be under heavy load.",
    };
  }

  // Network connectivity error
  if (error.code === "ERR_NETWORK") {
    console.error("[AdminDashboard] Network error:", error);
    return {
      users: [],
      total: 0,
      pages: 0,
      current_page: 1,
      error: "Network error. Please check your connection.",
    };
  }

  // Service unavailable
  if (error.response?.status === 503) {
    console.error("[AdminDashboard] Service unavailable:", error);
    return {
      users: [],
      total: 0,
      pages: 0,
      current_page: 1,
      error: "The service is temporarily unavailable. Please try again later.",
    };
  }

  // Generic server error
  if (error.response?.status === 500) {
    console.error("[AdminDashboard] Server error:", error);
    return {
      users: [],
      total: 0,
      pages: 0,
      current_page: 1,
      error: "An unexpected error occurred. Please try again later.",
    };
  }

  // Default error
  console.error("[AdminDashboard] Unhandled error:", error);
  throw error;
};
