import React, { useState } from "react";
import AddVideoNew from "../../assets/video-recorder.svg";
import AddAssessment from "../../assets/phone-card.svg";
import CreatorHelp from "../../assets/help-circle.svg";
import AnimatedDropdown from "../../containers/AnimatedDropdown/AnimatedDropdown.jsx";
import "./styles.scss";

const DropdownAddOptions = ({
  handleClose,
  setShowUpload,
  setShowUploadAssCard,
}) => {
  /*
    Creates a dropdown menu for the landing page for the upload video/ass card
    */
  const [closeDropdown, setCloseDropdown] = useState(false);

  const list = [
    {
      label: "Upload a Video",
      icon: AddVideoNew,
      function: () => setShowUpload(),
      dataCy: "upload-video-dropdown-button",
    },
    {
      label: "Create a Card",
      icon: AddAssessment,
      function: () => setShowUploadAssCard(),
      dataCy: "create-card-dropdown-button",
    },
    {
      label: "Creator Help Page",
      icon: CreatorHelp,
      function: () => {},
      dataCy: "create-help-page-dropdown-button",
    },
  ];

  const toFirstLetterUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <AnimatedDropdown
      className="dropdown-my-account"
      closeDropdown={closeDropdown}
      closeFunc={handleClose}
    >
      <div>
        <ul className="dropdown-list">
          {list.map((option) => (
            <>
              {option.label === "Creator Help Page" ? (
                <div className="divider"></div>
              ) : null}
              <li
                key={option.label}
                onClick={(e) => {
                  e.stopPropagation();
                  option.function();
                  setCloseDropdown(true);
                }}
                className="dropdown-list-item"
                data-cy={option.dataCy}
              >
                <div className="dropdown-list-item-left">
                  <div className="dropdown-list-option-icon">
                    <img className="dropdown-icon" src={option.icon} />
                  </div>
                  <div className="dropdown-list-option-name">
                    {toFirstLetterUpperCase(option.label)}
                  </div>
                </div>
              </li>
            </>
          ))}
        </ul>
      </div>
    </AnimatedDropdown>
  );
};

export default DropdownAddOptions;
