import {
  NOTIFY_SUCCESS,
  NOTIFY_ERROR,
  NOTIFY_INFO,
  NOTIFY_WARNING,
  CLEAR_TOAST_MESSAGE,
  SHOW_SIGNUP,
  HIDE_SIGNUP,
  HIDE_LOGIN,
  SHOW_LOGIN,
  SHOW_MODAL,
  HIDE_MODAL,
  SHOW_SUBSCRIPTION,
  HIDE_SUBSCRIPTION,
} from "./types";

export const notifySuccess = (message) => ({
  type: NOTIFY_SUCCESS,
  payload: message,
});

export const notifyError = (message) => ({
  type: NOTIFY_ERROR,
  payload: message,
});

export const notifyWarning = (message) => ({
  type: NOTIFY_WARNING,
  payload: message,
});

export const notifyInfo = (message) => ({
  type: NOTIFY_INFO,
  payload: message,
});

export const clearToastMessage = () => ({
  type: CLEAR_TOAST_MESSAGE,
});

export const showLogin = () => ({
  type: SHOW_LOGIN,
});

export const showModal = (type, videoPreview = "") => ({
  type: SHOW_MODAL,
  payload: { type, videoPreview },
});

export const hideModal = (type) => ({
  type: HIDE_MODAL,
  payload: type,
});

export const hideLogin = () => ({
  type: HIDE_LOGIN,
});

export const showSignup = () => ({
  type: SHOW_SIGNUP,
});

export const hideSignup = () => ({
  type: HIDE_SIGNUP,
});

export const showSubscription = () => ({
  type: SHOW_SUBSCRIPTION,
});

export const hideSubscription = () => ({
  type: HIDE_SUBSCRIPTION,
});
