import eye from "../../assets/eye.svg";
import eyeSlash from "../../assets/eye-slash.svg";
import crossSign from "../../assets/cross-sign.svg";
import googleLogo from "../../assets/google-logo.svg";
import githubLogo from "../../assets/github-logo.svg";
import React, { useEffect, useState, useRef } from "react";
import authService from "../../services/auth.service.ts";
import { SIGNUP_FAIL, SIGNUP_SUCCESS } from "../../actions/types.js";
import {
  hideSignup,
  notifyError,
  notifySuccess,
  showLogin,
} from "../../actions/global.action.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
// import { months } from "../../utils/constants.js";
import { githubAuthenticate, googleAuthenticate } from "../../actions/auth.js";
import Popup from "../../containers/Popup/Popup.jsx";
import useOutsideClick from "../../hooks/useOutsideClick.js";
import TooltipComponent from "../TooltipComponent/TooltipComponent.jsx";

const SignupForm = () => {
  /*
  Signup form component, handles user registration.
   */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [accountCreated, setAccountCreated] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [username, setUsername] = useState("");
  const location = useLocation();
  const signUpRef = useRef(null);
  const lines = [
    "At least 8 characters in length.",
    "Contains at least 3 of the following:",
    "- Lower case letters (a-z)",
    "- Upper case letters (A-Z)",
    "- Numbers (1-9)",
    "- Special characters (!@#$%^&*)",
  ];
  const tipText = lines.join("\n");
  // const [validDays, setValidDays] = useState([...Array(31).keys()]);
  // const [selectedMonth, setSelectedMonth] = useState(0);
  // const [selectedYear, setSelectedYear] = useState(0);

  const handleClose = () => {
    dispatch(hideSignup());
    if (location.pathname.includes("/signup")) {
      navigate("/");
    }
  };

  useOutsideClick(signUpRef, () => {
    setClosePopup(true);
  });

  const requestRegister = async (data) => {
    try {
      const response = await authService.register(data);
      if (response) {
        setAccountCreated(true);
        dispatch(notifySuccess("Register successfully"));
        dispatch({ type: SIGNUP_SUCCESS, payload: response });
      }
    } catch (e) {
      let errorMessage = "e.message";
      if (e.request.response) {
        const error = JSON.parse(e.request.response);
        errorMessage = error.detail;
      }
      dispatch({ type: SIGNUP_FAIL });
      dispatch(notifyError(errorMessage));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      first_name: e.currentTarget.firstName.value,
      last_name: e.currentTarget.lastName.value,
      // birth_date: `${e.currentTarget.year.value}-${e.currentTarget.month.value}-${e.currentTarget.day.value}`,
      email: e.currentTarget.email.value.toLowerCase(),
      username: e.currentTarget.username.value,
      password: e.currentTarget.password.value,
      re_password: e.currentTarget.passwordConfirm.value,
      communication_preference:
        e.currentTarget.communication_preference.checked,
    };

    if (formData.password === formData.re_password) {
      requestRegister(formData);
    } else {
      dispatch(notifyError("Password does not match"));
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      setClosePopup(true);
      navigate("/");
    }
    if (accountCreated) {
      setClosePopup(true);
      dispatch(showLogin());
      window.history.replaceState(
        {},
        document.title,
        `${window.location.pathname}?source=success_signup`,
      );
    }
  }, [isAuthenticated, accountCreated]);

  // useEffect(() => {
  //   const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
  //   setValidDays([...Array(daysInMonth).keys()]);
  // }, [selectedMonth, selectedYear]);

  useEffect(() => {
    document.body.classList.add("modal-open");
    return () => {
      document.body.classList.remove("modal-open");
    };
  }, []);

  return (
    <Popup
      className="auth-container"
      closePopup={closePopup}
      closeFunc={() => handleClose()}
      withContainer={true}
    >
      <div className="auth-form popup" ref={signUpRef}>
        <div className="auth-header">
          <span className="auth-title">Sign up for Edgur</span>
          <button
            className="close-button"
            onClick={() => {
              setClosePopup(true);
            }}
          >
            <img src={crossSign} alt="close-button" />
          </button>
        </div>
        <form className="auth-content" onSubmit={handleSubmit}>
          <div className="row-group">
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <div className="form-input-group">
                <input
                  required
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="form-input"
                  placeholder="Enter your first name"
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <div className="form-input-group">
                <input
                  required
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="form-input"
                  placeholder="Enter your last name"
                />
              </div>
            </div>
          </div>
          {/* <div className="row-group">
            <div className="form-group">
              <label htmlFor="month">Birthday</label>
              <div className="form-input-group">
                <select
                  name="month"
                  id="month"
                  className="form-input"
                  required
                  onChange={(e) => setSelectedMonth(e.target.value)}
                >
                  <option value="" disabled selected>
                    Month
                  </option>
                  {months.map((month, index) => (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="form-input-group">
                <select name="day" id="day" className="form-input" required>
                  <option value="" disabled selected>
                    Day
                  </option>
                  {validDays.map((day) => (
                    <option key={day} value={day + 1}>
                      {day + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group">
              <div className="form-input-group">
                <select
                  name="year"
                  id="year"
                  className="form-input"
                  required
                  onChange={(e) => setSelectedYear(e.target.value)}
                >
                  <option value="" disabled selected>
                    Year
                  </option>
                  {[...Array(100).keys()].map((year) => (
                    <option key={year} value={new Date().getFullYear() - year}>
                      {new Date().getFullYear() - year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div> */}
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <div className="form-input-group">
              <input
                required
                type="email"
                name="email"
                id="email"
                className="form-input"
                placeholder="Enter your email"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <div className="form-input-group">
              <input
                required
                type="text"
                name="username"
                id="username"
                className="form-input"
                placeholder="Enter a unique username"
                value={username}
                // Allow only alphanumeric characters and underscore and a length of 1-15 characters
                onInput={(e) => {
                  setUsername(
                    e.target.value
                      .slice(0, 15)
                      .toLowerCase()
                      .replace(/[^a-z0-9_]/g, ""),
                  );
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="password">
              Password
              <TooltipComponent tipText={tipText} longText={true} />
            </label>
            <div className="form-input-group">
              <input
                required
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                className="form-input"
                placeholder="Enter your password"
              />
              <button
                type="button"
                className="toggle-pass"
                onClick={() => setShowPassword(!showPassword)}
              >
                <img src={showPassword ? eyeSlash : eye} alt="show-password" />
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="password">Confirm Password</label>
            <div className="form-input-group">
              <input
                required
                type={showPasswordConfirm ? "text" : "password"}
                name="passwordConfirm"
                id="passwordConfirm"
                className="form-input"
                placeholder="Confirm your password"
              />
              <button
                type="button"
                className="toggle-pass"
                onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
              >
                <img
                  src={showPasswordConfirm ? eyeSlash : eye}
                  alt="show-password"
                />
              </button>
            </div>
          </div>
          <div className="policy-agreement-container">
            <div className="policy-agreement">
              <input required type="checkbox" id="years-old" />
              <div className="policy-label-container">
                <label className="policy-label star" htmlFor="years-old">
                  I confirm that I am at least 18 years old
                </label>
              </div>
            </div>
            <div className="policy-agreement">
              <input required type="checkbox" id="privacy-policy" />
              <div className="policy-label-container">
                <label className="policy-label star" htmlFor="privacy-policy">
                  I confirm that I have read the{" "}
                  <a
                    href="/pages/privacy"
                    className="upload-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="/pages/terms"
                    className="upload-terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </a>
                </label>
              </div>
            </div>
            <div className="policy-agreement">
              <input
                type="checkbox"
                id="communication_preference"
                defaultChecked
              />
              <div className="policy-label-container">
                <label
                  className="policy-label"
                  htmlFor="communication_preference"
                >
                  I want to receive updates and news from Edgur. You can
                  unsubscribe later.
                </label>
              </div>
            </div>
          </div>
          <button type="submit" className="submit-button">
            Sign Up
          </button>
        </form>
        <div className="or-divider">
          <span className="divider-line"></span>
          <span className="divider-text">or</span>
          <span className="divider-line"></span>
        </div>
        <div className="other-options">
          <button
            className="other-option"
            onClick={() => dispatch(googleAuthenticate())}
          >
            <img src={googleLogo} alt="google-logo" />
            <p>Sign up with Google</p>
          </button>
          <button
            className="other-option"
            onClick={() => dispatch(githubAuthenticate())}
          >
            <img src={githubLogo} alt="github-logo" />
            <p>Sign up with GitHub</p>
          </button>
        </div>
        <span className="auth-footer">
          Already have an account?{" "}
          <a
            onClick={() => {
              setClosePopup(true);
              dispatch(showLogin());
              if (location.pathname.includes("/signup")) {
                navigate("/login");
                window.location.reload();
              }
            }}
          >
            Log in
          </a>
        </span>
      </div>
    </Popup>
  );
};

export default SignupForm;
