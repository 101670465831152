import React from "react";
import { useDispatch } from "react-redux";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import sendIcon from "../../assets/send.svg";
import { notifyError } from "../../actions/global.action";

const Newsletter = ({
  email,
  setEmail,
  isSubscribed,
  setIsSubscribed,
  handleSubscribe,
}) => {
  const dispatch = useDispatch();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubscribeClick = () => {
    if (!validateEmail(email)) {
      dispatch(notifyError("Please enter a valid email address"));
      return;
    }

    handleSubscribe();
  };

  return (
    <div className="share-container">
      <div className="share-icon">
        <img src={sendIcon} alt="Telegram" />
      </div>
      <div className="share-text">
        <div className="share-title">Join Our Journey!</div>
        <div className="share-description">
          Be the first to get updates and learn about upcoming features.
        </div>
      </div>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
          setIsSubscribed(false);
        }}
      />
      <div className="privacy-policy">
        By subscribing, I agree to Edgur's{" "}
        <a href="/pages/terms">Terms and Conditions</a> and{" "}
        <a href="/pages/privacy">Privacy Policy</a>
      </div>
      <ButtonComponent
        text={isSubscribed ? "Subscribed" : "Subscribe"}
        icon={false}
        coverFullWidth={true}
        handleClick={handleSubscribeClick}
        level={isSubscribed || !email ? "disable" : "primary"}
      />
    </div>
  );
};

export default Newsletter;
