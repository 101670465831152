import assessmentThumb from "../assets/multiple-choice-thumb.svg";
import flashcardThumb from "../assets/flashcard-thumb.svg";
import knowledgeThumb from "../assets/knowledge-thumb.svg";

//Percentage of video that should be watched to be count as a view (send a request)
export const VIEW_VIDEO_PERCENTAGE = 0.1;

//Months list (previously used for sign up form's birthday field)
export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//Boolean to determine whether bottom footer (Footer.js) that suggest sign in/up is displayed
export const showFooter = false;

//Number of videos displayed at once on the feed
export const maxVideos = 20;

//Number of videos fetched from backend (for feed batch)
export const videoBatch = 5;

//Cards types displayed on the frontend and stored in the backend
export const cardTypeDict = {
  "Multiple Choice": "assessment",
  Informational: "knowledge",
  Flashcard: "flashcard",
  assessment: "Multiple Choice",
  knowledge: "Informational",
  flashcard: "Flashcard",
};

export const MAX_NEST_LVL = 4;

//Mapping of card types to their icons and text
export const cardMapping = {
  assessment: {
    icon: assessmentThumb,
    text: "Multiple Choice",
  },
  knowledge: {
    icon: knowledgeThumb,
    text: "Informational",
  },
  flashcard: {
    icon: flashcardThumb,
    text: "Flashcard",
  },
};

export const cardColors = [
  "#3B3B3B",
  "#AD343E",
  "#625834",
  "#FFA500",
  "#58A5F8",
];

export const FILE_SIZE_LIMIT = 5 * 1024 * 1024;
export const ASPECT_RATIO = 16 / 9;
export const MIN_DIMENSION = 150;
export const TIME_DELAY_NAVIGATE_TO_NEXT_ITEM = 0;
export const MOBILE_WIDTH = 750;
export const PAGINATION_SIZE = 20;
