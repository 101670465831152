import React, { useState, useEffect, useRef } from "react";
import { renderContent } from "../../utils/editor.jsx";
import TagsUpdated from "../TagsUpdated/TagsUpdated.jsx";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";

const DescriptionTag = ({
  video,
  pressSeeMore = null,
  rowsDefault = 2,
  selectedOption = null,
  isDetailMode = false,
  type,
  isInline = false,
  explanationToShow = "",
}) => {
  const [showMore, setShowMore] = useState(
    selectedOption === "expanded" ? false : null
  );
  const [tagsLength, setTagsLength] = useState(null);
  const descriptionId = uuidv4();
  const [needsToBeExpanded, setNeedsToBeExpanded] = useState(null);
  const containerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState("auto");

  // 1) Make sure we only return valid React nodes (no objects).
  const formatDescription = (description) => {
    if (!description) {
      // Return a simple <span> instead of an object with props
      return [<span key="empty-desc" />];
    }
    try {
      const jsonDesc = JSON.parse(description);
      return renderContent(jsonDesc);
    } catch (err) {
      // Return a single <span> with the fallback text
      return [<span key="fallback-desc">{String(description || "")}</span>];
    }
  };

  const descriptionToShow = formatDescription(video?.description);

  useEffect(() => {
    if (tagsLength && tagsLength >= 2) {
      setNeedsToBeExpanded(true);
    }
  }, [tagsLength]);

  useEffect(() => {
    const element = document.getElementById(descriptionId);
    if (element && video?.description) {
      const needExpansion = element.scrollHeight > 16 + rowsDefault * 20;
      setNeedsToBeExpanded(needExpansion ? true : null);
      
      // Set initial height based on whether content is expanded or collapsed
      if (containerRef.current) {
        const newHeight = showMore ? 
          `${rowsDefault * 20 + 16}px` : 
          `${element.scrollHeight}px`;
        setContainerHeight(newHeight);
      }
    }
  }, [video?.description, rowsDefault, showMore]);

  const toggleShowMore = () => {
    // Get full height before toggling
    const element = document.getElementById(descriptionId);
    if (!element) return;
    
    const fullHeight = element.scrollHeight;
    const collapsedHeight = rowsDefault * 20 + 16;
    
    // Set height first, then toggle the state
    setContainerHeight(showMore ? `${fullHeight}px` : `${collapsedHeight}px`);
    
    if (!pressSeeMore) {
      setShowMore(!showMore);
    } else {
      pressSeeMore();
    }
  };

  // 2) Ensure everything inside .map() has a key
  const renderWithKeys = (content) => {
    if (!content) return null;
    if (!Array.isArray(content)) return content; // single element or string

    return content.map((item, idx) => {
      if (React.isValidElement(item)) {
        return React.cloneElement(item, {
          key: `desc-item-${idx}-${descriptionId.substring(0, 8)}`,
        });
      }
      return (
        <span key={`desc-span-${idx}-${descriptionId.substring(0, 8)}`}>
          {item}
        </span>
      );
    });
  };

  // 3) If we're in "card" mode, safely string-ify explanation or description
  if (type === "card") {
    // Convert potential objects to strings to avoid "object with keys { props }" errors
    const safeExplanation =
      explanationToShow && typeof explanationToShow === "object"
        ? JSON.stringify(explanationToShow)
        : explanationToShow;

    const safeDescription =
      video?.description && typeof video.description === "object"
        ? JSON.stringify(video.description)
        : video?.description || "";

    return (
      <div className="description-tag">
        <div className="description">
          <div className="description-content">
            {safeExplanation ? (
              <div className="explanation-container">
                <span className="explanation-title">Explanation: </span>
                {safeExplanation}
              </div>
            ) : (
              safeDescription
            )}
          </div>
        </div>
      </div>
    );
  }

  // Default (non-card) case:
  return (
    <div className="description-tag">
      <div className="description">
        {descriptionToShow[0] &&
          descriptionToShow[0].props &&
          descriptionToShow[0].props.children !== undefined && (
            <div
              ref={containerRef}
              id={descriptionId}
              className={`description-content${
                showMore ? " clipped" : ""
              }${rowsDefault === 1 && showMore !== null ? " one-row" : ""}`}
              style={{
                WebkitLineClamp: showMore ? rowsDefault : "unset",
                height: containerHeight,
                transition: "height 0.3s ease-in-out"
              }}
            >
              <div
                className={` ${
                  rowsDefault === 1 && showMore !== null
                    ? "description-text one-row"
                    : ""
                }`}
              >
                {renderWithKeys(descriptionToShow)}
              </div>
              {isInline && showMore !== null && type !== "video-preview" && (
                <div
                  className="description-see-more"
                  onClick={() => {
                    pressSeeMore();
                  }}
                >
                  see more
                </div>
              )}
            </div>
          )}

        {/* Tags */}
        <div className="description-tag-tags">
          <TagsUpdated
            tags={video?.tags?.map((tag) => tag.name) || []}
            filterTags={!isDetailMode || showMore}
            setTagsLength={setTagsLength}
          />
        </div>

        {/* See more/less for normal (non-inline) mode */}
        {!isInline &&
          (showMore !== null || (isDetailMode && needsToBeExpanded)) && (
            <div
              className="see-more-less"
              onClick={toggleShowMore}
            >
              {showMore === true ? "...see more" : "...see less"}
            </div>
          )}
      </div>
    </div>
  );
};

export default DescriptionTag;
