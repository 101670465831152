import React from "react";
import { parseViews } from "../../../utils/video";
import TooltipComponent from "../../TooltipComponent/TooltipComponent";
import AuthorAvatar from "../../AuthorAvatar/AuthorAvatar";
import trashIcon from "../../../assets/trash.svg";
import { truncate } from "lodash";
import "./styles.scss";

const AdminVideoCard = ({ video, isSelected, onSelect, onDelete }) => {
  const notesCount = Array.isArray(video.notes) ? video.notes.length : 0;
  const visibilityValue = video.visibility ? video.visibility : "unknown";
  const visibilityText = visibilityValue.toLowerCase();

  // Get the first category if available, with better fallback handling
  const firstCategory =
    video.categories &&
    Array.isArray(video.categories) &&
    video.categories.length > 0
      ? video.categories[0]
      : null;
  const categoryName =
    firstCategory && firstCategory.name ? firstCategory.name : "Uncategorized";
  const categorySlug = categoryName.toLowerCase().replace(/\s+/g, "-");

  return (
    <tr className={isSelected ? "selected admin-video-row" : "admin-video-row"}>
      {/* 1) Checkbox */}
      <td className="checkbox-column">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelect(video.id)}
        />
      </td>

      {/* 2) Video Info Column */}
      <td className="video-info-column">
        <div className="video-info-wrapper">
          <a
            href={`/video/${video.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="thumbnail-link"
          >
            <img
              src={video.thumbnail_url}
              className="admin-video-thumbnail"
              alt={video.title}
            />
          </a>
          <div className="text-info">
            <div className="video-title" title={video.title}>
              <a
                href={`/video/${video.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="title-link"
              >
                {truncate(video.title, { length: 14, omission: "..." })}
              </a>
            </div>
          </div>
        </div>
      </td>

      {/* 3) Channel Column */}
      <td className="channel-info-column">
        <div className="channel-info-wrapper">
          <AuthorAvatar
            author={video.author}
            noAvatar={!video.author?.avatar_url}
            redirectToChannelPage={false}
            className="admin-video-avatar"
          />
          <span className="channel-name">
            {video.author?.first_name} {video.author?.last_name}
          </span>
        </div>
      </td>

      {/* 4) Category Column - NEW */}
      <td className="category-column">
        <span className={`category-badge ${categorySlug}`}>{categoryName}</span>
      </td>

      {/* 5) Upload Date */}
      <td className="date-cell">
        <span className="admin-video-date">
          {new Date(video.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </span>
      </td>

      {/* 6) Views */}
      <td className="numeric-cell">{parseViews(video.views)}</td>

      {/* 7) Likes */}
      <td className="numeric-cell">{parseViews(video.likes_count)}</td>

      {/* 8) Playlists */}
      <td className="numeric-cell">{parseViews(video.playlists_count)}</td>

      {/* 9) Notes */}
      <td className="numeric-cell">{video.notes_count}</td>

      {/* 10) Visibility */}
      <td className="visibility-column">
        <span className={`status-badge ${visibilityText}`}>
          {video.visibility || "Unknown"}
        </span>
      </td>

      {/* 11) Actions */}
      <td className="actions-column">
        <button className="control-button" onClick={() => onDelete(video.id)}>
          <TooltipComponent tipText="Delete" icon={trashIcon} />
        </button>
      </td>
    </tr>
  );
};

export default AdminVideoCard;
