import React, { useState, useEffect } from "react";
import DropdownMenuPrivacy from "../DropdownMenuPrivacy/DropdownMenuPrivacy";
import TextInputComponent from "../TextInputComponent/TextInputComponent";
import TextAreaComponent from "../TextAreaComponent/TextAreaComponent";
import "./styles.scss";
import RichTextEditorComponent from "../RichTextEditor/RichTextEditorComponent";
import MultipleComponent from "../MultipleComponent/MultipleComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { cardService } from "../../services/card.service";
import { notifyError } from "../../actions/global.action";
import { useDispatch } from "react-redux";
import { isRichTextEmpty } from "../../utils/editor";
import ToggleComponent from "../ToggleComponent/ToggleComponent";
const ANSWER_LIMIT = 40;

const AssCard = ({
  cardInfo,
  setCardInfo,
  showCodeComponent,
  showImageComponent,
  handleDrop,
  fileInputRef,
  fileBackInputRef,
  handleChange,
  isMobile = false,
  isSmallerMobile = false,
  hasGenerateButton = true,
}) => {
  const dispatch = useDispatch();
  const [tags, setTags] = useState(cardInfo?.tags?.map((tag) => tag.name));
  const [categories, setCategories] = useState(
    cardInfo?.categories?.map((category) => category.name),
  );
  const [disabledGenerate, setDisabledGenerate] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const handleInputLearningObjective = (categories) => {
    setCategories(categories);
    setCardInfo((prevCardInfo) => ({
      ...prevCardInfo,
      categories: categories,
    }));
  };

  const handleTagChange = (tags) => {
    setTags(tags);
    setCardInfo((prevCardInfo) => ({
      ...prevCardInfo,
      tags: tags,
    }));
  };

  useEffect(() => {
    setDisableButton(!cardInfo.title || isRichTextEmpty(cardInfo.description));
  }, [cardInfo.title, cardInfo.description]);

  const handleGenerate = () => {
    setDisabledGenerate(true);
    cardService
      .generateCardTagsAndCategories(cardInfo)
      .then((data) => {
        const { tags: generatedTags, categories: generatedCategories } = data;

        if (!generatedCategories || generatedCategories.length === 0) {
          dispatch(
            notifyError(
              "The input information was insufficient to generate categories and tags for your card.",
            ),
          );
          return;
        }

        if (!generatedTags || generatedTags.length === 0) {
          dispatch(
            notifyError(
              "The input information was insufficient to generate categories and tags for your card.",
            ),
          );
          return;
        }

        const filteredTags = generatedTags
          .split(",")
          .map((tag) => tag.trim())
          .filter((tag) => {
            const lowerCaseTag = tag.toLowerCase();
            return lowerCaseTag !== "paragraph" && lowerCaseTag !== "doc";
          });

        const parsedCategories = Array.isArray(generatedCategories)
          ? generatedCategories
          : generatedCategories.split(",").map((category) => category.trim());

        setTags(
          Array.isArray(filteredTags)
            ? [...cardInfo.tags, ...filteredTags]
            : [...cardInfo.tags],
        );
        setCategories(
          Array.isArray(parsedCategories)
            ? [...cardInfo.categories, ...parsedCategories]
            : [...cardInfo.categories],
        );

        setCardInfo((prevCardInfo) => ({
          ...prevCardInfo,
          tags: Array.isArray(filteredTags)
            ? [...cardInfo.tags, ...filteredTags]
            : [...cardInfo.tags],
          categories: Array.isArray(parsedCategories)
            ? [...cardInfo.categories, ...parsedCategories]
            : [...cardInfo.categories],
        }));
      })
      .catch((error) => {
        console.error("Error generating tags and categories:", error);
      })
      .finally(() => {
        setDisabledGenerate(true);
      });
  };

  return (
    <div className="asscard-container">
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left"></div>
        <div className="card-row-right">
          <div className={`dropdowns ${isSmallerMobile ? "vertical" : ""}`}>
            <div className="dropdown-wrapper">
              <div className="headline">Card Type</div>
              <div className="dropdown-card">
                <DropdownMenuPrivacy
                  category="cards"
                  currentVisibility={cardInfo.card_type}
                  handleVisibilityChange={(newCardType) => {
                    setCardInfo((prevCardInfo) => ({
                      ...prevCardInfo,
                      card_type: newCardType,
                    }));
                  }}
                  dataCy="dropdown-card-type"
                />
              </div>
            </div>
            <div className="dropdown-wrapper">
              <div className="headline">Visibility</div>
              <div className="dropdown-visibility">
                <DropdownMenuPrivacy
                  category="privacy"
                  currentVisibility={cardInfo.visibility}
                  handleVisibilityChange={(newVisibility) =>
                    setCardInfo((prevCardInfo) => ({
                      ...prevCardInfo,
                      visibility: newVisibility,
                    }))
                  }
                  dataCy="dropdown-privacy"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left">
          <div className="headline">Title</div>
          <div className="description">Max 100 characters.</div>
        </div>
        <div className="card-row-right" data-cy="title-input">
          <TextInputComponent
            label=""
            handleInput={(e) => {
              setCardInfo((prevCardInfo) => ({
                ...prevCardInfo,
                title: e.target.value,
              }));
            }}
            limit={100}
            restriction={true}
            placeholder="Write a title"
            initialValue={cardInfo.title}
          />
        </div>
      </div>
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left">
          <div className="headline">
            {cardInfo.card_type === "Multiple Choice"
              ? "Question"
              : "Description"}
          </div>
          <div className="description">{`Max ${cardInfo.card_type === "Multiple Choice" ? "400" : "1000"} characters. ${cardInfo.card_type === "Flashcard" ? "This will be displayed on the front of the card." : ""}`}</div>
        </div>
        <div
          className="card-row-right card-editor"
          data-cy="description-question-input"
        >
          <RichTextEditorComponent
            object={cardInfo}
            setContent={(newContent) => {
              if (newContent !== cardInfo.description) {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  description: newContent,
                }));
              }
            }}
            type="cards"
            placeholder={`Write a ${cardInfo.card_type === "Multiple Choice" ? "question" : "description"}`}
            limit={cardInfo.card_type === "Multiple Choice" ? 400 : 1000}
          />
        </div>
      </div>
      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left">
          <div className="headline">Image</div>
          <div className="description">{`Optional${cardInfo.card_type === "Flashcard" ? ". Front of the card." : ""}`}</div>
        </div>
        <div className={`card-row-right`}>
          {/* {showCodeComponent && (
            <div>
              <div className="dropdowns">
                <div className="dropdown-wrapper">
                  <div className="headline">Language</div>
                  <div className="dropdown-language">
                    <DropdownMenuPrivacy
                      category="languages"
                      currentVisibility={cardInfo.language}
                      handleVisibilityChange={(newLanguage) =>
                        setCardInfo((prevCardInfo) => ({
                          ...prevCardInfo,
                          language: newLanguage,
                        }))
                      }
                      noPhoto={true}
                    />
                  </div>
                </div>
              </div>
              <TextAreaComponent
                label=""
                placeholder="Type your code here..."
                handleInput={(e) =>
                  setCardInfo((prevCardInfo) => ({
                    ...prevCardInfo,
                    code_string: e.target.value,
                  }))
                }
                initialInput={cardInfo.code_string}
                limit={200}
              />
            </div>
          )} */}
          {showImageComponent && (
            <div>
              <div
                className="upload-image"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, true)}
              >
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".jpg,.jpeg,.gif,.png"
                  onChange={(e) => handleChange(e, true)}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />

                {!cardInfo.image ? (
                  <>
                    <div className="upload-image-title">
                      <a
                        onClick={() => fileInputRef.current.click()}
                        className="upload-button"
                      >
                        Click to upload{" "}
                      </a>{" "}
                      or drag and drop
                    </div>
                    <p className="upload-image-instruction">
                      PNG, JPG, JPEG or GIF
                    </p>
                  </>
                ) : (
                  <>
                    <div className="upload-image-title">
                      Image uploaded successfully!{" "}
                      <a
                        className="remove"
                        onClick={() =>
                          setCardInfo((prevCardInfo) => ({
                            ...prevCardInfo,
                            image: null,
                            image_url: null,
                          }))
                        }
                      >
                        Remove.
                      </a>
                    </div>
                    <div className="upload-image-title"></div>
                    <p className="upload-image-instruction">
                      <a
                        onClick={() => fileInputRef.current.click()}
                        className="upload-button"
                      >
                        Click to reupload{" "}
                      </a>{" "}
                      or drag and drop{" "}
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="background-image">
            <div className="background-image-title">
              Use image as background
            </div>
            <div className="background-image-toggle">
              <ToggleComponent
                initialState={cardInfo.is_image_background}
                isAiSearch={false}
                handleToggleSwitch={() => {
                  setCardInfo((prevCardInfo) => ({
                    ...prevCardInfo,
                    is_image_background: !cardInfo.is_image_background,
                  }));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {cardInfo.card_type === "Multiple Choice" && (
        <div className={`card-row ${isMobile ? "mobile" : ""}`}>
          <div className="card-row-left">
            <div className="headline">Answers</div>
            <div className="description">
              Minimum of 2 options and a maximum of 4 options. Select the
              correct answer. The choices will be shuffled randomly. The maximum
              length of each answer is {ANSWER_LIMIT} characters.
            </div>
          </div>
          <div className="card-row-right">
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer1: e.target.value,
                }));
              }}
              limit={ANSWER_LIMIT}
              placeholder="Correct Answer"
              initialValue={cardInfo.answer1 ? cardInfo.answer1 : ""}
              check={"on"}
              dataCy="correct-answer1-input"
            />
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer2: e.target.value,
                }));
              }}
              placeholder="Answer"
              initialValue={cardInfo.answer2 ? cardInfo.answer2 : ""}
              limit={ANSWER_LIMIT}
              dataCy="incorrect-answer2-input"
            />
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer3: e.target.value,
                }));
              }}
              placeholder="Answer (optional)"
              initialValue={cardInfo.answer3 ? cardInfo.answer3 : ""}
              limit={ANSWER_LIMIT}
            />
            <TextInputComponent
              label={""}
              handleInput={(e) => {
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  answer4: e.target.value,
                }));
              }}
              placeholder="Answer (optional)"
              initialValue={cardInfo.answer4 ? cardInfo.answer4 : ""}
              limit={ANSWER_LIMIT}
            />
          </div>
        </div>
      )}
      {cardInfo.card_type === "Multiple Choice" && (
        <div className={`card-row ${isMobile ? "mobile" : ""}`}>
          <div className="card-row-left">
            <div className="headline">Explanation (Optional)</div>
            <div className="description">Max 200 characters.</div>
          </div>
          <div className="card-row-right">
            <TextAreaComponent
              label=""
              handleInput={(e) =>
                setCardInfo((prevCardInfo) => ({
                  ...prevCardInfo,
                  explanation: e.target.value,
                }))
              }
              limit={200}
              placeholder="Write an explanation"
              initialInput={cardInfo.explanation}
            />
          </div>
        </div>
      )}
      {cardInfo.card_type === "Flashcard" && (
        <>
          <div className={`card-row ${isMobile ? "mobile" : ""}`}>
            <div className="card-row-left">
              <div className="headline">Description</div>
              <div className="description">{`Max 1000 characters. This will be displayed on the back of the card.`}</div>
            </div>
            <div
              className="card-row-right card-editor"
              data-cy="description-back-input"
            >
              <RichTextEditorComponent
                object={cardInfo}
                intialDescription={cardInfo.descriptionBack}
                setContent={(newContent) => {
                  if (newContent !== cardInfo.descriptionBack) {
                    setCardInfo((prevCardInfo) => ({
                      ...prevCardInfo,
                      descriptionBack: newContent,
                    }));
                  }
                }}
                type="cards"
                placeholder={`Write a ${cardInfo.card_type === "Multiple Choice" ? "question" : "description"}`}
                limit={cardInfo.card_type === "Multiple Choice" ? 400 : 1000}
              />
            </div>
          </div>
          <div className={`card-row ${isMobile ? "mobile" : ""}`}>
            <div className="card-row-left">
              <div className="headline">Image</div>
              <div className="description">{`Optional${cardInfo.card_type === "Flashcard" ? ". Back of the card." : ""}`}</div>
            </div>
            <div className={`card-row-right`}>
              <div
                className="upload-image"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e, true)}
              >
                <input
                  ref={fileBackInputRef}
                  type="file"
                  accept=".jpg,.jpeg,.gif,.png"
                  onChange={(e) => handleChange(e, false)}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                />

                {!cardInfo.imageBack ? (
                  <>
                    <div className="upload-image-title">
                      <a
                        onClick={() => fileBackInputRef.current.click()}
                        className="upload-button"
                      >
                        Click to upload{" "}
                      </a>{" "}
                      or drag and drop
                    </div>
                    <p className="upload-image-instruction">
                      SVG, PNG, JPG or GIF (max. 800x400px)
                    </p>
                  </>
                ) : (
                  <>
                    <div className="upload-image-title">
                      Image uploaded successfully!{" "}
                      <a
                        className="remove"
                        onClick={() =>
                          setCardInfo((prevCardInfo) => ({
                            ...prevCardInfo,
                            imageBack: null,
                            image_url_back: null,
                          }))
                        }
                      >
                        Remove.
                      </a>
                    </div>
                    <div className="upload-image-title"></div>
                    <p className="upload-image-instruction">
                      <a
                        onClick={() => fileBackInputRef.current.click()}
                        className="upload-button"
                      >
                        Click to reupload{" "}
                      </a>{" "}
                      or drag and drop{" "}
                    </p>
                  </>
                )}
              </div>
              <div className="background-image">
                <div className="background-image-title">
                  Use image as background
                </div>
                <div className="background-image-toggle">
                  <ToggleComponent
                    initialState={cardInfo.is_back_image_background}
                    isAiSearch={false}
                    handleToggleSwitch={() => {
                      setCardInfo((prevCardInfo) => ({
                        ...prevCardInfo,
                        is_back_image_background:
                          !cardInfo.is_back_image_background,
                      }));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={`card-row ${isMobile ? "mobile" : ""}`}>
        <div className="card-row-left">
          <div className="headline">Tags and Categories</div>
          <div className="description">
            {hasGenerateButton
              ? "Add or generate tags and categories to your card."
              : "Add tags and categories to your card."}
          </div>
          {hasGenerateButton && (
            <div className="generate-button">
              <ButtonComponent
                icon={false}
                text="Generate"
                handleClick={handleGenerate}
                level={
                  disabledGenerate || disableButton ? "disable" : "primary"
                }
              />
            </div>
          )}
        </div>

        <div className="card-row-right">
          <MultipleComponent
            label="Categories"
            placeholderText="Enter your categories"
            handleTagChange={handleInputLearningObjective}
            initialInput={categories}
            categorySelector={true}
            limit={3}
            dataCy="category-input"
          />
          <MultipleComponent
            label="Tag"
            handleTagChange={handleTagChange}
            placeholder="Enter your tag"
            initialInput={tags}
            isLowerCased={true}
            categorySelector={false}
            dataCy="tag-input"
          />
        </div>
      </div>
    </div>
  );
};

export default AssCard;
