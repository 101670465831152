import React, { useState, useRef, useEffect } from "react";

import DraggableVideoItem from "./DraggableVideoItem.jsx";
import DraggableCardItem from "./DraggableCardItem.jsx";
import DraggablePlaylistItem from "./DraggablePlaylistItem.jsx";

const DraggableItem = React.forwardRef((props, ref) => {
  /*
  Accessible from  Dashboard > My Playlists, in the left column.
  Function that handles actions associated with a playlist, including:
    - Settings (three dots)
      - Watch
      - Edit
      - Share
      - Duplicate
      - Delete
    - Privacy change
    - Add video  
*/

  return (
    <div>
      {props.item.video_url ? (
        <DraggableVideoItem {...props} ref={ref} />
      ) : props.item.card_type ? (
        <DraggableCardItem {...props} ref={ref} />
      ) : (
        <DraggablePlaylistItem {...props} ref={ref} />
      )}
    </div>
  );
});

export default DraggableItem;
