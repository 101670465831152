import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { hideSubscription } from "../../actions/global.action.js";
import { userService } from "../../services/user.service.ts";
import useOutsideClick from "../../hooks/useOutsideClick.js";

import Spinner from "../Spinner/Spinner.jsx";
import Popup from "../../containers/Popup/Popup.jsx";

import crossSign from "../../assets/cross-sign.svg";
import leftArrow from "../../assets/chevron-left.svg";

const SubscriptionForm = () => {
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState();
  const stripeAPIKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
  const stripePromise = loadStripe(stripeAPIKey);

  const loadEmbeddedCheckout = async () => {
    const session = await userService.create_embedded_checkout();
    if (session.clientSecret) {
      setClientSecret(session.clientSecret);
    }
  };
  const options = { clientSecret };

  useEffect(() => {
    loadEmbeddedCheckout();
  }, []);

  const checkoutRef = useRef(null);
  const [closePopup, setClosePopup] = useState(false);
  useOutsideClick(checkoutRef, () => {
    setClosePopup(true);
  });
  const handleClose = () => {
    dispatch(hideSubscription());
    // if (location.pathname.includes("/login")) {
    //   navigate("/");
    // }
  };

  return (
    <Popup
      className="auth-container"
      closePopup={closePopup}
      closeFunc={() => handleClose()}
      withContainer={true}
    >
      <div className="auth-form popup" ref={checkoutRef}>
        <div className="auth-header">
          <div className="left-side-header">
            <span className="auth-title">Upgrade to Premium</span>
          </div>
          <button
            className="close-button"
            onClick={() => {
              setClosePopup(true);
            }}
          >
            <img src={crossSign} alt="close-button" />
          </button>
        </div>
        <div className="auth-content">
          {clientSecret ? (
            <EmbeddedCheckoutProvider options={options} stripe={stripePromise}>
              <EmbeddedCheckout />
            </EmbeddedCheckoutProvider>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "50vh",
                width: "100%",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </Popup>
  );
};
export default SubscriptionForm;
