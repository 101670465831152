import InfoIcon from "../../assets/info.svg";
import "./styles.scss";
import React from "react";

const TooltipComponent = ({
  tipText,
  placement = "right",
  showIcon = true,
  icon = null,
  longText = false,
  dataCy = "",
}) => {
  /*
    Tooltip component -- text appears next to a component on hover.
    Params:
      - tipText: text of the tooltio
      - placement: direction in which the text should appear (currently supports 'right', 'bottom', and 'sidebar')
      - showIcon: boolean to indicate whether the component should display an icon (by default info icon)
      - icon: icon that should be displayed
      - longText: boolean to indicate if text is long and should be separated into several lines
  */
  return (
    <div className="tooltip-thing" data-cy={dataCy}>
      {showIcon && <img className="info-icon" src={icon ? icon : InfoIcon} />}
      <span className={`tooltip-text ${placement} ${longText && "long-text"}`}>
        {tipText}
      </span>
    </div>
  );
};
export default TooltipComponent;
