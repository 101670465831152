import * as Axios from "axios";
import {
  ResponseInterceptorReject,
  ResponseInterceptorResolve,
} from "../interceptors/response.ts";
import {
  RequestInterceptorReject,
  RequestInterceptorResolve,
} from "../interceptors/request.ts";
import { CommonService, CoreApiService } from "../interface/services.ts";

// import { CommonService, CoreApiService } from ''

class ApiService implements CoreApiService, CommonService<any> {
  static instance: ApiService;

  static getInstance(): ApiService {
    if (!ApiService.instance) {
      ApiService.instance = new ApiService();
    }
    return ApiService.instance;
  }

  axios: Axios.AxiosInstance;

  constructor() {
    this.axios = Axios.default.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.axios.interceptors.request.use(
      RequestInterceptorResolve,
      RequestInterceptorReject,
    );
    this.axios.interceptors.response.use(
      ResponseInterceptorResolve,
      ResponseInterceptorReject,
    );
  }

  get(url: string, config?: Partial<Axios.AxiosRequestConfig>): Promise<any> {
    return this.axios.get(url, config);
  }

  post(
    url: string,
    data?: any,
    config?: Partial<Axios.AxiosRequestConfig>,
  ): Promise<any> {
    return this.axios.post(url, data, config);
  }

  put(
    url: string,
    data?: any,
    config?: Partial<Axios.AxiosRequestConfig>,
  ): Promise<any> {
    return this.axios.put(url, data, config);
  }

  patch(
    url: string,
    data?: any,
    config?: Partial<Axios.AxiosRequestConfig>,
  ): Promise<any> {
    return this.axios.patch(url, data, config);
  }

  delete(url: string): Promise<any> {
    return this.axios.delete(url);
  }
}

const instance = ApiService.getInstance();

export { instance as ApiService };
