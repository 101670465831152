import React, { useState, useEffect, useRef } from "react";
import { parseViews } from "../../../utils/video";
import TooltipComponent from "../../TooltipComponent/TooltipComponent";
import AuthorAvatar from "../../AuthorAvatar/AuthorAvatar";
import trashIcon from "../../../assets/trash.svg";
import { truncate } from "lodash";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { notifySuccess, notifyError } from "../../../actions/global.action";
import AlertModal from "../../../modals/AlertModal/AlertModal";
import { cardService } from "../../../services/card.service";

const AdminCardsCard = ({ card, isSelected, onSelect, refreshData }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const mountedRef = useRef(true);
  const dispatch = useDispatch();

  const notesCount = card.notes_count || 0;
  const visibilityValue = card.visibility ? card.visibility : "unknown";
  const visibilityText = visibilityValue.toLowerCase();

  // Format card type for display
  const getCardTypeDisplay = (type) => {
    switch (type) {
      case "flashcard":
        return "Flashcard";
      case "assessment":
        return "Multiple Choice";
      case "knowledge":
        return "Informational";
      default:
        return type || "Unknown";
    }
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleDeleteCard = async () => {
    setIsDeleting(true);
    try {
      await cardService.deleteAdminCard(card.id);
      dispatch(notifySuccess("Card deleted successfully"));
      setShowDeleteModal(false);

      // Deselect the card if it was selected
      onSelect(card.id);

      // Update the UI immediately
      if (typeof refreshData === "function") {
        refreshData(card.id);
      }
    } catch (error) {
      console.error("Error deleting card:", error);
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "Unknown error occurred";
      dispatch(notifyError(`Error deleting card: ${errorMessage}`));
    } finally {
      if (mountedRef.current) {
        setIsDeleting(false);
      }
    }
  };

  return (
    <tr className={isSelected ? "selected admin-card-row" : "admin-card-row"}>
      {/* 1) Checkbox */}
      <td className="checkbox-column">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelect(card.id)}
        />
      </td>

      {/* 2) Card Info Column */}
      <td className="card-info-column">
        <div className="card-info-wrapper">
          <a
            href={`/card/${card.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="card-preview"
            style={{ backgroundColor: card.color || "#58A5F8" }}
          >
            {card.image_url ? (
              <img
                src={card.image_url}
                className="admin-card-image"
                alt={card.title}
              />
            ) : (
              <div className="card-placeholder">
                {card.title ? card.title.charAt(0) : "?"}
              </div>
            )}
          </a>
          <div className="text-info">
            <div className="card-title" title={card.title}>
              {truncate(card.title || "Untitled Card", { length: 50 })}
            </div>
            <div className="card-type">
              {getCardTypeDisplay(card.card_type)}
            </div>
          </div>
        </div>
      </td>

      {/* 3) Channel Column */}
      <td className="channel-column">
        <div className="author-info">
          <AuthorAvatar
            author={card.author}
            size="small"
            className="author-avatar"
          />
          <div className="author-name">
            {card.author?.channel_name ||
              `${card.author?.first_name || ""} ${card.author?.last_name || ""}`.trim() ||
              card.author?.username ||
              "Unknown"}
          </div>
        </div>
      </td>

      {/* 4) Date */}
      <td className="date-cell">
        <span className="admin-card-date">
          {new Date(card.date).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </span>
      </td>

      {/* 5) Card Type */}
      <td className="type-cell">
        <span
          className={`type-badge ${card.card_type?.toLowerCase() || "unknown"}`}
        >
          {getCardTypeDisplay(card.card_type)}
        </span>
      </td>

      {/* 6) Views */}
      <td className="numeric-cell">{parseViews(card.views)}</td>

      {/* 7) Likes */}
      <td className="numeric-cell">
        {card.likes_count !== undefined && !isNaN(card.likes_count)
          ? parseViews(card.likes_count)
          : "0"}
      </td>

      {/* 8) Playlists */}
      <td className="numeric-cell">{parseViews(card.playlists_count)}</td>

      {/* 9) Notes */}
      <td className="numeric-cell">
        {card.notes_count !== undefined && !isNaN(card.notes_count)
          ? card.notes_count
          : "0"}
      </td>

      {/* 10) Visibility */}
      <td className="visibility-column">
        <span className={`status-badge ${visibilityText}`}>
          {card.visibility || "Unknown"}
        </span>
      </td>

      {/* 11) Actions */}
      <td className="actions-column">
        <button
          className="control-button"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowDeleteModal(true);
          }}
        >
          <TooltipComponent tipText="Delete" icon={trashIcon} />
        </button>
        {showDeleteModal && (
          <AlertModal
            type="danger"
            title="Delete Card"
            message={`Are you sure you want to delete "${truncate(card.title, { length: 60, omission: "..." })}"?`}
            buttonDeleteText={isDeleting ? "Deleting..." : "Delete"}
            onContinue={handleDeleteCard}
            onCancel={() => setShowDeleteModal(false)}
            dataCy="delete-card-modal"
            disabled={isDeleting}
          />
        )}
      </td>
    </tr>
  );
};

export default AdminCardsCard;
