import React, { useState, useEffect, useRef } from "react";
import { formatSimpleDate } from "../../../utils/video";
import PlaylistThumbnail from "../../PlaylistThumbnail/PlaylistThumbnail";
import AuthorAvatar from "../../AuthorAvatar/AuthorAvatar";
import trashIcon from "../../../assets/trash.svg";
import TooltipComponent from "../../TooltipComponent/TooltipComponent";
import AlertModal from "../../../modals/AlertModal/AlertModal.jsx";
import { playlistService } from "../../../services/playlist.service.ts";
import { useDispatch } from "react-redux";
import { notifyError, notifySuccess } from "../../../actions/global.action";
import { truncate } from "lodash";
import "./styles.scss";

const AdminPlaylistCard = ({
  playlist,
  isSelected,
  onSelect,
  onDelete,
  refreshData,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const mountedRef = useRef(true);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  // Get the first category if available, with better fallback handling
  const firstCategory =
    playlist.categories &&
    Array.isArray(playlist.categories) &&
    playlist.categories.length > 0
      ? playlist.categories[0]
      : null;

  // Handle both object format and UUID format
  const categoryName = firstCategory
    ? typeof firstCategory === "object" && firstCategory.name
      ? firstCategory.name
      : "Uncategorized"
    : "Uncategorized";

  const categorySlug = categoryName.toLowerCase().replace(/\s+/g, "-");

  const handleDeletePlaylist = async () => {
    setIsDeleting(true);
    try {
      await playlistService.deleteAdminPlaylist(playlist.id);
      dispatch(notifySuccess("Playlist deleted successfully"));
      setShowDeleteModal(false);

      // Deselect the playlist
      onSelect(playlist.id);

      // Update the UI immediately
      if (typeof refreshData === "function") {
        refreshData();
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "Unknown error occurred";
      dispatch(notifyError(`Error deleting playlist: ${errorMessage}`));
    } finally {
      if (mountedRef.current) {
        setIsDeleting(false);
      }
    }
  };

  return (
    <tr
      className={
        isSelected ? "selected admin-playlist-row" : "admin-playlist-row"
      }
    >
      {/* 1) Checkbox */}
      <td className="checkbox-column">
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => onSelect(playlist.id)}
        />
      </td>

      {/* 2) Playlist Info Column */}
      <td className="playlist-info-column">
        <div className="playlist-info-wrapper">
          <a
            href={`/playlist/${playlist.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="thumbnail-link"
          >
            <div className="admin-playlist-thumbnail">
              <PlaylistThumbnail
                noPhoto={false}
                playlist={playlist}
                thumbnail={playlist.thumbnail_url}
                thumbnail_type={playlist.thumbnail_type}
                isCustomized={playlist.is_customized_thumbnail}
              />
            </div>
          </a>
          <div className="text-info">
            <div className="playlist-title" title={playlist.title}>
              <a
                href={`/playlist/${playlist.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className="title-link"
              >
                {truncate(playlist.title, { length: 14, omission: "..." })}
              </a>
            </div>
          </div>
        </div>
      </td>

      {/* 3) Author Column */}
      <td className="author-info-column">
        <div className="author-info-wrapper">
          <AuthorAvatar
            author={playlist.user}
            noAvatar={!playlist.user?.avatar_url}
            redirectToChannelPage={false}
            className="admin-playlist-avatar"
          />
          <span className="author-name">
            {playlist.user?.first_name} {playlist.user?.last_name}
          </span>
        </div>
      </td>

      {/* 4) Category */}
      <td className="category-column">
        <span className={`category-badge ${categorySlug}`}>{categoryName}</span>
      </td>

      {/* 5) Created Date */}
      <td className="date-cell">
        <span className="admin-playlist-date">
          {new Date(playlist.created_at).toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </span>
      </td>

      {/* 6) Views */}
      <td className="numeric-cell">{playlist.views || 0}</td>

      {/* 7) Items Count */}
      <td className="numeric-cell">
        {playlist.video_count + playlist.card_count || 0}
      </td>

      {/* 8) Video Items */}
      <td className="numeric-cell">{playlist.video_count || 0}</td>

      {/* 9) Card Items */}
      <td className="numeric-cell">{playlist.card_count || 0}</td>

      {/* 10) Visibility */}
      <td className="visibility-column">
        <span
          className={`status-badge ${
            playlist.visibility ? playlist.visibility.toLowerCase() : "unknown"
          }`}
        >
          {playlist.visibility || "Unknown"}
        </span>
      </td>

      {/* 11) Actions */}
      <td className="actions-column">
        <button
          className="control-button"
          onClick={() => setShowDeleteModal(true)}
        >
          <TooltipComponent tipText="Delete" icon={trashIcon} />
        </button>
      </td>

      {showDeleteModal && (
        <AlertModal
          type="danger"
          title="Delete Playlist"
          message={`Are you sure you want to delete "${playlist.title}"?`}
          buttonDeleteText="Delete"
          onContinue={handleDeletePlaylist}
          onCancel={() => setShowDeleteModal(false)}
          isLoading={isDeleting}
          dataCy="delete-playlist-modal"
        />
      )}
    </tr>
  );
};

export default AdminPlaylistCard;
