import { useEffect } from "react";

export const useFeedCardObservers = (
  cardRefs,
  setCurrFeedCardIndex,
  currFeedCardIndexRef,
  isObserverPausedRef,
) => {
  useEffect(() => {
    try {
      // Create an observer that updates the active index based on intersection
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            const index = cardRefs.current.findIndex(
              (ref) => ref.current === entry.target,
            );
            if (entry.isIntersecting && !isObserverPausedRef.current) {
              // Set the active card index (make it 1-based)
              setCurrFeedCardIndex(index + 1);
              currFeedCardIndexRef.current = index + 1;
            }
          });
        },
        {
          // Adjust the threshold to decide when a card is “active”
          threshold: 0.5,
        },
      );

      // Observe every card element in cardRefs
      cardRefs.current.forEach((ref) => {
        if (ref.current) observer.observe(ref.current);
      });

      // Cleanup: unobserve each element on unmount or dependency change
      return () => {
        cardRefs.current.forEach((ref) => {
          if (ref.current) observer.unobserve(ref.current);
        });
      };
    } catch (error) {
      console.error("Error setting up IntersectionObserver:", error);
    }
    // Adding cardRefs.current.length ensures the observer resets when new cards are added.
  }, [
    cardRefs,
    setCurrFeedCardIndex,
    currFeedCardIndexRef,
    isObserverPausedRef,
    cardRefs.current.length,
  ]);
};
