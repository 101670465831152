import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { userService } from "../services/user.service.ts";
import Spinner from "../components/Spinner/Spinner";

const PrivateAdminRoute = ({ children }) => {
  const [isSuperuser, setIsSuperuser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkSuperuser = async () => {
      try {
        const response = await userService.checkSuperuserStatus();
        setIsSuperuser(response.is_superuser);
      } catch (error) {
        console.error("Error checking superuser status:", error);
        setIsSuperuser(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkSuperuser();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return isSuperuser ? children : <Navigate to="/" replace />;
};

export default PrivateAdminRoute;
