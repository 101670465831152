import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useFeedCardObservers } from "../../hooks/useFeedCardObservers";
import useWindowWidth from "../../hooks/useWindowWidth";
import useWindowHeight from "../../hooks/useWindowHeight";
import { showSignup } from "../../actions/global.action.js";

import FeedCard from "../../components/FeedComponents/FeedCard";
import InfoCard from "../../components/FeedComponents/InfoCard";

import chevronDown from "../../assets/chevron-down.svg";
import chevronUp from "../../assets/chevron-up.svg";
import chevronUpUp from "../../assets/chevron-up-up.svg";
import "./styles.scss";

import { cardTypeDict } from "../../utils/constants";

const FeedContainer = ({
  data,
  type,
  isMyTab,
  isPrivatePage,
  isEmpty,
  showSignUpForm,
  playlistTitle,
  cardRefs,
  totalNumOfCards,
  currentVideoIndex = 0,
  // pagination: { currentPage, totalPages, setCurrentPage, isFetchingMore }
  pagination,
}) => {
  const dispatch = useDispatch();

  // Window dimensions and responsiveness
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const [deviceType, setDeviceType] = useState("desktop");

  // Global modal state from Redux
  const showModal = useSelector((state) => state.global.showModal);

  // Local state for detail mode and options
  const [isDetailMode, setIsDetailMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState("description");

  const feedContainerRef = useRef(null);
  // Once the container mounts, store its node for the Intersection Observer.
  const [containerNode, setContainerNode] = useState(null);
  useEffect(() => {
    setContainerNode(feedContainerRef.current);
  }, []);

  // Observer-related state for tracking which card is visible
  const [currFeedCardIndex, setCurrFeedCardIndex] = useState(0);
  const currFeedCardIndexRef = useRef(0);
  const isObserverPausedRef = useRef(false);

  const [isUpChevronHovered, setIsUpChevronHovered] = useState(false);

  useFeedCardObservers(
    cardRefs,
    setCurrFeedCardIndex,
    currFeedCardIndexRef,
    isObserverPausedRef,
  );

  // Adjust deviceType based on window dimensions
  useEffect(() => {
    if (windowWidth > 1440) {
      setDeviceType("desktop");
    } else if (windowWidth > 1280) {
      setDeviceType("macbook-pro");
    } else if (windowWidth > 770) {
      setDeviceType("macbook-air");
    } else {
      setDeviceType("mobile");
    }
    if (windowHeight < 700) {
      setDeviceType("mobile");
    }
  }, [windowWidth, windowHeight]);

  let isThrottled = false;
  const handleChevron = (newIndex, oneStepDown = false) => {
    if (isThrottled) return;
    const targetIndex = oneStepDown ? currFeedCardIndex + 1 : newIndex;
    const container = feedContainerRef.current;
    const targetElement = cardRefs.current[targetIndex - 1]?.current;
    if (container && targetElement) {
      container.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
      isThrottled = true;
      setTimeout(() => {
        isThrottled = false;
      }, 500);
    }
  };

  useEffect(() => {
    if (currentVideoIndex !== 0) {
      navigateToItem(currentVideoIndex);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVideoIndex]);

  const navigateToItem = (newIndex) => {
    isObserverPausedRef.current = true;
    handleChevron(newIndex);
    setCurrFeedCardIndex(newIndex);
    setTimeout(() => {
      isObserverPausedRef.current = false;
    }, 1000);
  };

  const navigateToNextItem = () => {
    const chevronDownButton = document.getElementById("chevron-down");
    if (chevronDownButton) {
      chevronDownButton.click();
    }
  };

  const handleGlobalKeyDown = (event) => {
    if (showModal) return;
    if (
      (event.target.classList.contains("tiptap") &&
        event.target.classList.contains("ProseMirror")) ||
      event.target.classList.contains("text-input-component-input")
    ) {
      return;
    }
    if (event.key === "ArrowDown") {
      event.preventDefault();
      const chevronDownButton = document.getElementById("chevron-down");
      if (chevronDownButton) {
        chevronDownButton.click();
      }
    } else if (event.key === "ArrowUp") {
      event.preventDefault();
      const chevronUpButton = document.getElementById("chevron-up");
      if (chevronUpButton) {
        chevronUpButton.click();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyDown);
    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [showModal]);

  let isScrollThrottled = false;
  let lastScrollTop = 0;
  const handleScroll = () => {
    if (isScrollThrottled || !showSignUpForm) return;
    const container = feedContainerRef.current;
    if (!container) return;
    const scrollTop = container.scrollTop;
    if (scrollTop > lastScrollTop) {
      if (isScrollThrottled) return;
      isScrollThrottled = true;
      dispatch(showSignup());
      setTimeout(() => {
        isScrollThrottled = false;
      }, 500);
    }
    lastScrollTop = scrollTop;
  };

  useEffect(() => {
    const container = feedContainerRef.current;
    if (container && currFeedCardIndex === data?.length) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [showSignUpForm, currFeedCardIndex, data?.length, dispatch]);

  const sentinelRef = useRef(null);
  const isFetchingRef = useRef(false);

  useEffect(() => {
    if (
      pagination &&
      pagination.currentPage < pagination.totalPages &&
      containerNode &&
      sentinelRef.current
    ) {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting && !isFetchingRef.current) {
            isFetchingRef.current = true;
            // Trigger loading of the next page:
            pagination.setCurrentPage(pagination.currentPage + 1);
            setTimeout(() => {
              isFetchingRef.current = false;
            }, 1000);
          }
        },
        {
          root: containerNode,
          // Adjust the rootMargin to trigger earlier (e.g. 600px instead of 200px)
          rootMargin: "1000px 0px 0px 0px",
          threshold: 0,
        },
      );
      observer.observe(sentinelRef.current);
      return () => {
        if (sentinelRef.current) {
          observer.unobserve(sentinelRef.current);
        }
      };
    }
  }, [
    pagination,
    pagination?.currentPage,
    pagination?.totalPages,
    containerNode,
  ]);

  if (isPrivatePage) {
    return (
      <div className="feed-container">
        <div className="feed-container-card">
          <InfoCard type="privatePage" />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div
        ref={feedContainerRef}
        className={`feed-container hidden-scrollbar ${isMyTab ? " my-tab" : ""}${
          isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""
        }${data?.length <= 1 ? " one-card" : ""}`}
      >
        <div className="feed-container-card">
          <InfoCard type="noVideos" />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={feedContainerRef}
      className={`feed-container hidden-scrollbar${
        isMyTab ? " my-tab" : ""
      }${isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""}${
        data?.length <= 1 ? " one-card" : ""
      }${type === "video-preview" ? " video-preview" : ""}`}
    >
      {data?.map((video, index) => (
        <div
          className={`feed-container-card${
            type === "video-preview" ? " video-preview-card" : ""
          }`}
          key={video.id + index}
          ref={cardRefs.current[index]}
        >
          <FeedCard
            type={
              video?.card_type === "video"
                ? "video"
                : Object.values(cardTypeDict).includes(video?.card_type)
                  ? "card"
                  : type
            }
            data={data}
            video={video}
            deviceType={deviceType}
            isMyTab={isMyTab}
            isDetailMode={isDetailMode && deviceType !== "mobile"}
            setIsDetailMode={setIsDetailMode}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            navigateToItem={navigateToItem}
            handleChevron={handleChevron}
            playlistTitle={playlistTitle}
            currFeedCardIndex={currFeedCardIndex}
            index={index + 1}
            navigateToNextItem={navigateToNextItem}
            isVisible={currFeedCardIndex === index + 1}
          />
        </div>
      ))}

      {/* Navigation controls (chevrons) remain unchanged */}
      <div
        className={`feed-navigation ${
          isDetailMode &&
          deviceType !== "mobile" &&
          (type !== "playlist" || ![1, data.length].includes(currFeedCardIndex))
            ? `focus-mode ${deviceType}`
            : ""
        }`}
        onMouseEnter={() => setIsUpChevronHovered(true)}
        onMouseLeave={() => setIsUpChevronHovered(false)}
      >
        {isUpChevronHovered && deviceType !== "mobile" && (
          <div
            className={`chevron-container ${
              isUpChevronHovered ? "show-double-up" : ""
            }`}
          >
            {currFeedCardIndex !== 1 && deviceType !== "mobile" && (
              <button id="chevron-up-up" onClick={() => handleChevron(1)}>
                <img src={chevronUpUp} alt="Scroll Up" />
              </button>
            )}
          </div>
        )}
        <div className="chevron-container">
          {currFeedCardIndex !== 1 && deviceType !== "mobile" && (
            <button
              id="chevron-up"
              onClick={() => handleChevron(currFeedCardIndex - 1)}
            >
              <img src={chevronUp} alt="Scroll Up" />
            </button>
          )}
        </div>
        <div className="chevron-container">
          {currFeedCardIndex !== totalNumOfCards && deviceType !== "mobile" && (
            <button
              id="chevron-down"
              onClick={() => handleChevron(currFeedCardIndex + 1)}
            >
              <img src={chevronDown} alt="Scroll Down" />
            </button>
          )}
        </div>
      </div>

      {/* Sentinel element for auto-pagination */}
      {pagination && pagination.currentPage < pagination.totalPages && (
        <div ref={sentinelRef} style={{ height: "1px" }}></div>
      )}

      {/* Optional loading indicator for pagination */}
      {pagination && pagination.isFetchingMore && (
        <div className="loading-more-indicator">Loading more...</div>
      )}
    </div>
  );
};

export default FeedContainer;
