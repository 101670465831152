import { ApiService } from "./api.service.ts";

class CardService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getCard(cardId: string): Promise<any> {
    return await ApiService.get(`/cards/${cardId}/`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createCard(data: any): Promise<any> {
    return await ApiService.post("/cards/create_card", data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async editCard(cardId: string, data: any): Promise<any> {
    return await ApiService.post(`/cards/${cardId}/edit`, data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deleteCard(cardId: string): Promise<any> {
    return await ApiService.delete(`/cards/${cardId}/delete`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async viewCard(cardId: string): Promise<any> {
    return await ApiService.post(`/cards/${cardId}/view`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async addLike(id: string, type: "video" | "card" = "card"): Promise<any> {
    const endpoint = type === "video" ? "/video/like/" : "/card/like/";
    const data = type === "video" ? { video_id: id } : { card_id: id };
    const response = await ApiService.post(endpoint, data);

    // After successful like, refetch the cards data
    await this.getAllCardsAdmin({
      page: 1,
      pageSize: 10,
      sortBy: "date",
      sortOrder: "desc",
      timeRange: "All Time",
    });
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async removeLike(id: string, type: "video" | "card" = "card"): Promise<any> {
    const endpoint = type === "video" ? "/video/unlike/" : "/card/unlike/";
    const data = type === "video" ? { video_id: id } : { card_id: id };
    const response = await ApiService.post(endpoint, data);

    // After successful unlike, refetch the cards data
    await this.getAllCardsAdmin({
      page: 1,
      pageSize: 10,
      sortBy: "date",
      sortOrder: "desc",
      timeRange: "All Time",
    });
    return response;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getUserCards(): Promise<any> {
    return await ApiService.get(`/user/cards`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getChannelCards(userId: string): Promise<any> {
    return await ApiService.get(`/user/${userId}/cards/`);
  }

  async changeCardVisibility(cardId: string, visibility: string) {
    return await ApiService.post(`/card/change-visibility/${cardId}/`, {
      visibility,
    });
  }

  async updateCardViews(cardId: string) {
    return await ApiService.post(`/card/update-views/${cardId}/`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async generateCardTagsAndCategories(data: any): Promise<any> {
    return await ApiService.post("/card/generate-tags-and-categories/", data);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async getAllCardsAdmin(params: any): Promise<any> {
    try {
      const timeRangeMap: { [key: string]: string } = {
        "All Time": "all",
        "7 days": "7_days",
        "30 days": "30_days",
        "12 months": "12_months",
        Custom: "custom",
      };

      const queryParams = new URLSearchParams({
        page: params.page?.toString() || "1",
        page_size: params.pageSize?.toString() || "10",
        sort_by: params.sortBy || "date",
        sort_order: params.sortOrder || "desc",
        time_range: timeRangeMap[params.timeRange] || "all",
        search: params.search || "",
      });

      const response = await ApiService.get(`/api/admin/cards/?${queryParams}`);

      // Map and ensure all required fields are present
      const cards = (response?.cards || []).map((card: any) => ({
        ...card,
        tags: card?.tags || [],
        likes_count: parseInt(card?.likes_count) || 0,
        playlists_count: parseInt(card?.playlists_count) || 0,
        notes_count: parseInt(card?.notes_count) || 0,
        views: parseInt(card?.views) || 0,
        author: card?.author || {},
        date: card?.date || new Date().toISOString(),
      }));

      return {
        cards,
        total: response?.total || 0,
        pages: response?.pages || 1,
        current_page: response?.current_page || 1,
      };
    } catch (error) {
      console.error("Error in getAllCardsAdmin:", error);
      throw error;
    }
  }

  async deleteAdminCard(id: string): Promise<any> {
    return ApiService.delete(`/api/admin/cards/${id}/delete/`);
  }

  async bulkDeleteAdminCards(cardIds: string[]): Promise<any> {
    return ApiService.post(`/api/admin/cards/bulk-delete/`, {
      card_ids: cardIds,
    });
  }
}

export const cardService = new CardService();
