import React, { useState } from "react";
import "./styles.scss";
import {
  fetchPlaylists,
  updatePlaylistStructure,
} from "../../actions/playlist";
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { notifySuccess, notifyError } from "../../actions/global.action";
import { playlistService } from "../../services/playlist.service.ts";
import ItemCard from "../ItemCard/ItemCard.jsx";

const VideoItem = ({
  video,
  isVideo = true,
  playlist,
  preview,
  isAiRecommendation = false,
  addVideo,
  removeVideo,
}) => {
  /*
    Renders each available video item in the AddVideoToPlaylist pop out window.
    Displays different buttons 'Added' or 'Add' for each video based on whether it is already in a selected playlist.
  */
  const dispatch = useDispatch();
  const items = useSelector((state) => state.playlist.playlists);
  const playlistCurr = useSelector((state) => state.playlist.currentPlaylist);
  const selectedPlaylist = items.find(
    (item) => item.object_id === playlistCurr.object_id,
  );
  const selectedPlaylistItems = selectedPlaylist?.children?.map(
    (video) => video.object_id,
  );
  const [isAdded, setIsAdded] = useState(
    isAiRecommendation
      ? playlist.videos.some((child) => child.id === video.id)
      : selectedPlaylistItems?.includes(video.id),
  );

  const handleClick = () => {
    if (isVideo) {
      preview(video);
    }
  };

  const addToPlaylist = () => {
    if (isAiRecommendation) {
      setIsAdded(true);
      addVideo(video.id);
    } else {
      if (isVideo) {
        playlistService
          .addVideoToPlaylist(video.id, playlist.object_id)
          .then(() => {
            dispatch(updatePlaylistStructure(items, playlist));
            dispatch(fetchPlaylists());
            dispatch(notifySuccess("Video added to playlist"));
            setIsAdded(true);
          })
          .catch((error) => {
            dispatch(notifyError(error.response?.data?.error || "Failed to add video to playlist"));
          });
      } else {
        playlistService
          .addCardToPlaylist(video.id, playlist.object_id)
          .then(() => {
            dispatch(fetchPlaylists());
            dispatch(updatePlaylistStructure(items, playlist));
            dispatch(notifySuccess("Card added to playlist"));
            setIsAdded(true);
          })
          .catch((error) => {
            dispatch(notifyError(error.response?.data?.error || "Failed to add card to playlist"));
          });
      }
    }
  };

  const deleteFromPlaylist = () => {
    if (isAiRecommendation) {
      setIsAdded(false);
      removeVideo(video.id);
    } else {
      dispatch(fetchPlaylists());

      playlistService
        .deleteItemFromPlaylist(playlist.object_id, video.id)
        .then(() => {
          dispatch(updatePlaylistStructure(items, playlist));
          dispatch(fetchPlaylists());
          setIsAdded(false);
          dispatch(
            notifySuccess(
              `${isVideo ? "Video" : "Card"} removed from the playlist`,
            ),
          );
        })
        .catch((error) => {
          console.error(
            `Error removing ${isVideo ? "video" : "card"} from playlist:`,
            error.message,
          );
          dispatch(
            notifyError(
              `Error removing ${isVideo ? "video" : "card"} from playlist`,
              error.message,
            ),
          );
        });
    }
  };

  return (
    <div className="shell-component">
      <div className="video-part" data-cy="add-item-modal-video-item">
        <ItemCard
          key={video.id}
          object={video}
          handleClick={handleClick}
          redirectToChannelPage={false}
        />
      </div>
      <div className="button-part">
        <div className="button-container">
          <ButtonComponent
            text={isAdded ? "Added" : "Add"}
            handleClick={isAdded ? deleteFromPlaylist : addToPlaylist}
            icon={isAdded ? true : false}
            level={isAdded ? "secondary" : "primary"}
            checkmarkIcon={true}
            size={isAdded ? "small" : "medium"}
            dataCy="add-item-modal-add-item-button"
          />
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
