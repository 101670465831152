import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PlaylistThumbnail from "../PlaylistThumbnail/PlaylistThumbnail.jsx";
import ImageCropper from "../ImageCropComponent/ImageCrop.jsx";
import { notifyError } from "../../actions/global.action.js";
import {
  ASPECT_RATIO,
  MIN_DIMENSION,
  FILE_SIZE_LIMIT,
} from "../../utils/constants.js";
import "./styles.scss";

const ThumbnailChangeComponentPlaylist = ({
  playlist,
  img,
  setImg,
  setThumbnail,
  redirect = false,
  type,
}) => {
  /*
    Component that handles thumbnail change. Currently used when editing playlist and editing it.
      - playlist: playlist object 
      - img: variable for displaying the thumbnail
      - setImg: function to change thumbnail display
      - setThumbnail: function to store the new thumbnail file
      - redirect: boolean that determines whether click on the thumbnail should open video page
  */
  const thumbnailUploadRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [imgSrc, setImgSrc] = useState("");
  const [showCrop, setShowCrop] = useState(false);
  const [fileType, setFileType] = useState();

  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (!file) return;
    if (file.size > FILE_SIZE_LIMIT) {
      dispatch(notifyError("File size should be less than 5 MB"));
      return;
    }
    setFileType(file.type);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          dispatch(notifyError("Image must be at least 150 x 150 pixels."));
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
    setShowCrop(true);
  };

  const handleClose = () => setShowCrop(false);

  const handleClickThumbnail = () => thumbnailUploadRef.current.click();

  const removeThumbnail = () => {
    if (type === "create") {
      setImg(null);
      setThumbnail(null);
      return;
    }
    // check if img is base64 string
    if (img?.includes("data:image/")) {
      setImg(playlist?.customized_thumbnail_url || playlist?.thumbnail_url);
      setThumbnail(playlist?.customized_thumbnail_url);
    } else {
      setImg(playlist?.thumbnail_url);
      setThumbnail(null);
    }
  };

  const openVideo = () => {
    if (redirect) {
      navigate(`/playlist/${playlist.id}`);
    }
  };

  return (
    <div className="thumbnail-edit-container">
      <input
        ref={thumbnailUploadRef}
        type="file"
        style={{ display: "none" }}
        onChange={onSelectFile}
        accept=".jpg,.jpeg,.png"
        onClick={(event) => (event.target.value = null)}
      />
      <div className="video-thumbnail" onClick={openVideo}>
        <PlaylistThumbnail
          playlist={playlist}
          thumbnail={img || playlist?.customized_thumbnail_url}
          thumbnail_type={
            img === playlist?.thumbnail_url
              ? playlist?.thumbnail_type
              : "landscape"
          }
          isCustomized={
            img?.includes("data:image/") ||
            img === playlist?.customized_thumbnail_url
          }
          noPhoto={
            (type === "create" && !img?.includes("data:image/")) ||
            (type === "edit" && img === null)
          }
          itemsCount={type !== "edit" ? 0 : null}
        />
      </div>
      <div className="instruction">
        (Supported file types: JPG, JPEG, and PNG. Max file size: 5 MB)
      </div>
      <div className="thumbnail-edit">
        <div onClick={handleClickThumbnail} className="thumbnail-btn">
          Change
        </div>
        <div
          onClick={removeThumbnail}
          className={`thumbnail-btn ${
            (type === "create" && img === null) ||
            img === playlist?.thumbnail_url
              ? "no-action"
              : ""
          }`}
        >
          Remove
        </div>
      </div>
      {showCrop && (
        <ImageCropper
          imgSrc={imgSrc}
          handleClose={handleClose}
          setImg={setImg}
          ASPECT_RATIO={ASPECT_RATIO}
          setThumbnail={setThumbnail}
          fileType={fileType}
        />
      )}
    </div>
  );
};

export default ThumbnailChangeComponentPlaylist;
