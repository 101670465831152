import React from "react";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import noVideo from "../../assets/no-video.svg";
import { cardMapping } from "../../utils/constants";

// TODO: refactor this component
const ThumbnailComponent = ({ video, thumbnail = null, isModal = false }) => {
  const location = useLocation();
  /*
    Thumbnail component alligns with Figma design (May 15)
    Note: current impolementation doesn't have heart symbol, sybject text and blue bar 
    There is another Card component that duplicates some of this component functionality 
    and thus should be deleted and replayced with this component
    */
  if (isModal) {
    return (
      <div className="video-image-container">
        <img
          className="video-image"
          src={thumbnail ? thumbnail : video.thumbnail_url || noVideo}
          style={{
            objectFit:
              video.video_type === "landscape" ? "cover" : "scale-down",
          }}
        />
      </div>
    );
  }

  if (location.search.includes("?tab=Favorites")) {
    return (
      <div className="video-image-container">
        {video.card_type !== "video" ? (
          <div className="card-thumbnail">
            <img src={cardMapping[video.card_type]?.icon} />
            <span>{cardMapping[video.card_type]?.text}</span>
          </div>
        ) : (
          <img
            className="video-image"
            src={thumbnail ? thumbnail : video.thumbnail_url || noVideo}
            style={{
              objectFit:
                video.video_type === "landscape" ? "cover" : "scale-down",
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className="video-image-container">
      {Object.keys(cardMapping).includes(video.card_type) ? (
        <div className="card-thumbnail">
          <img src={cardMapping[video.card_type]?.icon} />
          <span>{cardMapping[video.card_type]?.text}</span>
        </div>
      ) : thumbnail || video.thumbnail_url ? (
        <img
          className="video-image"
          src={thumbnail || video.thumbnail_url}
          style={{
            objectFit:
              video.video_type === "landscape" ? "cover" : "scale-down",
          }}
        />
      ) : (
        <div className="card-thumbnail">
          <img src={noVideo} className="deleted-thumbnail" />
          <span>Item deleted</span>
        </div>
      )}
    </div>
  );
};

export default ThumbnailComponent;
