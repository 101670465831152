import { ApiService } from "./api.service.ts";

class PlaylistService {
  async getPlaylist(
    playlistId: string,
    preview: boolean = false,
    page: number = 1,
  ): Promise<any> {
    return await ApiService.get(
      `/playlist/${playlistId}/?preview=${preview}&paginate=true&page=${page}`,
    );
  }

  async getPlaylistToEdit(playlistId: string): Promise<any> {
    return await ApiService.get(`/playlist/get-edit-playlist/${playlistId}/`);
  }

  async getPublicPlaylists(): Promise<any> {
    return await ApiService.get("/playlists/public/");
  }

  async getUserPlaylists(userId: string): Promise<any> {
    return await ApiService.get(`/user/${userId}/playlists/`);
  }

  async generatePlaylist(query: string): Promise<any> {
    return await ApiService.post(`/search/ai/playlist`, { query });
  }

  async createPlaylist(
    title: string,
    visibility: string,
    description: string,
    videos: string[],
    ai_generated: boolean = false,
    thumbnail: File | null = null,
  ): Promise<any> {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("visibility", visibility);
    formData.append("description", description);
    formData.append("ai_generated", ai_generated.toString());

    videos.forEach((videoId, index) => {
      formData.append(`videos[${index}]`, videoId);
    });

    if (thumbnail) {
      formData.append("thumbnail", thumbnail);
    }

    return await ApiService.post("/playlists/create/", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async addVideoToPlaylist(videoId: string, playlistId: string): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_video/`, {
      video_id: videoId,
    });
  }

  async addCardToPlaylist(
    cardId: string,
    playlistId: string,
    order?: number,
  ): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_card/`, {
      cardId: cardId,
      order: order ?? undefined,
    });
  }

  async addPlaylistToPlaylist(
    childPlaylistId: string,
    playlistId: string,
  ): Promise<any> {
    return await ApiService.post(`/playlists/${playlistId}/add_playlist/`, {
      child_playlist_id: childPlaylistId,
    });
  }

  async editPlaylist(playlistId: string, formData: FormData): Promise<any> {
    return await ApiService.post(
      `/playlist/edit-playlist/${playlistId}/`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }

  async updatePlaylistStructure(
    data: any,
    currentItemId?: string,
  ): Promise<any> {
    return await ApiService.post("/playlists/update-playlist-structure/", {
      data,
      current_item_id: currentItemId,
    });
  }

  async deleteFromPlaylist(objectId: string): Promise<any> {
    return await ApiService.delete(`/playlist/item/${objectId}/remove/`);
  }

  async deleteItemFromPlaylist(
    playlistId: string,
    itemId: string,
  ): Promise<any> {
    return await ApiService.delete(
      `/playlist/${playlistId}/item/${itemId}/remove/`,
    );
  }

  async deletePlaylist(playlistId: string): Promise<any> {
    return await ApiService.delete(`/playlists/${playlistId}/delete/`);
  }

  async duplicatePlaylist(itemId: string, parentId: string): Promise<any> {
    return await ApiService.post(
      `/playlist/duplicate-and-add/${itemId}/${parentId}/`,
    );
  }

  async changePlaylistVisibility(
    playlistId: string,
    visibility: string,
  ): Promise<any> {
    return await ApiService.post(`/playlist/change-visibility/${playlistId}/`, {
      visibility,
    });
  }

  async getAllPlaylistsAdmin(params: any): Promise<any> {
    try {
      const timeRangeMap: { [key: string]: string } = {
        "All Time": "all_time",
        "24 hours": "24_hours",
        "7 days": "7_days",
        "30 days": "30_days",
        "12 months": "12_months",
        Custom: "custom",
      };

      const queryParams = new URLSearchParams({
        page: params.page?.toString() || "1",
        page_size: params.pageSize?.toString() || "10",
        sort_by: params.sort_by || "created_at",
        sort_order: params.sort_order || "desc",
        time_range: timeRangeMap[params.timeRange] || "all_time",
        search: params.search || "",
      });

      if (params.customRange?.startDate && params.customRange?.endDate) {
        queryParams.append("custom_start", params.customRange.startDate);
        queryParams.append("custom_end", params.customRange.endDate);
      }

      const response = await ApiService.get(
        `/api/admin/playlists/?${queryParams}`,
      );

      const playlists = (response?.playlists || []).map((playlist: any) => ({
        ...playlist,
        categories: playlist?.categories || [],
        tags: playlist?.tags || [],
        video_count: playlist?.video_count || 0,
        card_count: playlist?.card_count || 0,
        views: playlist?.views || 0,
        user: playlist?.user || {},
      }));

      return {
        playlists,
        total: response?.total || 0,
        pages: response?.pages || 1,
        current_page: response?.current_page || 1,
      };
    } catch (error) {
      console.error("Error in getAllPlaylistsAdmin:", error);
      throw error;
    }
  }

  async deleteAdminPlaylist(playlistId: string): Promise<any> {
    const response = await ApiService.delete(
      `/api/admin/playlists/${playlistId}/delete/`,
    );
    return response;
  }
}

export const playlistService = new PlaylistService();
