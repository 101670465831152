import React, { useState } from "react";
import "./styles.scss";

const TimeFilter = ({
  selectedRange,
  onRangeChange,
  customRange,
  onCustomRangeChange,
}) => {
  const [showCustomPicker, setShowCustomPicker] = useState(false);
  const timeRanges = ["7 days", "30 days", "12 months", "All Time", "Custom"];

  const handleCustomClick = () => {
    setShowCustomPicker(true);
  };

  const handleCustomRangeSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const startDate = formData.get("startDate");
    const endDate = formData.get("endDate");

    if (startDate && endDate) {
      onCustomRangeChange({ startDate, endDate });
      onRangeChange("Custom");
      setShowCustomPicker(false);
    }
  };

  const handleRangeSelection = (range) => {
    if (range === "Custom") {
      handleCustomClick();
    } else {
      onRangeChange(range);
      onCustomRangeChange(null);
    }
  };

  return (
    <div className="time-filter">
      {timeRanges.map((range) => (
        <button
          key={range}
          className={`time-filter-button ${selectedRange === range ? "active" : ""}`}
          onClick={() => handleRangeSelection(range)}
        >
          {range}
        </button>
      ))}

      {showCustomPicker && (
        <div className="custom-date-picker">
          <form onSubmit={handleCustomRangeSubmit}>
            <div className="date-inputs">
              <input
                type="date"
                name="startDate"
                defaultValue={customRange?.startDate}
                max={new Date().toISOString().split("T")[0]}
              />
              <input
                type="date"
                name="endDate"
                defaultValue={customRange?.endDate}
                max={new Date().toISOString().split("T")[0]}
              />
            </div>
            <div className="date-actions">
              <button type="submit">Apply</button>
              <button type="button" onClick={() => setShowCustomPicker(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default TimeFilter;
