import React, { useState, useEffect, useCallback, useRef } from "react";
import { playlistService } from "../../../services/playlist.service.ts";
import AdminPlaylistCard from "../AdminPlaylistCard/AdminPlaylistCard.jsx";
import AlertModal from "../../../modals/AlertModal/AlertModal.jsx";
import PaginationControls from "../PaginationControls/PaginationControls.jsx";
import Spinner from "../../Spinner/Spinner.jsx";
import { useDebounce } from "../../../hooks/useDebounce";
import trashIcon from "../../../assets/trash.svg";
import "./styles.scss";

const PlaylistsTable = ({ timeRange, customRange }) => {
  const [playlists, setPlaylists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPlaylists, setSelectedPlaylists] = useState(new Set());
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPlaylists, setTotalPlaylists] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "created_at",
    direction: "desc",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const responseCache = useRef(new Map());

  const getCacheKey = useCallback(() => {
    const customRangeKey = customRange
      ? `${customRange.startDate}-${customRange.endDate}`
      : "";
    return `${timeRange}-${customRangeKey}-${currentPage}-${pageSize}-${sortConfig.key}-${sortConfig.direction}-${debouncedSearchTerm}`;
  }, [
    timeRange,
    customRange,
    currentPage,
    pageSize,
    sortConfig,
    debouncedSearchTerm,
  ]);

  const debouncedSearch = useDebounce((value) => {
    setDebouncedSearchTerm(value);
    setCurrentPage(1);
  }, 500);

  const fetchPlaylists = useCallback(async () => {
    try {
      setSearchLoading(true);

      const cacheKey = getCacheKey();
      if (responseCache.current.has(cacheKey)) {
        const cachedData = responseCache.current.get(cacheKey);
        setPlaylists(cachedData.playlists);
        setTotalPlaylists(cachedData.total);
        setTotalPages(cachedData.pages);
        setSearchLoading(false);
        return;
      }

      const sort_mapping = {
        author: "user__first_name",
        title: "title",
        created_at: "created_at",
        views: "views",
        video_count: "video_count",
        card_count: "card_count",
        items_count: "items_count",
        visibility: "visibility",
        categories: "categories__name",
      };

      const params = {
        page: currentPage,
        pageSize: pageSize,
        sort_by: sort_mapping[sortConfig.key] || "created_at",
        sort_order: sortConfig.direction,
        timeRange: timeRange,
        search: debouncedSearchTerm,
      };

      if (
        timeRange === "Custom" &&
        customRange?.startDate &&
        customRange?.endDate
      ) {
        params.customRange = {
          startDate: customRange.startDate,
          endDate: customRange.endDate,
        };
      }

      const response = await playlistService.getAllPlaylistsAdmin(params);

      const processedPlaylists = response.playlists.map((playlist) => ({
        ...playlist,
        categories: playlist.categories || [],
      }));

      setPlaylists(processedPlaylists);
      setTotalPlaylists(response.total);
      setTotalPages(response.pages);

      responseCache.current.set(cacheKey, {
        playlists: processedPlaylists,
        total: response.total,
        pages: response.pages,
      });
    } catch (error) {
      console.error("Error fetching playlists:", error);
      setError("Failed to load playlists. Please try again later.");
    } finally {
      setLoading(false);
      setSearchLoading(false);
    }
  }, [
    currentPage,
    pageSize,
    sortConfig,
    timeRange,
    debouncedSearchTerm,
    customRange,
    getCacheKey,
  ]);

  useEffect(() => {
    fetchPlaylists();
  }, [fetchPlaylists]);

  useEffect(() => {
    if (searchTerm !== debouncedSearchTerm) {
      debouncedSearch(searchTerm);
    }
  }, [searchTerm, debouncedSearch, debouncedSearchTerm]);

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      if (prevConfig.key === key) {
        return {
          key,
          direction: prevConfig.direction === "asc" ? "desc" : "asc",
        };
      }
      return { key, direction: "desc" };
    });
  };

  const handleSelectPlaylist = (playlistId) => {
    setSelectedPlaylists((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(playlistId)) {
        newSelected.delete(playlistId);
      } else {
        newSelected.add(playlistId);
      }
      return newSelected;
    });
  };

  const handleSelectAll = (playlists) => {
    if (selectedPlaylists.size === playlists.length) {
      setSelectedPlaylists(new Set());
    } else {
      setSelectedPlaylists(new Set(playlists.map((playlist) => playlist.id)));
    }
  };

  const handleDeletePlaylists = async () => {
    try {
      await playlistService.bulkDeletePlaylists(Array.from(selectedPlaylists));
      setSelectedPlaylists(new Set());
      responseCache.current.clear();
      fetchPlaylists();
      setShowDeleteModal(false);
    } catch (error) {
      console.error("Error deleting playlists:", error);
    }
  };

  const allCurrentPageSelected =
    playlists.length > 0 &&
    playlists.every((playlist) => selectedPlaylists.has(playlist.id));

  return (
    <div className="admin-playlists-wrapper">
      <div className="table-controls">
        <div className="search-field">
          <input
            type="text"
            placeholder="Search playlists..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          {searchLoading && (
            <div className="search-indicator">
              <Spinner size="small" />
            </div>
          )}
        </div>

        {selectedPlaylists.size > 0 && (
          <button
            className="delete-selected"
            onClick={() => setShowDeleteModal(true)}
          >
            <img src={trashIcon} alt="Delete" />
            Delete Selected ({selectedPlaylists.size})
          </button>
        )}
      </div>

      {loading ? (
        <div className="loading-container">
          <Spinner />
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : playlists.length === 0 ? (
        <div className="no-results">No playlists found</div>
      ) : (
        <>
          <div className="table-scroll-container">
            <table className="playlists-table">
              <thead>
                <tr>
                  <th className="checkbox-column">
                    <input
                      type="checkbox"
                      onChange={() => handleSelectAll(playlists)}
                      checked={allCurrentPageSelected}
                    />
                  </th>
                  <th
                    className={`playlist-info-header ${sortConfig.key === "title" ? "sorted" : ""} ${
                      sortConfig.key === "title" ? sortConfig.direction : ""
                    }`}
                    onClick={() => handleSort("title")}
                  >
                    Playlist Info
                  </th>
                  <th
                    className={`author-header ${sortConfig.key === "author" ? "sorted" : ""} ${
                      sortConfig.key === "author" ? sortConfig.direction : ""
                    }`}
                    onClick={() => handleSort("author")}
                  >
                    Author
                  </th>
                  <th
                    className={`category-header ${sortConfig.key === "categories" ? "sorted" : ""} ${
                      sortConfig.key === "categories"
                        ? sortConfig.direction
                        : ""
                    }`}
                    onClick={() => handleSort("categories")}
                  >
                    Category
                  </th>
                  <th
                    className={`date-header ${sortConfig.key === "created_at" ? "sorted" : ""} ${
                      sortConfig.key === "created_at"
                        ? sortConfig.direction
                        : ""
                    }`}
                    onClick={() => handleSort("created_at")}
                  >
                    Created
                  </th>
                  <th
                    className={`numeric-header ${sortConfig.key === "views" ? "sorted" : ""} ${
                      sortConfig.key === "views" ? sortConfig.direction : ""
                    }`}
                    onClick={() => handleSort("views")}
                  >
                    Views
                  </th>
                  <th
                    className={`numeric-header ${sortConfig.key === "items_count" ? "sorted" : ""} ${
                      sortConfig.key === "items_count"
                        ? sortConfig.direction
                        : ""
                    }`}
                    onClick={() => handleSort("items_count")}
                  >
                    Items
                  </th>
                  <th
                    className={`numeric-header ${sortConfig.key === "video_count" ? "sorted" : ""} ${
                      sortConfig.key === "video_count"
                        ? sortConfig.direction
                        : ""
                    }`}
                    onClick={() => handleSort("video_count")}
                  >
                    Video Items
                  </th>
                  <th
                    className={`numeric-header ${sortConfig.key === "card_count" ? "sorted" : ""} ${
                      sortConfig.key === "card_count"
                        ? sortConfig.direction
                        : ""
                    }`}
                    onClick={() => handleSort("card_count")}
                  >
                    Card Items
                  </th>
                  <th
                    className={`visibility-header ${sortConfig.key === "visibility" ? "sorted" : ""} ${
                      sortConfig.key === "visibility"
                        ? sortConfig.direction
                        : ""
                    }`}
                    onClick={() => handleSort("visibility")}
                  >
                    Visibility
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {playlists.map((playlist) => (
                  <AdminPlaylistCard
                    key={playlist.id}
                    playlist={playlist}
                    isSelected={selectedPlaylists.has(playlist.id)}
                    onSelect={handleSelectPlaylist}
                    refreshData={() => {
                      setSelectedPlaylists((prev) => {
                        const newSet = new Set(prev);
                        newSet.delete(playlist.id);
                        return newSet;
                      });
                      setPlaylists((prevPlaylists) =>
                        prevPlaylists.filter((p) => p.id !== playlist.id),
                      );
                      responseCache.current.clear();
                      fetchPlaylists();
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <PaginationControls
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            onPageChange={setCurrentPage}
            onPageSizeChange={(size) => {
              setPageSize(size);
              setCurrentPage(1);
            }}
            totalRows={totalPlaylists}
          />

          {showDeleteModal && (
            <AlertModal
              type="danger"
              title="Delete Playlists"
              message={`Are you sure you want to delete ${selectedPlaylists.size} selected playlist(s)?`}
              buttonDeleteText="Delete"
              onContinue={handleDeletePlaylists}
              onCancel={() => setShowDeleteModal(false)}
              dataCy="delete-playlists-modal"
            />
          )}
        </>
      )}
    </div>
  );
};

export default PlaylistsTable;
