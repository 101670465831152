import React, { useState, useRef, useLayoutEffect } from "react";
import ButtonComponent from "../ButtonComponent/ButtonComponent.jsx";
import AlertModal from "../../modals/AlertModal/AlertModal.jsx";
import { notifyError, notifySuccess } from "../../actions/global.action.js";
import { useDispatch } from "react-redux";
import { videoService } from "../../services/video.service.ts";
import trash from "../../assets/trash.svg";
import edit from "../../assets/edit.svg";
import "./styles.scss";
import RichTextEditorComponent from "../RichTextEditor/RichTextEditorComponent.jsx";
import { renderContent } from "../../utils/editor.jsx";
import ItemCard from "../ItemCard/ItemCard.jsx";
import TooltipComponent from "../TooltipComponent/TooltipComponent.jsx";
import { useNavigate } from "react-router-dom";

const Note = ({ video, content, handleChange }) => {
  /*
  Represents a single note in the My Notes tab
  handleChange: a function that leads to fetching notes since something was changed
  */
  const [isTruncated, setIsTruncated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const noteDescriptionRef = useRef(null);
  const [noteToDeleteId, setNoteToDeleteId] = useState(null);
  const [showDangerModal, setShowDangerModal] = useState(false);
  const [isEditOn, setIsEditOn] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const checkTruncation = () => {
      const noteDescription = noteDescriptionRef.current;
      if (!noteDescription) return;

      const lineHeight = parseFloat(
        getComputedStyle(noteDescription).lineHeight,
      );
      const rowsThreshold = 6;
      if (noteDescription.scrollHeight > lineHeight * rowsThreshold) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
        setIsExpanded(true);
      }
    };

    checkTruncation();
  }, [content]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const requestDeleteNote = async (noteId) => {
    try {
      const response = await videoService.deleteNote(noteId);
      if (response) {
        dispatch(notifySuccess("Note deleted successfully"));
        handleChange();
      }
    } catch (e) {
      dispatch(notifyError("Error deleting notes"));
    }
  };

  const handleNoteSaved = () => {
    setIsEditOn(false);
    handleChange();
  };

  const handleEditIconClick = () => {
    if (!isEditOn) {
      setIsEditOn(true);
      return;
    }
    handleChange();
    setIsEditOn(false);
  };

  const navigateToPage = () => {
    if (video.card_type === "video") {
      navigate(`/video/${video.id}`);
      return;
    }

    if (
      video.card_type &&
      ["knowledge", "flashcard", "assessment"].includes(video.card_type)
    ) {
      navigate(`/card/${video.id}`);
      return;
    }

    if (
      video.type &&
      ["knowledge", "flashcard", "assessment"].includes(video.type)
    ) {
      navigate(`/card/${video.id}`);
      return;
    }

    // Default fallback
    navigate(`/video/${video.id}`);
  };

  const preprocessDesc = () => {
    try {
      const jsonDesc = JSON.parse(content);
      const renderedDesc = renderContent(jsonDesc);
      if (renderedDesc && renderedDesc[0].props.children) {
        return renderedDesc;
      } else {
        return "Note is empty.";
      }
    } catch (err) {
      return typeof content === "string" ? content : "Invalid content.";
    }
  };

  return (
    <div className="note-item-component" key={video.id}>
      {showDangerModal && (
        <AlertModal
          type="danger"
          onContinue={() => {
            requestDeleteNote(noteToDeleteId);
            setShowDangerModal(false);
          }}
          onCancel={() => {
            setShowDangerModal(false);
          }}
          title="Delete Note"
          message="Are you sure you want to delete this note? This action is irreversible."
        />
      )}
      <ItemCard
        key={video.id}
        object={video}
        isDeleted={!video.id}
        handleClick={video.id ? navigateToPage : null}
        type="dashboard-video"
      />
      <div className="note-block">
        {isEditOn ? (
          <div className="note-edit">
            {/* Editor! */}
            <RichTextEditorComponent
              object={video}
              type={"notesPage"}
              handleNoteSaved={handleNoteSaved}
              placeholder="Add a note..."
            />
          </div>
        ) : (
          <div className="note-block-no-edit">
            <div
              className={`note-description ${isExpanded ? "expanded" : ""}`}
              id="noteDescription"
              ref={noteDescriptionRef}
              style={
                isTruncated && !isExpanded
                  ? {
                      maxHeight: `${4.8 * parseFloat(noteDescriptionRef.current ? getComputedStyle(noteDescriptionRef.current).lineHeight : 0)}px`,
                      overflow: "hidden",
                    }
                  : {}
              }
            >
              {preprocessDesc()}
            </div>
            <div className="note-button">
              {isTruncated && (
                <ButtonComponent
                  text={isExpanded ? "See less" : "See more"}
                  level="ghost"
                  size="small"
                  icon={false}
                  handleClick={handleToggle}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="controls-child">
        <div
          className={`control-icon${!video.id ? " disabled" : ""}`}
          onClick={() => {
            if (video.id) handleEditIconClick();
          }}
        >
          <TooltipComponent tipText={"Edit"} placement="bottom" icon={edit} />
        </div>
        <div
          className="control-icon"
          onClick={() => {
            const id = video.notes[0].id || video[0].id;
            setNoteToDeleteId(id);
            setShowDangerModal(true);
          }}
        >
          <TooltipComponent
            tipText={"Delete"}
            placement={"bottom"}
            icon={trash}
          />
        </div>
      </div>
    </div>
  );
};

export default Note;
