import "./styles.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../actions/global.action.js";
import ItemCard from "../ItemCard/ItemCard.jsx";
import ButtonComponent from "../ButtonComponent/ButtonComponent.jsx";

const PlaylistPreview = ({ newPlaylist = false }) => {
  /*
        Playlist Preview displayed when editing the playlist.
        Accessible from  Dashboard > My Playlists and /playlists, in the rightmost column.
        Displays all videos in a selected playlist:
            - Thumbnail
            - Title
            - Author
            - Date
            - View count
        Contains a button to add more videos to the selected playlist.    
    */
  const dispatch = useDispatch();
  const playlist = useSelector((state) => state.playlist.playlistToPreview);

  const renderPlaylistItems = (playlist) => {
    const childrenArray = Array.isArray(playlist?.children?.results)
      ? playlist.children.results
      : [];

    return childrenArray.map((item) => {
      if (item.children) {
        return renderPlaylistItems(item);
      } else {
        return (
          <ItemCard
            key={item?.object_id || item?.id}
            object={item}
            level="small"
            handleClick={() => {
              window.open(
                item?.video_url
                  ? `/video/${item?.object_id || item?.id}`
                  : `/card/${item?.object_id || item?.id}`,
                "_blank",
              );
            }}
          />
        );
      }
    });
  };

  return (
    <div className="preview-wrapper">
      <div className="preview-top">
        <div className="preview-header">Playlist Preview</div>
        <ButtonComponent
          text="Add items"
          handleClick={() => dispatch(showModal("add-video-to-playlist"))}
          icon={false}
          size="small"
          level={newPlaylist ? "disable" : "primary"}
          dataCy="playlist-preview-add-item-modal-button"
        />
      </div>
      <div className="preview-deck" key={playlist?.id}>
        {!newPlaylist && renderPlaylistItems(playlist)}
      </div>
    </div>
  );
};
export default PlaylistPreview;
