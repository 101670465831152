import { ApiService } from "./api.service.ts";

class AnalyticsService {
  /**
   * Record a flip event when a user flips a flashcard
   * @param feedCardId ID of the card being flipped
   */
  async recordFlip(feedCardId: string): Promise<any> {
    try {
      return await ApiService.post("/analytics/flip/", {
        feed_card_id: feedCardId,
      });
    } catch (error) {
      console.error("Error recording flip event:", error);
      // Return a default value that won't break the UI
      return { data: { flip_count: 0 } };
    }
  }

  /**
   * Record an attempt when a user answers a multiple choice question
   * @param feedCardId ID of the card being answered
   * @param isCorrect Whether the answer was correct
   */
  async recordAttempt(feedCardId: string, isCorrect: boolean): Promise<any> {
    try {
      return await ApiService.post("/analytics/attempt/", {
        feed_card_id: feedCardId,
        is_correct: isCorrect,
      });
    } catch (error) {
      console.error("Error recording attempt event:", error);
      // Return a default value that won't break the UI
      return { data: { attempt_count: 0, percent_correct: 0 } };
    }
  }

  /**
   * Record a recall rating when a user rates how well they knew a flashcard
   * @param feedCardId ID of the flashcard
   * @param rating Rating value (1-4)
   */
  async recordRecallRating(feedCardId: string, rating: number): Promise<any> {
    try {
      return await ApiService.post("/analytics/recall-rating/", {
        feed_card_id: feedCardId,
        rating: rating,
      });
    } catch (error) {
      console.error("Error recording recall rating:", error);
      // Return a default value that won't break the UI
      return { data: { success: false } };
    }
  }

  /**
   * Submit an answer to a multiple choice card
   * @param cardId ID of the card being answered
   * @param answerIndex Index of the selected answer
   */
  async submitAnswer(cardId: string, answerIndex: number): Promise<any> {
    try {
      const response = await ApiService.post(`/cards/${cardId}/submit_answer`, {
        answer_index: answerIndex,
      });
      return response;
    } catch (error) {
      console.error("Error submitting answer:", error);
      throw error;
    }
  }

  /**
   * Get analytics data for a specific card
   * @param feedCardId ID of the card
   */
  async getCardAnalytics(feedCardId: string): Promise<any> {
    try {
      return await ApiService.get(`/analytics/card/${feedCardId}/`);
    } catch (error) {
      console.error("Error getting card analytics:", error);
      // Return a default value that won't break the UI
      return {
        data: {
          flip_count: 0,
          attempt_count: 0,
          percent_correct: 0,
        },
      };
    }
  }

  /**
   * Get analytics data for a specific playlist
   * @param playlistId ID of the playlist
   */
  async getPlaylistAnalytics(playlistId: string): Promise<any> {
    try {
      return await ApiService.get(`/analytics/playlist/${playlistId}/`);
    } catch (error) {
      console.error("Error getting playlist analytics:", error);
      // Return a default value that won't break the UI
      return {
        data: {
          quiz_score: 0,
          recall_overview: {
            1: 0,
            2: 0,
            3: 0,
            4: 0,
            null: 0,
          },
        },
      };
    }
  }
}

export const analyticsService = new AnalyticsService();
