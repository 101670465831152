import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { setData } from "../actions/data.js";
import { videoService } from "../services/video.service.ts";
import { cardService } from "../services/card.service.ts";
import { playlistService } from "../services/playlist.service.ts";

import FeedContainer from "../pages/FeedContainer/FeedContainer.jsx";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage.jsx";
import { showSignup, showSubscription } from "../actions/global.action.js";

const FeedWrapper = ({ type, isMyTab, video }) => {
  // General Hooks
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Redux selectors
  const data = useSelector((state) => state.data.data);
  const playlistUuidTab = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const currentPlaylist = useSelector((state) => state.playlist);
  const videoUuid = currentPlaylist?.object_id;

  // FeedWrapper state variables
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  // Use a ref for cardRefs so that they persist between pagination loads
  const cardRefs = useRef([]);
  const [totalNumOfCards, setTotalNumOfCards] = useState(0);
  const [playlistTitle, setPlaylistTitle] = useState("");

  // Edge cases
  const [isEmpty, setIsEmpty] = useState(false);
  const [isPrivatePage, setIsPrivatePage] = useState(false);
  const [notFound, setNotFound] = useState(null);

  // Auth & Loading
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const showSignUpForm = !isAuthenticated;
  // Separate full-page loading for the first load...
  const [loading, setLoading] = useState(true);
  // ...from pagination (infinite scrolling) loading
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  // Pagination state for playlists
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);

  // Video feed functions for non-playlist pages
  const fetchFeedPaginated = async () => {
    try {
      await videoService.sortVideos();
      const videos = await videoService.getVideosPaginated();
      dispatch(setData([...videos]));
    } catch (error) {
      console.error("Error fetching videos for feed:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchVideo = async (id) => {
    try {
      setNotFound(null);
      const response = await videoService.getVideo(id);
      let recommendedVideos = [];
      try {
        recommendedVideos = await videoService.getRecommended(id);
      } catch (error) {
        console.error("Error fetching recommended videos:", error);
      }
      dispatch(setData([response, ...recommendedVideos]));
    } catch (err) {
      setNotFound("video");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchCard = async (id) => {
    try {
      setNotFound(null);
      const response = await cardService.getCard(id);
      const updatedResponse = {
        ...response,
        card_type: response.card_type,
        image: response.image_url,
        imageBack: response.image_url_back,
      };
      let recommendedVideos = [];
      try {
        recommendedVideos = await videoService.getRecommended(id);
      } catch (error) {
        console.error("Error fetching recommended videos:", error);
      }
      dispatch(setData([updatedResponse, ...recommendedVideos]));
    } catch (err) {
      setNotFound("card");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Modified fetchPlaylist to support pagination without reloading the page
  const fetchPlaylist = async (id) => {
    try {
      const current = searchParams.get("current");
      setNotFound(null);
      const response = await playlistService.getPlaylist(id, false, currentPage);

      let childrenData = [];
      if (response.children && response.children.results) {
        childrenData = response.children.results;
        setTotalPages(response.children.total_pages);
      } else {
        childrenData = response.children;
      }

      if (currentPage > 1) {
        dispatch(setData([...data, ...childrenData]));
      } else {
        dispatch(setData([...childrenData]));
      }

      if (childrenData.length === 0) {
        setIsEmpty(true);
      }

      if (current && currentPage === 1) {
        const index = childrenData.findIndex((item) => item.id === current);
        if (index !== -1) setCurrentVideoIndex(index + 1);
      }
      setPlaylistTitle(response.title);
    } catch (err) {
      setNotFound("playlist");
      setIsPrivatePage(true);
      console.error(err);
    } finally {
      if (currentPage > 1) {
        setIsFetchingMore(false);
      } else {
        setLoading(false);
      }
    }
  };

  // Load feed whenever type, location, or currentPage changes
  useEffect(() => {
    const loadFeed = async () => {
      if (currentPage === 1) {
        setLoading(true);
      }
      if (type === "feed") {
        await fetchFeedPaginated();
      } else if (type === "playlist" && location.pathname.includes("/playlist")) {
        await fetchPlaylist(location.pathname.split("/")[2]);
      } else if (type === "playlist-tab") {
        await fetchPlaylist(playlistUuidTab?.object_id);
      } else if (type === "video") {
        await fetchVideo(location.pathname.replace(/\/$/, "").split("/").pop());
      } else if (type === "video-tab") {
        await fetchVideo(videoUuid);
      } else if (type === "video-preview") {
        dispatch(setData([video]));
        setLoading(false);
      } else if (type === "card") {
        await fetchCard(location.pathname.replace(/\/$/, "").split("/").pop());
      }

      // Automatically trigger the next page fetch if there are more pages
      if (totalPages && currentPage < totalPages) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };
    loadFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playlistUuidTab, type, location, currentPage, totalPages]);

  // Update cardRefs and total number of cards when new data comes in.
  useEffect(() => {
    if (data && data.length > 0) {
      // Only add refs for new items rather than recreating all refs.
      while (cardRefs.current.length < data.length) {
        cardRefs.current.push(React.createRef());
      }
      setTotalNumOfCards(data.length);
    }
  }, [data]);

  if (loading) return <div className="spinner-container-feed"></div>;

  return notFound ? (
    <NotFoundPage pageType={notFound} />
  ) : (
    <FeedContainer
      data={data}
      type={type}
      isMyTab={isMyTab}
      isPrivatePage={isPrivatePage}
      isEmpty={isEmpty}
      showSignUpForm={showSignUpForm}
      currentVideoIndex={currentVideoIndex}
      playlistTitle={playlistTitle}
      cardRefs={cardRefs}
      totalNumOfCards={totalNumOfCards}
      // Pass pagination info for playlist pages:
      pagination={
        type === "playlist" || type === "playlist-tab"
          ? { currentPage, totalPages, setCurrentPage, isFetchingMore }
          : null
      }
    />
  );
};

export default FeedWrapper;
