import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCurrentPlaylist,
  fetchPlaylists,
} from "../../../actions/playlist.js";
import useOutsideClick from "../../../hooks/useOutsideClick.js";
import "../styles.scss";
import { SimpleTreeItemWrapper } from "../dnd-kit/SimpleTreeItemWrapper.tsx";
import AlertModal from "../../../modals/AlertModal/AlertModal.jsx";
import { setWatchingPlaylist } from "../../../actions/playlist.js";
import { notifyError, notifySuccess } from "../../../actions/global.action.js";
import DropdownMenuPlaylistComponent from "../../DropdownMenuPlaylistComponent/DropdownMenuPlaylistComponent.jsx";
import { hideModal } from "../../../actions/global.action.js";
import { playlistService } from "../../../services/playlist.service.ts";
import TooltipComponent from "../../TooltipComponent/TooltipComponent.jsx";
import videoIcon from "../../../assets/video-recorder.svg";
import UploadAssCard from "../../../modals/UploadAssCard/UploadAssCard.jsx";

const DraggableVideoItem = React.forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const showPlaylistWatch = useSelector(
    (state) => state.playlist.watchingPlaylist,
  );
  const [videoSettings, setVideoSettings] = useState(false);
  const menuRef = useRef();
  const [showWarningDeleteVideo, setShowWarningDeleteVideo] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const [uploadAssCard, setUploadAssCard] = useState({
    show: false,
    playlistId: null,
    playlistTitie: null,
    orderId: null,
  });
  const user = useSelector((state) => state.auth.user);

  const handleVideoSettingsClick = () => {
    setVideoSettings(true);
  };

  useOutsideClick(menuRef, () => {
    dispatch(hideModal());
  });

  const handleConfirmDeleteVideo = () => {
    setShowWarningDeleteVideo(false);
    handleRemove(props.item, "video");
  };

  const handleCancelDeleteVideo = () => {
    setShowWarningDeleteVideo(false);
  };

  const handleHighlight = (item, type) => {
    dispatch(setCurrentPlaylist(props.item));
    dispatch(setCurrentPlaylist(item));
    dispatch(setWatchingPlaylist("edit"));
  };

  const handleWatch = (item, type) => {
    if (type === "playlist") {
      dispatch(setCurrentPlaylist(props.item));
      dispatch(setCurrentPlaylist(item));
      window.open(`/playlist/${props.item.object_id}`, "_blank");
    } else if (type === "video") {
      dispatch(setCurrentPlaylist(item));
      window.open(`/video/${props.item.object_id}`, "_blank");
    } else {
      window.open(`/card/${props.item.object_id}`, "_blank");
    }
  };

  const handleShare = (item, item_type) => {
    const textToCopy = `${window.location.origin}/${item_type}/${item.object_id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        dispatch(notifySuccess("URL copied to clipboard!"));
      })
      .catch((err) => {
        dispatch(notifyError(`Error copying to clipboard. ${err}`));
      });
  };

  const handleVideoSettings = (option) => {
    setVideoSettings(false); // Close the settings menu

    switch (option) {
      case "watch":
        handleWatch(props.item, "video");
        break;

      case "share":
        handleShare(props.item, "video");
        break;

      case "remove":
        setShowWarningDeleteVideo(true);
        break;

      case "insert card after":
        setUploadAssCard({
          show: true,
          playlistId: props.item.parent.object_id,
          playlistTitle: props.item.parent.title,
          orderId: props.item.index + 1,
        });
        break;

      case "edit":
        navigate(`/edit/video/${props.item.object_id}`);
        break;

      default:
        // Handle unexpected options (optional)
        console.warn(`Unknown option: ${option}`);
        break;
    }
  };

  const handleRemove = (item, type) => {
    playlistService
      .deleteFromPlaylist(item.playlist_item_id)
      .then((response) => {
        dispatch(
          notifySuccess(
            `${type === "video" ? "Video" : "Card"} removed successfully`,
          ),
        );
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        console.error("Error removing playlist item:", error.message);
      });
  };

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth,
      );
    }
  }, [textRef]);

  return (
    <SimpleTreeItemWrapper
      {...props}
      ref={ref}
      onCollapse={null}
      isVideo={true}
      isSelected={
        currentPlaylist?.object_id === props.item.object_id &&
        showPlaylistWatch === "watch-video"
      }
    >
      <>
        {" "}
        {uploadAssCard.show && (
          <UploadAssCard
            handleClose={() =>
              setUploadAssCard({
                show: false,
                playlistId: null,
                playlistTitle: null,
                orderId: null,
              })
            }
            playlistId={uploadAssCard.playlistId}
            playlistTitle={uploadAssCard.playlistTitle}
            orderId={uploadAssCard.orderId}
          />
        )}
        {showWarningDeleteVideo && (
          <AlertModal
            type="danger"
            onContinue={handleConfirmDeleteVideo}
            onCancel={handleCancelDeleteVideo}
            title="Remove Video"
            message="Are you sure you want to remove video from this playlist?"
            buttonDeleteText="Remove"
          />
        )}
        <>
          {
            <>
              <>{(props.item.canHaveChildren = false)}</>
              <div
                className="inner-draggable-container"
                onClick={handleVideoSettingsClick}
                data-cy="draggable-video-item"
              >
                {!videoSettings && isOverflowing && (
                  <TooltipComponent
                    tipText={props.item.title}
                    showIcon={false}
                    placement="playlist video"
                  />
                )}
                <div className="icon-container">
                  <div className="card-icon">
                    <img src={videoIcon} />
                  </div>
                </div>
                <div className="draggable-span-container">
                  <div className="draggable-span" ref={textRef}>
                    {props.item.title}
                  </div>
                </div>
                <div className="kebab-menu">
                  <button
                    className="btn menu-toggle"
                    onClick={handleVideoSettingsClick}
                  ></button>
                  {videoSettings ? (
                    <div style={{ position: "relative" }}>
                      <DropdownMenuPlaylistComponent
                        isPlaylistTree={true}
                        isRightAlignedProp={true}
                        type="video"
                        chosenOption={"none"}
                        handleChange={handleVideoSettings}
                        handleClose={() => setVideoSettings(false)}
                        isCurrUserAuthor={props.item.author?.id === user?.id}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          }
        </>
      </>
    </SimpleTreeItemWrapper>
  );
});

export default DraggableVideoItem;
