import React from "react";
import "./styles.scss";
import { useDispatch } from "react-redux";
import {
  setWatchingPlaylist,
  updatePlaylistName,
  updatePlaylistVisibility,
} from "../../actions/playlist";
import ThreeSquares from "../../assets/three-squares.svg";
import PaperMagnifier from "../../assets/paper-magnifier.svg";
import useWindowWidth from "../../hooks/useWindowWidth";
import ButtonComponent from "../ButtonComponent/ButtonComponent";

const PlaylistBanner = () => {
  /*
        Playlist banner displayed on Dashboard > Playlist tab and /playlist.
        Encourages to create a playlist.
        Displayed when no playlist is selected/being created.
    */

  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  return (
    <div className="right-wrapper">
      <div className="thumbnail">
        <div className="text-container">
          <div className="title-text">A home for your playlists</div>
          <div className="desc-text">Create a playlist today!</div>
        </div>

        <div className="image-container">
          {windowWidth > 1250 ? <img src={ThreeSquares} /> : <></>}
        </div>
      </div>

      <div className="playlist-suggest-container">
        <div className="playlist-suggest">
          <img src={PaperMagnifier} />
          <div className="description">
            <div className="title-card">Create a playlist today</div>
            <div className="desc-card">
              <div>
                You can create a playlist from videos you or others have
                uploaded.
              </div>
              <div>You can also nest playlists inside each other.</div>
              <div>You can only add videos that are public.</div>
            </div>
          </div>
          <ButtonComponent
            text="Create playlist"
            icon={false}
            handleClick={() => {
              dispatch(setWatchingPlaylist("create"));
              dispatch(updatePlaylistName(""));
              dispatch(updatePlaylistVisibility("public"));
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default PlaylistBanner;
