import React, { useState, useEffect } from "react";
import DropdownMenuPlaylistComponent from "../DropdownMenuPlaylistComponent/DropdownMenuPlaylistComponent";
import privateIcon from "../../assets/private.svg";
import publicIcon from "../../assets/public.svg";
import unlistedIcon from "../../assets/unlisted.svg";
import assessmentIcon from "../../assets/multiple-choice.svg";
import flashcardIcon from "../../assets/informational.svg";
import informationalIcon from "../../assets/knowledge-thumb-black.svg";
import chevronDown from "../../assets/chevron-down.svg";
import chevronUp from "../../assets/chevron-up.svg";
import "./styles.scss";
import { Skeleton } from "@mui/material";

const DropdownMenuPrivacy = ({
  category = "privacy",
  currentVisibility,
  handleVisibilityChange,
  disabled = false,
  noPhoto = false,
  dataCy = "",
}) => {
  /*
  the component is currently defined only for "privacy" category
  modify the code to make it work for other categories: "menu", "video"
  */

  const [state, setState] = useState(false);
  const [visibility, setVisibility] = useState(currentVisibility);

  useEffect(() => {
    setVisibility(currentVisibility);
  }, [currentVisibility]);

  const selectIcon = (visibility) => {
    const icons = {
      private: privateIcon,
      unlisted: unlistedIcon,
      public: publicIcon,
      "Multiple Choice": assessmentIcon,
      Flashcard: flashcardIcon,
      Informational: informationalIcon,
    };

    if (category === "privacy" || category === "cards") {
      return icons[visibility];
    }

    return null;
  };

  const selectedIcon = selectIcon(visibility);
  const selectedChevron = state ? chevronUp : chevronDown;

  const handleOnClick = () => {
    disabled || setState(!state);
  };

  const handleStateChange = (selectedOption) => {
    handleVisibilityChange(selectedOption);
    setVisibility(selectedOption);
    setState(!state);
  };

  const handleBackgroundClick = () => {
    setState(!state);
  };

  return (
    <div
      className={`toggle-headline-component${disabled ? " disabled" : ""}`}
      data-cy={dataCy}
    >
      <div
        className={`toggle-headline${category === "news" ? " news" : ""}`}
        onClick={handleOnClick}
      >
        <div
          className={`toggle-headline-icon left${noPhoto ? " no-photo" : ""}`}
        >
          {!noPhoto && <img src={selectedIcon} />}
        </div>
        <div className="toggle-headline-name">
          {visibility ? (
            visibility?.charAt(0).toUpperCase() + visibility?.slice(1)
          ) : (
            <>
              <Skeleton variant="text" width={60} />
            </>
          )}
        </div>
        {!disabled && (
          <div className="toggle-headline-icon right">
            <img src={selectedChevron} />
          </div>
        )}
      </div>
      <div className="dropdown-menu-playlist">
        {state ? (
          <DropdownMenuPlaylistComponent
            isRightAlignedProp={false}
            type={category}
            chosenOption={visibility}
            handleChange={handleStateChange}
            handleBackgroundClick={handleBackgroundClick}
            handleClose={() => setState(false)}
            noPhoto={noPhoto}
          />
        ) : null}
      </div>
    </div>
  );
};

export default DropdownMenuPrivacy;
