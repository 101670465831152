import { ApiService } from "./api.service.ts";

class VideoService {
  async getStaffPicks(): Promise<any> {
    return await ApiService.get("/videos/staff-picks/");
  }

  async getVideos(): Promise<any> {
    return await ApiService.get("/videos/");
  }

  async getVideo(id: string): Promise<any> {
    return await ApiService.get(`/video/${id}/`);
  }

  async getUserVideos(userId: string): Promise<any> {
    return await ApiService.get(`/user/${userId}/videos/`);
  }

  async addLike(id: string): Promise<any> {
    return await ApiService.post("/video/like/", { video_id: id });
  }

  async removeLike(id: string): Promise<any> {
    return await ApiService.post("/video/unlike/", { video_id: id });
  }

  async updateViews(video_id: string, progress: number) {
    return await ApiService.post(`/video/update-views/${video_id}/`, {
      progress,
    });
  }

  async addToWatchHistory(videoId: string, playlistId: string): Promise<any> {
    return await ApiService.post("/video/watched/", {
      video_id: videoId,
      playlist_id: playlistId,
    });
  }

  async updateNote(data: any): Promise<any> {
    const { item_type, item_id, content } = data;
    return await ApiService.post("/notes/add/", {
      item_type,
      item_id,
      content,
    });
  }

  async deleteNote(noteId: string): Promise<any> {
    return await ApiService.delete(`/notes/delete/${noteId}/`);
  }

  async getPopular(card: boolean = false): Promise<any> {
    return await ApiService.get("/videos/popular/", {
      params: {
        card,
      },
    });
  }

  async getRecommended(videoId: string): Promise<any> {
    return await ApiService.get("/videos/recommended/", {
      params: {
        video_id: videoId,
      },
    });
  }

  async getCategories(featured: boolean = false): Promise<any> {
    return await ApiService.get("/video/categories/", {
      params: {
        featured,
      },
    });
  }

  async search(
    query_term: string,
    author_username: string | null = "null",
    search_category: string = "all",
    page: number = 0,
  ): Promise<any> {
    return await ApiService.get(
      `/search/?q=${query_term}&category=${search_category}&page=${page}&author=${author_username}`,
    );
  }

  async flagVideo(videoId: string, flagCategory: string) {
    return await ApiService.post(`/video/${videoId}/flag`, { flagCategory });
  }

  async deleteVideo(videoId: string) {
    return await ApiService.delete(`/videos/${videoId}/delete/`);
  }

  async deleteAdminVideo(videoId: string) {
    return await ApiService.delete(`/api/admin/videos/${videoId}/delete/`);
  }

  async changeVideoVisibility(videoId: string, visibility: string) {
    return await ApiService.post(`/video/change-visibility/${videoId}/`, {
      visibility,
    });
  }

  async preprocessVideo(data: any) {
    return await ApiService.post("/preprocess-video/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async createVideo(data: any) {
    return await ApiService.put("/create-video/", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async editVideo(videoId: string, data: any) {
    return await ApiService.post(`/edit-video/${videoId}`, data);
  }

  async canWatchMoreVideos(): Promise<any> {
    return await ApiService.get("/videos/can-watch-more/");
  }

  async editVideoThumbnail(videoId: string, data: any) {
    return await ApiService.post(`/video/${videoId}/thumbnail`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  async sortVideos(): Promise<any> {
    return await ApiService.get("/videos/sort");
  }
  async getVideosPaginated(page: number = 1): Promise<any> {
    return await ApiService.get(`/videos/retrieve/?page=${page}`);
  }

  async getAllVideosAdmin(params: any): Promise<any> {
    try {
      const timeRangeMap: { [key: string]: string } = {
        "All Time": "all",
        "7 days": "7_days",
        "30 days": "30_days",
        "12 months": "12_months",
        Custom: "custom",
      };

      const queryParams = new URLSearchParams({
        page: params.page?.toString() || "1",
        page_size: params.pageSize?.toString() || "10",
        sort_by: params.sortBy || "date",
        sort_order: params.sortOrder || "desc",
        time_range: timeRangeMap[params.timeRange] || "all",
        search: params.search || "",
      });

      if (params.customRange?.startDate && params.customRange?.endDate) {
        queryParams.append("custom_start", params.customRange.startDate);
        queryParams.append("custom_end", params.customRange.endDate);
      }

      const response = await ApiService.get(
        `/api/admin/videos/?${queryParams}`,
      );

      const responseData = response;

      const videos = (responseData?.videos || []).map((video: any) => ({
        ...video,
        tags: video?.tags || [],
        categories: video?.categories || [],
        likes_count: video?.likes_count || 0,
        playlists_count: video?.playlists_count || 0,
        notes_count: video?.notes_count || 0,
        description:
          video?.description ||
          JSON.stringify({
            type: "doc",
            content: [
              { type: "paragraph", content: [{ type: "text", text: "" }] },
            ],
          }),
        author: video?.author || {},
        video_url: video?.video_url || "",
        thumbnail_url: video?.thumbnail_url || "",
        date: video?.date || new Date().toISOString(),
      }));

      return {
        videos,
        total: responseData?.total || 0,
        pages: responseData?.pages || 1,
        current_page: responseData?.current_page || 1,
      };
    } catch (error) {
      console.error("Error in getAllVideosAdmin:", error);
      throw error;
    }
  }
}

export const videoService = new VideoService();
