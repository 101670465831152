import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setCurrentPlaylist,
  fetchPlaylists,
  updatePlaylistVisibility,
} from "../../../actions/playlist.js";
import useOutsideClick from "../../../hooks/useOutsideClick.js";
import "../styles.scss";
import { SimpleTreeItemWrapper } from "../dnd-kit/SimpleTreeItemWrapper.tsx";
import AlertModal from "../../../modals/AlertModal/AlertModal.jsx";
import { setWatchingPlaylist } from "../../../actions/playlist.js";
import { notifyError, notifySuccess } from "../../../actions/global.action.js";
import { checkIsDuplicatePlaylist } from "../../../hooks/usePlaylist.ts";
import PrivatePlaylist from "../../../assets/private.svg";
import PublicPlaylist from "../../../assets/public.svg";
import UnlistedPlaylist from "../../../assets/unlisted.svg";
import SettingsIcon from "../../../assets/edit-icon.svg";
import DropdownMenuPlaylistComponent from "../../DropdownMenuPlaylistComponent/DropdownMenuPlaylistComponent.jsx";
import { showModal, hideModal } from "../../../actions/global.action.js";
import plus from "../../../assets/plus.svg";
import { playlistService } from "../../../services/playlist.service.ts";
import TooltipComponent from "../../TooltipComponent/TooltipComponent.jsx";
import assessmentIcon from "../../../assets/multiple-choice.svg";
import flashcardIcon from "../../../assets/informational.svg";
import knowledgeIcon from "../../../assets/knowledge-thumb-black.svg";
import videoIcon from "../../../assets/video-recorder.svg";
import UploadAssCard from "../../../modals/UploadAssCard/UploadAssCard.jsx";

const cardTypeImage = {
  assessment: assessmentIcon,
  knowledge: knowledgeIcon,
  flashcard: flashcardIcon,
};

const DraggableCardItem = React.forwardRef((props, ref) => {
  /*
  Accessible from  Dashboard > My Playlists, in the left column.
  Function that handles actions associated with a playlist, including:
    - Settings (three dots)
      - Watch
      - Edit
      - Share
      - Duplicate
      - Delete
    - Privacy change
    - Add video  
*/
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const items = useSelector((state) => state.playlist.playlists);
  const currentPlaylist = useSelector(
    (state) => state.playlist.currentPlaylist,
  );
  const showPlaylistWatch = useSelector(
    (state) => state.playlist.watchingPlaylist,
  );
  const [selectedVisibility, setSelectedVisibility] = useState(false);
  const [videoSettings, setVideoSettings] = useState(false);
  const [cardSettings, setCardSettings] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();
  const [showWarning, setShowWarning] = useState(false);
  const [showWarningDeleteVideo, setShowWarningDeleteVideo] = useState(false);
  const [showWarningDeleteCard, setShowWarningDeleteCard] = useState(false);
  const [showWarningEdit, setShowWarningEdit] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const [uploadAssCard, setUploadAssCard] = useState({
    show: false,
    playlistId: null,
    playlistTitie: null,
    orderId: null,
  });
  const user = useSelector((state) => state.auth.user);

  const handleCardSettingsClick = () => {
    setCardSettings(true);
  };

  useOutsideClick(menuRef, () => {
    dispatch(hideModal());
  });

  const handleDeleteClick = () => {
    setShowWarning(true);
  };

  const handleDeleteClickEdit = () => {
    setShowWarningEdit(true);
  };

  const handleConfirmDeleteCard = () => {
    setShowWarningDeleteCard(false);
    handleRemove(props.item, "card");
  };

  const handleCancelDelete = () => {
    setShowWarning(false);
  };

  const handleCancelDeleteCard = () => {
    setShowWarningDeleteCard(false);
  };

  const handleCancelDeleteEdit = () => {
    setShowWarningEdit(false);
  };

  const handleConfirmDeleteEdit = () => {
    setShowWarningEdit(false);
    handleHighlight(props.item, "playlist");
    dispatch(setCurrentPlaylist(props.item));
    dispatch(setWatchingPlaylist("edit"));
  };

  const handleHighlight = (item, type) => {
    dispatch(setCurrentPlaylist(props.item));
    dispatch(setCurrentPlaylist(item));
    dispatch(setWatchingPlaylist("edit"));
  };

  const handleWatch = (item, type) => {
    if (type === "playlist") {
      dispatch(setCurrentPlaylist(props.item));
      dispatch(setCurrentPlaylist(item));
      window.open(`/playlist/${props.item.object_id}`, "_blank");
    } else if (type === "video") {
      dispatch(setCurrentPlaylist(item));
      window.open(`/video/${props.item.object_id}`, "_blank");
    } else {
      window.open(`/card/${props.item.object_id}`, "_blank");
    }
  };

  const handleEdit = (item) => {
    if (showPlaylistWatch === "create") {
      handleDeleteClickEdit(props.item, "playlist");
    } else {
      handleHighlight(props.item, "playlist");
      dispatch(setCurrentPlaylist(props.item));
      dispatch(setCurrentPlaylist(item));
      dispatch(setWatchingPlaylist("edit"));
    }
  };

  const handleShare = (item, item_type) => {
    const textToCopy = `${window.location.origin}/${item_type}/${item.object_id}`;

    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        dispatch(notifySuccess("URL copied to clipboard!"));
      })
      .catch((err) => {
        dispatch(notifyError(`Error copying to clipboard. ${err}`));
      });
  };

  const handleCardSettings = (option) => {
    setCardSettings(false); // Close the settings menu

    switch (option) {
      case "preview":
        handleWatch(props.item, "card");
        break;

      case "share":
        handleShare(props.item, "card");
        break;

      case "remove":
        setShowWarningDeleteCard(true);
        break;

      case "insert card after":
        setUploadAssCard({
          show: true,
          playlistId: props.item.parent.object_id,
          playlistTitle: props.item.parent.title,
          orderId: props.item.index + 1,
        });
        break;

      case "edit":
        navigate(`/edit/card/${props.item.object_id}`);
        break;

      default:
        console.warn(`Unknown option: ${option}`);
        break;
    }
  };

  const handleRemove = (item, type) => {
    playlistService
      .deleteFromPlaylist(item.playlist_item_id)
      .then((response) => {
        dispatch(
          notifySuccess(
            `${type === "video" ? "Video" : "Card"} removed successfully`,
          ),
        );
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        console.error("Error removing playlist item:", error.message);
      });
  };

  useEffect(() => {
    if (textRef.current) {
      setIsOverflowing(
        textRef.current.scrollWidth > textRef.current.clientWidth,
      );
    }
  }, [textRef]);

  return (
    <SimpleTreeItemWrapper
      {...props}
      ref={ref}
      onCollapse={null}
      isVideo={true}
      isSelected={
        currentPlaylist?.object_id === props.item.object_id &&
        showPlaylistWatch === "watch-video"
      }
    >
      <>
        {" "}
        {uploadAssCard.show && (
          <UploadAssCard
            handleClose={() =>
              setUploadAssCard({
                show: false,
                playlistId: null,
                playlistTitle: null,
                orderId: null,
              })
            }
            playlistId={uploadAssCard.playlistId}
            playlistTitle={uploadAssCard.playlistTitle}
            orderId={uploadAssCard.orderId}
          />
        )}
        {showWarningDeleteCard && (
          <AlertModal
            type="danger"
            onContinue={handleConfirmDeleteCard}
            onCancel={handleCancelDeleteCard}
            title="Remove Card"
            message="Are you sure you want to remove card from this playlist?"
            buttonDeleteText="Remove"
          />
        )}
        <>
          {
            <>
              <>{(props.item.canHaveChildren = false)}</>
              <div
                className="inner-draggable-container"
                onClick={handleCardSettingsClick}
                data-cy="draggable-card-item"
              >
                {!cardSettings && isOverflowing && (
                  <TooltipComponent
                    tipText={props.item.title}
                    showIcon={false}
                    placement="playlist card"
                  />
                )}
                <div className="icon-container">
                  <div className="card-icon">
                    <img src={cardTypeImage[props.item.card_type]} />
                  </div>
                </div>
                <div className="draggable-span-container">
                  <div className="draggable-span" ref={textRef}>
                    {props.item.title}
                  </div>
                </div>
                <div className="kebab-menu">
                  <button
                    className="btn menu-toggle"
                    onClick={handleCardSettingsClick}
                  ></button>
                  {cardSettings && (
                    <div style={{ position: "relative" }}>
                      <DropdownMenuPlaylistComponent
                        isPlaylistTree={true}
                        isRightAlignedProp={true}
                        type="card"
                        chosenOption={"none"}
                        handleChange={handleCardSettings}
                        handleClose={() => setCardSettings(false)}
                        isCurrUserAuthor={props.item.author?.id === user?.id}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          }
        </>
      </>
    </SimpleTreeItemWrapper>
  );
});

export default DraggableCardItem;
