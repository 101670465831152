import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "core-js/actual/structured-clone";

// Global error handler for ResizeObserver errors
window.onerror = function (message) {
  if (message.includes("ResizeObserver")) {
    console.warn("ResizeObserver error suppressed.");
    return true; // Suppresses the error
  }
  // Other global error handling can go here...
};

// listen for storage changes and reload the page
window.addEventListener("storage", (event) => {
  if (event.key === "access" || event.key === "refresh") {
    // Dispatch a logout action or reload the page
    window.location.reload();
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);
